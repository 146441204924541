import React from "react";
import Sidebar from "shared/components/Sidebar/Sidebar";
//import QuoteForm from "./components/QuoteForm";
import Iframe from "./components/Iframe";

import Product from "./components/Product";
import vcpPersonal from "../../../../assets/productos/2023/1.png";
import vcAgencias from "../../../../assets/productos/2023/2.png";
import vcPlus from "../../../../assets/productos/2023/3-4.png";
import vcPickupMovil from "../../../../assets/productos/2023/5-6.png";

import viaAutomatica from "../../../../assets/productos/final/via-automatica.png";
import pack from "../../../../assets/productos/final/pack-size.png";
import background from "../../../../assets/backgrounds/05.jpg";

import "./styles.css";

const extraInfo = (
  <div className="product-extra-info">
    <p>
      <span className="primary">despacho en agencia </span>
      <span className="secondary">entrega a domicilio</span>
    </p>
    <p className="details">
      Debe ser coordinado con la agencia despachante del envío.
    </p>
    <p>
      <span className="primary">retiro en domicilio </span>
      <span className="secondary">entrega en agencia (RD)</span>
    </p>
    <p className="details">
      Debe ser solicitado{" "}
      <a
        href="https://formularios.viacontacto.com.ar/viacargo/domicilio/"
        target="_blank"
      >
        aquí
      </a>
    </p>
  </div>
);

const Quote = () => (
  <section className="screen-contents quote-screen">
    <div className="background">
      <img src={background} alt="Fondo" />
    </div>
    <div className="details-container">
      <div className="content">
        <div className="content-title">
          <h1>Cotizar el envío</h1>
        </div>
        {/*<QuoteForm />*/}
        <Iframe
          id="iFrameCotizador"
          title="Cotizador Vía Cargo"
          source="https://gtsviacargo.alertran.net/gts/pub/cotiza.seam"
        />
        <div className={"product-considerations"}>
          <div className={"one-half"}>
            <div className={"text-container"}>
              Consideraciones a tener en cuenta
            </div>
          </div>
          <div className={"one-fourth"}>
            <div className={"image-container"}>
              <img src={pack} alt={"Medidas"} />
            </div>
          </div>
          <div className={"one-half"}>
            <p>
              Las medidas deben expresarse en centímetros.
              <br />
              El peso máximo para un paquete es de 100 kg.
            </p>
          </div>
        </div>

        <div className={"products-container"}>
          <Product
            title="VC Personal"
            subtitle="Puerta a Puerta"
            image={[vcpPersonal]}
            showSeparator={false}
          >
            <p>
              Vía Cargo se acercará a tu domicilio para retirar la encomienda y
              se encargará de entregársela directamente al destinatario en el
              domicilio pactado. &nbsp;
              <a href="/product/rd" target="_blank">
                Solicite el retiro aquí
              </a>
            </p>
          </Product>

          <Product
            title="VC Standard"
            subtitle="Agencia a Agencia"
            image={[vcAgencias]}
            showSeparator={false}
          >
            <p>
              Si deseás realizar el envío desde una de nuestras agencias podés
              acercarte a la más próxima a tu ubicación y nosotros nos
              encargaremos de enviarla hacia la Agencia que corresponda, de
              acuerdo a la dirección del destino.&nbsp;
              <a href="/agencies" target="_blank">
                Link a Mapa de Agencias
              </a>
            </p>
          </Product>

          <Product
            title="VC Plus"
            subtitle="Agencia-Domicilio o Domicilio-Agencia"
            // hasExtraInfo={extraInfo}
            image={[vcPlus]}
            showSeparator={false}
          >
            <p>
              Podés elegir que retiren la encomienda en tu domicilio para ser
              entregada en una agencia, o por el contrario, podés llevarla a una
              de nuestras agencias para ser entregada - al destinatario - en su
              domicilio o dirección pactada. Debe ser solicitado&nbsp;
              <a href="/product/ea" target="_blank">
                aquí
              </a>
            </p>
          </Product>
          <Product
            title="Pickup Móvil"
            subtitle="Pickup > Agencia o Pickup > Domicilio"
            // hasExtraInfo={extraInfo}
            image={[vcPickupMovil]}
            showSeparator={false}
          >
            <p>
              Servicio de despacho de puntos de la Ciudad de Buenos Aires, a
              todo el país, hasta 15kg. Servicio exclusivo de recolección en
              CABA. Debe ser solicitado&nbsp;
              {/* <a href="/product/pm" target="_blank">
                aquí
              </a> */}
            </p>
          </Product>
          <Product
            title="Vía Automática"
            subtitle=""
            image={[viaAutomatica]}
            showSeparator
          >
            <p>
              En 2019 inaguramos la nueva planta automatizada de encomiendas
              para medianos y pequeños volúmenes.
            </p>
          </Product>
        </div>
      </div>
      <Sidebar />
    </div>
  </section>
);

export default Quote;
