//import React from "react";
/*Tarea GDM-965 - Inicio */
import React, { useState, useEffect } from "react";
import axios from "axios";
/*Tarea GDM-965 - Fin */

import "./styles.css";

export const userCategoryConfirm = ({}) => {
  /*Tarea GDM-965 - Inicio */
  const [urlRetorno, setUrlRetorno] = useState(""),
    [xhr, setXhr] = useState(""),
    [showModal, setShowModal] = useState(""),
    [errorMessage, setErrorMessage] = useState("");

  let cliente_existe = process.env.REACT_APP_API_URL + "/api/cliente";
  let datos;
  if (window.localStorage.getItem("StoreId")) {
    let StoreId = window.localStorage.getItem("StoreId");
    datos = {
      Email: null,
      StoreId: StoreId
    };
  }
  if (window.localStorage.getItem("EmailTN")) {
    let Email = window.localStorage.getItem("EmailTN");
    datos = {
      Email: Email,
      StoreId: null
    };
  }

  axios
    .get(cliente_existe, { params: datos })
    .then(response => {
      let { data } = response;
      window.localStorage.setItem("URLTN", data.cliente.URL + "/admin");
      setUrlRetorno(window.localStorage.getItem("URLTN"));
    })
    .catch(e => {});

  /*Tarea GDM-965 - Fin */
  return (
    <section className="screen-contents contact-screen">
      <div className="details-container">
        <div className="content">
          <div className="container confirm-container">
            <div className="confirm-content">
              <div className="title-confirm-container">
                <h4>Te contactaremos dentro de las próximas 24 hs.</h4>
              </div>

              <div className="text-confirm-container">
                <p>
                  ¡Mientras tanto, ya podes operar en{" "}
                  <strong>Mi Via Cargo!</strong>
                </p>
              </div>

              <div className="button-confirm-container">
                <a href={urlRetorno}>
                  {/* Se cambio un valor fijo por una variable */}
                  <input type="button" value="Empezar" name="buttonRecover" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default userCategoryConfirm;
