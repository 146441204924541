import React, { Component, useState, useEffect, useRef } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup
} from "react-bootstrap";
import axios from "axios";
import Modal from "simple-react-modal";

//import { useSelector, useDispatch, createStore } from 'react-redux'
//import { decrement, increment } from './../../screens/estadosSlice'

function Fomulario(props) {
  /// const count = useSelector((state) => state.estados.value)
  ///const dispatch = useDispatch()

  useEffect(() => {
    mostrar();
  }, []);

  const [xhr, setXhr] = useState("");
  const [showModal, setShowModal] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formPago, setFormPago] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [contador, setContador] = useState("");

  const formulario = Object.entries(formPago).map(([key, value]) => {
    let i = 0;
    return <input key={i++} type="hidden" name={key} value={value}></input>;
  });

  const mostrar = () => {
    //dispatch(increment())

    let camposFirstData = {
      IdMedio: "TAR",
      Importe: 100,
      IdTarjeta: 711,
      IdPosnet: 1701,
      Cuotas: 1,
      IdTipoDocumento: 1,
      Documento: "31991958",
      Nombre: "Test",
      Apellido: "Test",
      Email: "test@test.com"
    };

    let retiros = process.env.REACT_APP_API_URL + `/api/retiros_iniciar_pago`;
    axios
      .post(retiros, camposFirstData)
      .then(response => {
        let { data } = response;

        setFormPago(data.data.Pago);
        setTimeout(function() {}, 2000);

        setTimeout(function() {
          // alert("claaaro")
        }, 10000);
      })
      .catch(e => {
        setXhr(false);
        setShowModal(true);
        setFormPago("otra cosa");
        setErrorMessage("server-error: " + e.response.data.message);
        console.log(e);
      });
  };

  const formIframe = () => {
    return (
      <div>
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          <div className={"close-button"}>
            <i className={"material-icons"} onClick={() => setShowModal(false)}>
              close
            </i>
          </div>
          <div className={"modal-body"}>
            {modalMessage}
            {errorMessage}
            <div>
              <button
                className="quote btn btn-default"
                onClick={() => setShowModal(false)}
              >
                Cerrar
              </button>
            </div>
          </div>
        </Modal>

        {Object.entries(formPago).map(([key, value]) => {
          let i = 0;
          if (key !== "action") {
            return (
              <input key={i++} type="text" name={key} value={value}></input>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div>
      <span>count= *</span>
      {Object.entries(formPago).map(([key, value]) => {
        let i = 0;
        if (key !== "action") {
          return <input key={i++} type="text" name={key} value={value}></input>;
        }
      })}
    </div>
  );
}
export default Fomulario;
