import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "shared/components/Sidebar/Sidebar";
import background from "assets/backgrounds/01.jpg";
import "./styles.css";
import basica from "../../../../../src/assets/entrega_domicilio/basica.png";
import estandar from "../../../../../src/assets/entrega_domicilio/estandar.png";
import styles from "./HeaderContent.module.css";

import { Accordion, Card, Form, Col, Button, CardGroup } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

class PickUpHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeId: 1123,
      setActiveId: 1
    };
  }

  toggleActive(id) {
    if (this.state.activeId === id) {
      this.setState({ setActiveId: null });
    } else {
      this.setState({ setActiveId: id });
    }
  }

  render() {
    let { activeId, setActiveId } = this.state;
    let valor = "'" + activeId + "'";
    return (
      <section className="screen-contents faqs-screen">
        <div className="background">
          <img src={background} alt="Fondo" />
        </div>
        <div className="details-container">
          <div className="content">
            <div className="content-title">
              <h1>Mi retiro en domicilio Online</h1>
            </div>
            <div className="">
              <div>
                <div className={`jumbotron2 col-lg-12`}>
                  <div class="col-md-4 col-lg-7">
                    <h1 class="display-4">Ahora retiramos&nbsp;</h1>
                    <h1 class="display-4">tus pedidos&nbsp;</h1>
                    <h1 class="display-4">en tu puerta.</h1>
                    <p class="lead">
                      Coordina tus envíos en solo 3 pasos y ahorrá tiempo.{" "}
                    </p>
                  </div>

                  <div class="col-md-3">
                    <br />
                    <br />
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item active">
                        <a className="nav-link btn btn-success" href="/pickup">
                          Comenzar<span className="sr-only">(current)</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="container">
                  <br />
                  <div class="row">
                    <div class="col-md-12">
                      <div class="col-md-12 offset-lg-2 col-lg-10 offset-xl-3 col-sm-12">
                        <h2 class="display-4">Retiro en domicilio</h2>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class={`card col-md-3 offset-1`}>
                      <div class={`cardBody`}>
                        <h5 class={`cardTitle2`}>Cobertura</h5>
                        <img
                          src="images/cobertura.png"
                          alt="Placeholder image"
                          width="95"
                          class="img-fluid"
                        />
                        <p class={`cardText`}>
                          Servicio disponible en zonas <br />
                          residenciales de todo el país.
                        </p>
                      </div>
                    </div>

                    <div class={`card col-md-3 offset-1`}>
                      <div class={`cardBody`}>
                        <h5 class={`cardTitle2`}>Horarios</h5>
                        <img
                          src="images/horarios.png"
                          alt="Placeholder image"
                          width="95"
                          class="img-fluid"
                        />
                        <p class={`cardText`}>
                          Pasaremos al día hábil
                          <br />
                          siguiente de realizada tu solicitud. Si
                          <br />
                          ese día hábil es lunes, martes,
                          <br />
                          miércoles, jueves o viernes,
                          <br />
                          pasaremos entre las 08 y las 18 hs.
                          <br />
                          Si es sábado, pasaremos entre las
                          <br />
                          08 y 14 hs.
                        </p>
                      </div>
                    </div>

                    <div class={`card col-md-3 offset-1`}>
                      <div class={`cardBody`}>
                        <h5 class={`cardTitle2`}>Medidas y pesos</h5>
                        <img
                          src="images/medidas.png"
                          alt="Placeholder image"
                          width="95"
                          class="img-fluid"
                        />
                        <p class={`cardText`}>Hasta 50 kg</p>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />

                <div class="container">
                  <br />
                  <hr />
                  <br />
                  <div class="row">
                    <img
                      src="images/banner-via-cargo.png"
                      class="img-fluid"
                      alt="Placeholder image"
                    />
                  </div>
                </div>

                <div class="container">
                  <br />
                  <br />

                  <div class="row">
                    <div class={`${styles.card2}  col-md-3 offset-1`}>
                      <div class={`cardBody`}>
                        <h5 class={`cardTitle2`}>Empaques</h5>
                        <hr />
                        <p class={`cardText`}>
                          El empaque de un producto es el contenedor que protege
                          el envío. Sirve principalmente para preservar las
                          condiciones propias del producto, su imagen y la
                          descripción de uso o consumo, además de tener un
                          diseño que evita el daño durante su manipulación.{" "}
                        </p>
                      </div>
                    </div>

                    <div class={`${styles.card2}  col-md-3 offset-1`}>
                      <div class={`cardBody`}>
                        <h5 class={`cardTitle2`}>Embalaje</h5>
                        <hr />

                        <p class={`cardText`}>
                          El embalaje es la envoltura contenedora que permite
                          transportar, manipular y almacenar el producto,
                          individual o grupalmente. Su función principal es la
                          protección y manipulación. Prepará el embalaje de
                          manera segura y adecuada teniendo en cuenta.
                          Amortiguación - Envolturas - Sellado
                        </p>
                      </div>
                    </div>

                    <div class={`${styles.card2}  col-md-3 offset-1`}>
                      <div class={`cardBody`}>
                        <h5 class={`cardTitle2`}>Identificación</h5>
                        <hr />

                        <p class={`cardText`}>
                          Una vez que el producto se encuentra debidamente
                          empacado y embalado, se debe colocar en un sitio
                          visible de la encomienda la etiqueta que indique cómo
                          tiene que ser manipulado y conservado. Es necesario
                          para aclarar si requiere de algún manejo especial.
                        </p>
                      </div>
                    </div>
                    <div class={`${styles.card2}  col-md-3 offset-1`}>
                      <div class={`cardBody`}>
                        <h5 class={`cardTitle2`}>Protección</h5>
                        <hr />

                        <p class={`cardText`}>
                          Es importante que el producto esté protegido de los
                          impactos, golpes y caídas, al igual que de los agentes
                          biológicos y climáticos que podrían presentarse
                          durante el transporte. Recomendamos usar algunos de
                          los siguientes materiales: metal, madera, cartón
                          corrugado, plásticos, entre otros.
                        </p>
                      </div>
                    </div>
                    <div class={`${styles.card2}  col-md-3 offset-1`}>
                      <div class={`cardBody`}>
                        <h5 class={`cardTitle2`}>Sujeción y agarre</h5>
                        <hr />
                        <p class={`cardText`}>
                          Para su mayor seguridad, es preferible que se utilicen
                          películas plásticas adheribles (Strech), zunchos,
                          cintas o ganchos para sellar los empaques y embalajes,
                          y facilitar así su transporte, manipulación y
                          almacenamiento.
                        </p>
                      </div>
                    </div>
                    <div class={`${styles.card2}  col-md-3 offset-1`}>
                      <div class={`cardBody`}>
                        <h5 class={`cardTitle2`}>Tiempo de envío y colecta</h5>
                        <hr />

                        <p class={`cardText`}>
                          Tu envío será recogido dentro de las 24 horas
                          siguientes luego de la validación del pago. En el
                          momento de la recolección del envío el mensajero
                          esperará un máximo de 15 minutos para la entrega del
                          paquete.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <br />

                <div class="container" style={{ padding: 0 }}>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="col-md-12 col-lg-10 offset-xl-2">
                        <h2 class="display-4">
                          Objetos de prohibida circulación
                        </h2>
                        <br />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="card2 col-md-1"></div>
                    <div class="card2 col-md-2">
                      <div class="card-body">
                        <h5 class="card-title">
                          <img
                            src="images/marihuana.png"
                            width="80"
                            height="80"
                            alt="No enviar Opio, Marihuana ni estupefacientes - Vía Cargo"
                          />
                        </h5>
                        <hr />

                        <p class={`cardText`}>
                          Opio, morfina, cocaína, marihuana y ningún tipo de
                          estupefacientes y sustancias.
                        </p>
                      </div>
                    </div>

                    <div class="card2 col-md-2">
                      <div class="card-body">
                        <h5 class="card-title">
                          <img
                            src="images/vidrio.png"
                            width="80"
                            height="80"
                            alt="No enviar vidrios - Vía Cargo"
                          />
                        </h5>
                        <hr />
                        <p class={`cardText`}>
                          Los objetos que por su naturaleza o embalaje puedan
                          ocasionar daños a los mensajeros, o puedan manchar o
                          deteriorar los demás envíos.
                        </p>
                      </div>
                    </div>

                    <div class="card2 col-md-2 ">
                      <div class="card-body">
                        <h5 class="card-title">
                          <img
                            src="images/liquido.png"
                            width="80"
                            height="80"
                            alt="Prohibido enviar líquidos corrosivos - Vía Cargo"
                          />
                        </h5>
                        <hr />
                        <p class={`cardText`}>
                          Líquidos corrosivos y sustancias venenosas, las
                          materias grasas, los polvos colorantes y otras
                          materias similares.
                        </p>
                      </div>
                    </div>

                    <div class="card2 col-md-2">
                      <div class="card-body">
                        <h5 class="card-title">
                          <img
                            src="images/quimico.png"
                            width="80"
                            height="80"
                            alt="No enviar químicos peligrosos - Vía Cargo"
                          />
                        </h5>
                        <hr />

                        <p class={`cardText`}>
                          Los químicos peligrosos como corrosivos generadores de
                          oxígeno, peróxidos, blanqueadores, alcoholes, etc.
                          También artículos que contengan o funcionen con base a
                          estos elementos.
                        </p>
                      </div>
                    </div>

                    <div class="card2 col-md-2">
                      <div class="card-body">
                        <h5 class="card-title">
                          <img
                            src="images/armas.png"
                            width="80"
                            height="80"
                            alt="No enviar armas- Vía Cargo"
                          />
                        </h5>
                        <hr />
                        <p class={`cardText`}>
                          Armas, municiones y elementos bélicos de toda especie,
                          además las máquinas para acuñar moneda, los esqueletos
                          para billetes de bancos, salvo el caso de que se trate
                          de envíos.
                        </p>
                      </div>
                    </div>

                    <div class="card2 col-md-1"></div>
                    <br />
                    <div class="card2 col-md-1"></div>

                    <div class="card2 col-md-2 ">
                      <div class="card-body">
                        <h5 class="card-title">
                          <img
                            src="images/dinero.png"
                            width="80"
                            height="80"
                            alt="No enviar dinero en efectivo - Vía Cargo"
                          />
                        </h5>
                        <hr />
                        <p class={`cardText`}>
                          Dinero en efectivo y otros objetos de valor, como
                          monedas, platino, oro y plata manufacturados o no,
                          billetes representativos de moneda o cualquier otro
                          valor al portador, piedras finas o cualquier objeto
                          precioso.
                        </p>
                      </div>
                    </div>

                    <div class="card2 col-md-2 ">
                      <div class="card-body">
                        <h5 class="card-title">
                          <img
                            src="images/explosivos.png"
                            width="80"
                            height="80"
                            alt="No enviar explosivos - Vía Cargo"
                          />
                        </h5>
                        <hr />

                        <p class={`cardText`}>
                          Material explosivo, inflamable o peligroso como
                          municiones, fuegos artificiales, detonadores, cohetes,
                          petardos, entre otros.
                        </p>
                      </div>
                    </div>

                    <div class="card2 col-md-2 ">
                      <div class="card-body">
                        <h5 class="card-title">
                          <img
                            src="images/animales.png"
                            width="80"
                            height="80"
                            alt="No enviar animales - Vía Cargo"
                          />
                        </h5>
                        <hr />
                        <p class={`cardText`}>
                          Los animales vivos y los muertos no disecados, con
                          excepción de las abejas, las sanguijuelas y los
                          gusanos de seda. Los parásitos y los destructores de
                          insectos nocivos canjeados entre instituciones
                          científicas reconocidas.
                        </p>
                      </div>
                    </div>

                    <div class="card2 col-md-2 ">
                      <div class="card-body">
                        <h5 class="card-title">
                          <img
                            src="images/gases.png"
                            width="80"
                            height="80"
                            alt="No enviar gases - Vía Cargo"
                          />
                        </h5>
                        <hr />
                        <p class={`cardText`}>
                          Los gases comprimidos como gas butano, de encendedor,
                          tanques de buceo, aerosoles, desodorantes o
                          extintores.
                        </p>
                      </div>
                    </div>
                    <div class="card2 col-md-2 ">
                      <div class="card-body">
                        <h5 class="card-title">
                          <img
                            src="images/mercancias.png"
                            width="80"
                            height="80"
                            alt="No enviar Mercancías peligrosas - Vía Cargo"
                          />
                        </h5>
                        <hr />

                        <p class={`cardText`}>
                          Las mercancías peligrosas como motores magnetizados,
                          dióxido de carbono sólido, baterías, bacterias, virus
                          o material radioactivo.
                        </p>
                      </div>
                    </div>
                    <div class="card2 col-md-1"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* 
            <Form >
              <Accordion defaultActiveKey="0" >
                <Card>
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                    1- Comenzá a gestionar 
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <h1 className="product-title-primary">Completá los datos de tu envío</h1>
                        <p>Los campos marcados con * son obligatorios</p>
                        <Form.Group controlId="CPOrigen">
                          <Form.Label>Código postal de origen*</Form.Label>
                          <Form.Control type="number" placeholder="Ej.: 1000, 1002, 1085" />
                          <Form.Text className="text-muted">
                            Sólo números.
                          </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="CPDestino">
                          <Form.Label>Código postal de destino*</Form.Label>
                          <Form.Control type="number" placeholder="Ej.: 1000, 1002, 1085" />
                          <Form.Text className="text-muted">
                            Sólo números.
                          </Form.Text>
                        </Form.Group>

                        <h3>Descripción del envío</h3>

                        <Form.Row>
                          <Form.Group as={Col} controlId="bultos">
                            <Form.Label>Cantidad de bultos*</Form.Label>
                            <Form.Control type="number" placeholder="Ej.: 1, 2, 3" />
                            <Form.Text className="text-muted">
                              Sólo números.
                            </Form.Text>
                          </Form.Group>

                          <Form.Group as={Col} controlId="peso">
                            <Form.Label>Peso en kg*</Form.Label>
                            <Form.Control type="number" placeholder="Ej.: 1, 1.5, 4" />
                            <Form.Text className="text-muted">
                              Sólo números.
                            </Form.Text>
                          </Form.Group>

                          <Form.Group as={Col} controlId="altura">
                            <Form.Label>Altura en cm*</Form.Label>
                            <Form.Control type="number" placeholder="Ej.: 5, 10, 20" />
                            <Form.Text className="text-muted">
                              Sólo números.
                            </Form.Text>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="bultos">
                            <Form.Label>Anchura en cm*</Form.Label>
                            <Form.Control type="number" placeholder="Ej.: 10, 20, 30" />
                            <Form.Text className="text-muted">
                              Sólo números.
                            </Form.Text>
                          </Form.Group>

                          <Form.Group as={Col} controlId="peso">
                            <Form.Label>Profundidad en cm*</Form.Label>
                            <Form.Control type="number" placeholder="Ej.: 5, 10, 20" />
                            <Form.Text className="text-muted">
                              Sólo números.
                            </Form.Text>
                          </Form.Group>

                          <Form.Group as={Col} controlId="altura">
                            <Form.Label>Valor declarado*</Form.Label>
                            <Form.Control type="number" placeholder="Valor en Ar$" />
                            <Form.Text className="text-muted">
                              Sólo números.
                            </Form.Text>
                          </Form.Group>
                        </Form.Row>
                        <h3>Descripción del envío</h3>
                        <Form.Check inline label="Pago en origen" name="pago" type='radio' id="origen" ></Form.Check>
                        <Form.Check inline label="Pago en destino" name="pago" type='radio' id="destino" ></Form.Check>
                        
                      </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                    2- Tipo de servicio 
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                      <Card.Body>
                      <h1 className="product-title-primary">Completá los datos de tu envío</h1>
                      <CardGroup>
                        <Card>
                          <Card.Header>Básica</Card.Header>
                          <Card.Body>
                            <Card.Title>Retiro/Entrega a domicilio</Card.Title>
                            <Card.Text>
                              <img src={basica} thumbnail />
                              <p>Servicio de entrega básica para envíos con entrega en domicilio del destinatario*.</p>
                            </Card.Text>
                            <Form.Check inline label="" name="pago" type='radio' id="origen" ></Form.Check>
                          </Card.Body>
                        </Card> 
                        <Card>
                          <Card.Header>Estándar</Card.Header>
                          <Card.Body>
                            <Card.Title>Retiro en domicilio / Entrega en agencia</Card.Title>
                            <Card.Text>
                            <img src={estandar} thumbnail />
                            <p> Servicio de entrega estándar para envíos y retiro en sucursal seleccionada*</p>
                            </Card.Text>
                            <Form.Check inline label="" name="pago" type='radio' id="origen" ></Form.Check>
                          </Card.Body>
                        </Card> 
                      </CardGroup>

                        <p> *Los valores de cotización son únicamente informativos y están sujetos a variaciones según cargo por manejo, peso y/o 
                          medida reales registradas en el momento del retira.</p>

                        <p>**En todos los casos el acondicionamiento del embalaje de las piezas es responsabilidad del remitente.</p>

                        <p>*** Toda operación tiene un costo mínimo y fijo de $ XXX (campo para integrar un WS), en concepto de flete por el
                            retiro a domicilio de encomienda y gastos de servicio. </p>
                      </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                    3- Datos del remitente 
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <h1 className="product-title-primary">Completá los datos del remitente</h1>
                        <p>Los campos marcados con * son obligatorios</p>
                        <Form.Group controlId="CPOrigen">
                          <Form.Label>Nombre completo del remitente*</Form.Label>
                          <Form.Control type="number" placeholder="Nombre completo" />
                          <Form.Text className="text-muted">
                            
                          </Form.Text>
                        </Form.Group>

                        <Form.Row>
                          <Col xs={4}>
                          <Form.Group as={Col} controlId="bultos">
                            <Form.Label>Dirección*</Form.Label>
                            <Form.Control type="text" placeholder="Calle" />
                            <Form.Text className="text-muted">
                              
                            </Form.Text>
                          </Form.Group>
                          </Col>
                          <Col>
                          <Form.Group as={Col} controlId="peso">
                            <Form.Label>Número*</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                              
                            </Form.Text>
                          </Form.Group>
                          </Col>
                          <Col>
                          <Form.Group as={Col} controlId="altura">
                            <Form.Label>Piso/Depto</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">                          
                            </Form.Text>
                          </Form.Group>
                          </Col>
                          <Col>
                          <Form.Group as={Col} controlId="altura">
                            <Form.Label>Datos adicionales</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                              
                            </Form.Text>
                          </Form.Group>
                          </Col>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="bultos">
                            <Form.Label>DNI/CUIL/CUIT del remitente*</Form.Label>
                            <Form.Control type="number" placeholder="" />
                            <Form.Text className="text-muted">
                              
                            </Form.Text>
                          </Form.Group>

                          <Form.Group as={Col} controlId="peso">
                            <Form.Label>Teléfono del remitente*</Form.Label>
                            <Form.Control type="number" placeholder="" />
                            <Form.Text className="text-muted">
                            
                            </Form.Text>
                          </Form.Group>

                          <Form.Group as={Col} controlId="altura">
                            <Form.Label>Email del remitente*</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                              
                            </Form.Text>
                          </Form.Group>
                        </Form.Row>
                        
                      </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">
                    4- Datos del destinatario 
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <h1 className="product-title-primary">Completá los datos del destinatario</h1>
                        <p>Los campos marcados con * son obligatorios</p>
                        <Form.Group controlId="CPOrigen">
                          <Form.Label>Nombre completo del destinatario*</Form.Label>
                          <Form.Control type="number" placeholder="Nombre completo" />
                          <Form.Text className="text-muted">
                            
                          </Form.Text>
                        </Form.Group>

                        <Form.Row>
                          <Col xs={4}>
                          <Form.Group as={Col} controlId="bultos">
                            <Form.Label>Dirección*</Form.Label>
                            <Form.Control type="text" placeholder="Calle" />
                            <Form.Text className="text-muted">
                              
                            </Form.Text>
                          </Form.Group>
                          </Col>
                          <Col>
                          <Form.Group as={Col} controlId="peso">
                            <Form.Label>Número*</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                              
                            </Form.Text>
                          </Form.Group>
                          </Col>
                          <Col>
                          <Form.Group as={Col} controlId="altura">
                            <Form.Label>Piso/Depto</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">                          
                            </Form.Text>
                          </Form.Group>
                          </Col>
                          <Col>
                          <Form.Group as={Col} controlId="altura">
                            <Form.Label>Datos adicionales</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                              
                            </Form.Text>
                          </Form.Group>
                          </Col>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="bultos">
                            <Form.Label>DNI/CUIL/CUIT del destinatario*</Form.Label>
                            <Form.Control type="number" placeholder="" />
                            <Form.Text className="text-muted">
                              
                            </Form.Text>
                          </Form.Group>

                          <Form.Group as={Col} controlId="peso">
                            <Form.Label>Teléfono del destinatario*</Form.Label>
                            <Form.Control type="number" placeholder="" />
                            <Form.Text className="text-muted">
                            
                            </Form.Text>
                          </Form.Group>

                          <Form.Group as={Col} controlId="altura">
                            <Form.Label>Email del destinatario*</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                              
                            </Form.Text>
                          </Form.Group>
                        </Form.Row>
                        
                      </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey="4">
                    5- Pago  
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <h1 className="product-title-primary">Detalle de la operación</h1>
                      <p>Por favor, revise cuidadosamente los datos ingresados</p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Form>
            */}
          </div>
          <Sidebar />
        </div>
      </section>
    );
  }
}

export default PickUpHome;
