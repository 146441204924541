import React, { Component, useState, render, useEffect } from "react";
import { Button, InputGroup } from "react-bootstrap";
import axios from "axios";

import "./../styles.css";

function TiposServicios(props) {
  const [tipoServicio, setTipoServicio] = useState(0);
  const [active, setActive] = useState("");
  const [IdCotizacion, setIdCotizacion] = useState(0);
  const [DescripcionServicio, setDescripcionServicio] = useState("");
  const [CodigoServicio, setCodigoServicio] = useState(0);
  const [TotalServicio, setTotalServicio] = useState(0);
  const [TipoEntrega, setTipoEntrega] = useState(0);

  const [xhr, setXhr] = useState("");
  const [showModal, setShowModal] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  const [boton, setBoton] = useState(false);

  const [token, setToken] = useState(sessionStorage.getItem("token"));

  let servicios = {};

  useEffect(() => {}, [tipoServicio]);

  if (props.tiposServicios) {
    servicios = props.tiposServicios;
  }

  let key = 0;
  let descripcion = "";
  let tiempo = "";
  let imagen = "";
  let total = "";
  let titulo = "";

  const asignarParametros = (
    Id,
    DescripcionServicio,
    Codigo,
    TipoEntrega,
    Total
  ) => {
    let valores = {
      Id: Id,
      DescripcionServicio: DescripcionServicio,
      Codigo: Codigo,
      TipoEntrega: TipoEntrega,
      Total: Total
    };

    let campos = {
      IdCotizacion: Id,
      CodigoServicio: Codigo,
      ImporteTotal: Total
    };

    let cotizarPut = process.env.REACT_APP_API_URL + `/api/cotizar`;

    axios
      .put(cotizarPut, campos, { params: { token: token } })
      .then(response => {
        let { data } = response;

        /*Tarea GDM-1031 - Inicio */
        let datosCotizacion = JSON.parse(
          window.localStorage.getItem("datosRemitente")
        );
        datosCotizacion.IdCotizacion = data.IdCotizacion;
        datosCotizacion.CodigoServicio = Codigo;
        datosCotizacion.ImporteReembolso = 100.0; /*COMENTAR PARA DEBUG GDM-1031 */
        window.localStorage.setItem(
          "datosRemitente",
          JSON.stringify(datosCotizacion)
        );
        /*Tarea GDM-1031 - Fin */
        ///setFormulario(data.data.Pago)
        //variable = "/pickup-formulario/info="+JSON.stringify(data.data.Pago)

        ///setUrl("/pickup-formulario/"+encodeURIComponent( JSON.stringify(response.data.data.Pago)))
        if (data.Estado) {
          props.parametroSegundoForm(valores);
        }
      })
      .catch(e => {
        setXhr(false);
        setShowModal(true);
        setErrorMessage("server-error: " + e);
        console.log(e);
      });
  };

  const handleSubmit = () => {
    //const selectedService = services.find(s => s.selected);

    props.tercerForm();
  };

  const atrasForm1 = () => {
    //const selectedService = services.find(s => s.selected);

    props.atrasForm1();
  };

  if (
    servicios.Cotizacion &&
    servicios.Cotizacion[0].PRODUCTO_CODIGO !== undefined
  ) {
    // console.log("tipos servicios: " + servicios.Cotizacion);
    return (
      <div>
        <p>Seleccione el tipo de operación</p>
        <div className="row">
          {servicios.Cotizacion &&
            servicios.Cotizacion.map(servicio => {
              if (servicio.PRODUCTO_CODIGO == "0001") {
                descripcion = servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/basica.png";
                total = servicio.TOTAL;
                titulo = "Básica";
              } else if (servicio.PRODUCTO_CODIGO == "3000") {
                descripcion = servicio.PRODUCTO_DESCRIPCION;
                tiempo = servicio.TIEMPO_ENTREGA;
                imagen = "/images/estandar.png";
                total = servicio.TOTAL;
                titulo = "Estandar";
              }

              if (servicio.PRODUCTO_CODIGO == "0001")
                return (
                  <div key={key++} className="card text-center col-md-6">
                    <div className="card-titulo">{titulo}</div>
                    <div className="card-body">
                      <img
                        src={imagen}
                        className="img-fluid"
                        alt="Placeholder image"
                      />
                      <br />
                      <br />
                      <h5 className="card-title">{descripcion}</h5>
                      <br />
                      <h3>${total}</h3>
                      <br />

                      <p className="card-text">
                        Servicio de entrega básica para envíos de hasta 50kg.
                        por bulto con entrega en domicilio del destinatario. *
                      </p>
                      <br />
                      <p className="card-text">
                        La colecta será realizada al día siguiente de tu pedido.
                        <br />
                        De Lunes a Viernes, el horario de la colecta es entre
                        las 08 y las 18hs. Sábados entre las 08 y las 14hs.
                        Domingos, no se realizan colectas.
                      </p>
                      {/* <hr className="my-4" /> */}
                      {/* <p className="card-text">Tiempo de entrega: {tiempo}</p> */}
                      {/* <h3>${total}</h3> */}

                      <input
                        type="button"
                        className={
                          active === servicio.PRODUCTO_CODIGO
                            ? "active btn"
                            : "btn"
                        }
                        value="Seleccionar"
                        onClick={() => {
                          ///console.log(servicios)
                          ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                          setActive(servicio.PRODUCTO_CODIGO);
                          setIdCotizacion(servicios.IdCotizacion);
                          setCodigoServicio(servicio.PRODUCTO_CODIGO);
                          setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                          setTipoEntrega(titulo);
                          setTotalServicio(servicio.TOTAL);
                          asignarParametros(
                            servicios.IdCotizacion,
                            servicio.PRODUCTO_DESCRIPCION,
                            servicio.PRODUCTO_CODIGO,
                            titulo,
                            servicio.TOTAL
                          );
                        }}
                        key={key}
                      />
                    </div>
                  </div>
                );
              else if (servicio.PRODUCTO_CODIGO == "3000")
                return (
                  <div key={key++} className="card text-center col-md-6">
                    <div className="card-titulo">{titulo}</div>
                    <div className="card-body">
                      <img
                        src={imagen}
                        className="img-fluid"
                        alt="Placeholder image"
                      />
                      <br />
                      <br />
                      <h5 className="card-title">{descripcion}</h5>
                      <br />
                      <h3>${total}</h3>
                      <br />

                      <p className="card-text text-dark">
                        Servicio de entrega en Agencia
                      </p>
                      <br />
                      <p className="card-text">
                        La colecta será realizada al día siguiente de tu pedido.
                        <br />
                        De Lunes a Viernes, el horario de la colecta es entre
                        las 08 y las 18hs. Sábados entre las 08 y las 14hs.
                        Domingos, no se realizan colectas.
                      </p>
                      <hr className="my-4" />
                      <p className="card-text">Tiempo de entrega: {tiempo}</p>
                      <h3>${total}</h3>

                      <input
                        type="button"
                        className={
                          active === servicio.PRODUCTO_CODIGO
                            ? "active btn"
                            : "btn"
                        }
                        value="Seleccionar"
                        onClick={() => {
                          ///console.log(servicios)
                          ///console.log(servicios.IdCotizacion,servicio.PRODUCTO_CODIGO,servicio.TOTAL)
                          setActive(servicio.PRODUCTO_CODIGO);
                          setIdCotizacion(servicios.IdCotizacion);
                          setCodigoServicio(servicio.PRODUCTO_CODIGO);
                          setDescripcionServicio(servicio.PRODUCTO_DESCRIPCION);
                          setTipoEntrega(titulo);
                          setTotalServicio(servicio.TOTAL);
                          asignarParametros(
                            servicios.IdCotizacion,
                            servicio.PRODUCTO_DESCRIPCION,
                            servicio.PRODUCTO_CODIGO,
                            titulo,
                            servicio.TOTAL
                          );
                        }}
                        key={key}
                      />
                    </div>
                  </div>
                );
            })}
          {!descripcion && (
            <div>
              <p>
                No se encontro cotización para los códigos postales ingresados
              </p>
              <br />
              <Button
                variant="secondary"
                className="h-auto"
                onClick={atrasForm1}
              >
                Anterior
              </Button>
            </div>
          )}
        </div>
        {servicios.Cotizacion && descripcion && (
          <div>
            <br />
            <br />
            {/*<p>
              *Los valores de cotización son únicamente informativos y están
              sujetos a variaciones según cargo por manejo, peso y/o medida
              reales registradas en el momento del retira.
            </p>*/}
            {/*Tarea GDM-1031 */}
            <p>
              En todos los casos el acondicionamiento del{" "}
              <a href="/tips">embalaje de las piezas</a> es responsabilidad del
              remitente
            </p>
            {/* <p>
              **En todos los casos el acondicionamiento del{" "}
              <a href="/tips">embalaje de las piezas</a> es responsabilidad del
              remitente.
            </p>
            <p>
              * Toda operación tiene un costo mínimo y fijo de ${" "}
              <b>
                <i>{servicios.CostoFijo}</i>
              </b>
              , en concepto de flete por el retiro a domicilio de encomienda y
              gastos de servicio.
            </p> */}
            <br />
            <Button variant="secondary" onClick={atrasForm1}>
              Anterior
            </Button>{" "}
            <br />
            {active && (
              <Button variant="primary" onClick={handleSubmit}>
                Siguiente
              </Button>
            )}
            {!active && (
              <Button variant="primary" onClick={handleSubmit} disabled>
                Siguiente
              </Button>
            )}
          </div>
        )}
      </div>
    );
  } else {
    if (servicios.IdCotizacion) {
      return (
        <div>
          <div>
            No se encontro cotización para los códigos postales ingresados
          </div>
          <br />
          <Button variant="secondary" onClick={atrasForm1}>
            Anterior
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <div>Espere mientras se carga la información</div>
          <br />
          <Button variant="secondary" onClick={atrasForm1}>
            Anterior
          </Button>
        </div>
      );
    }
  }
}
export default TiposServicios;
