import React from "react";

const Table = ({ data, type }) => {
  if (type === "incidents") {
    return (
      <table style={{ marginBottom: 50 }}>
        <thead>
          <tr>
            <th>Fecha Alta</th>
            <th>Fecha Cierre</th>
            <th>Descripción</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr key={index}>
                <td>{row["fechaAlta"]}</td>
                <td>{row["fechaCierre"] ? row["fechaCierre"] : "-"}</td>
                <td>{row["descripcion"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <table style={{ marginBottom: 50 }}>
      <thead>
        <tr>
          <th>Hora Local</th>
          <th>Lugar</th>
          <th>Acción</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          return (
            <tr key={index}>
              <td>{row["fechaEvento"]}</td>
              <td>{row["deleNombre"]}</td>
              <td>{row["descripcion"]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
