import React, { Component } from "react";
import isEmail from "validator/lib/isEmail";

import Sidebar from "../../../../shared/components/Sidebar/Sidebar";
import Tabbar from "../../../../shared/components/Tabbar/Tabbar";
import CustomerSupport from "../CustomerSupport/CustomerSupport";
import background from "../../../../assets/backgrounds/03.jpg";
import ContactForm from "./components/ContactForm";
import "./styles.css";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [
        {
          value: "contact",
          label: "Contacto",
          action: () => this._toggleTab("contact"),
          active: window.location.pathname.indexOf("contact") > -1
        },
        {
          value: "claim",
          label: "Reclamos",
          action: () => this._toggleTab("claim"),
          active: window.location.pathname.indexOf("support") > -1
        }
      ],
      width: 850,
      height: 800,
      formFields: {
        name: "",
        lastName: "",
        email: ""
      },
      formErros: []
    };
  }

  updateDimensions() {
    if (window.innerWidth < 900) {
      let update_width = window.innerWidth - 65;
      let update_height = window.innerHeight + 200;
      this.setState({ width: update_width, height: update_height });
    } else if (window.innerWidth < 1500) {
      let update_width = window.innerWidth - 460;
      let update_height = window.innerHeight + 200;
      this.setState({ width: update_width, height: update_height });
    } else {
      let update_width = window.innerWidth - 490;
      let update_height = window.innerHeight + 200;
      this.setState({ width: update_width, height: update_height });
    }
  }

  componentWillMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  _toggleTab = tab => {
    // Already active
    if (this.state.options.filter(option => option.value === tab)[0]["active"])
      return false;

    let options = [...this.state.options];

    options = options.map(option => {
      option.active = option.value === tab;
      return option;
    });

    this.setState({ options });
  };

  _onChangeForm = (fieldName, value) => {
    this.setState(prevState => ({
      ...prevState,
      formFields: {
        ...prevState.formFields,
        [fieldName]: value
      }
    }));
  };

  _setErrorForm = (value = []) => {
    this.setState(prevState => ({
      ...prevState,
      formErros: value
    }));
  };

  _onSubmitForm = e => {
    const { name, lastName, email } = this.state.formFields;
    let errors = [];
    if (!name) {
      errors.push({
        key: "name",
        error: "El campo Nombre no puede estar vacio"
      });
    }

    if (!lastName) {
      errors.push({
        key: "lastName",
        error: "El campo Apellido no puede estar vacio"
      });
    }

    if (!isEmail(email)) {
      errors.push({
        key: "email",
        error: "El campo Email no puede estar vacio"
      });
    }

    if (errors.length > 0) {
      e.preventDefault();
      this._setErrorForm(errors);
    } else {
      this._setErrorForm([]);
      // aca iria el llamado a la API
    }
  };

  _getErrorForm = key => {
    if (this.state.formErros.length > 0) {
      let error = null;
      this.state.formErros.forEach(row => {
        if (row.key === key) {
          error = row.error;
        }
      });
      return error;
    }
  };
  className = "error";
  render() {
    let activeTab = this.state.options.find(option => option.active);
    const nameFieldError = this._getErrorForm("name");
    const lastNameFieldError = this._getErrorForm("lastName");
    const emailFieldError = this._getErrorForm("email");

    return (
      <section className="screen-contents contact-screen">
        <div className="background">
          <img src={background} alt="Fondo" />
        </div>
        <div className="details-container">
          <div className="content">
            <div className="content-title">
              <h1>Sumate a la red</h1>
              <div className="phone">
                {/* <a href="tel:08102227722">0810-222-7722</a>{" "} */}
              </div>
            </div>
            <br />
            <br />
            <div className="tex-content">
              <h4>
                {" "}
                En Vía Cargo buscamos crecer junto a nuestras agencias.
                <br />
                Por eso ampliamos nuestra red con nuevos puntos en todo el país.
                <br />
                Enterate cómo.
              </h4>
              <br />
              <br />
              <h4 className="titel-tex">¿Por qué elegirnos?</h4>
              <p>
                Somos una empresa líder en el servicio de encomiendas y
                logística. Contamos con más de 500 puntos de venta distribuidos
                en todo el país ofreciendo mayor cantidad de destinos a nuestros
                clientes.
                <br />
                Aplicamos la inteligencia logística, a más destinos, mayor
                servicio, eficacia y flexibilidad.
                <br />
                <br />
                Ofrecemos soluciones logísticas para diversos sectores y
                Canales, Empresas, Pymes,
                <br />
                Retailers, Distribuidores y centros de Servicio técnico,
                Tecnología, Telecomunicaciones, industria de maquinaria pesada,
                herramientas, materiales eléctricos y tuberías, materiales para
                la construcción, servicio de e-commerce con entrega a domicilio,
                con los más altos estándares de calidad.
                <br />
                <br />
                <strong>
                  Nos gusta verlos crecer y alcanzar el objetivo de tener una
                  nueva agencia. Acompañando en esta primera etapa con el
                  compromiso de brindarles soporte seguro y responsable que les
                  permita trabajar de manera eficiente con cada cliente y enviar
                  mercadería de gran volumen con protección garantizada.
                  <br />
                  Simplificamos tu día a día para que puedas hacer crecer tu
                  negocio.
                </strong>
                <br />
                <br />
              </p>
              <h4 className="titel-tex">¡Sumate a nuestra red Vía Cargo!</h4>
              <br />
              <br />
              <div className="titleContent">
                <div className="form">
                  <p>Formulario de contacto</p>
                </div>
              </div>
              <hr />
              <h5 className="titel-tex">Nueva Agencia VC</h5>
              <form
                onSubmit={this._onSubmitForm}
                className="formContainer"
                id="form"
              >
                <div>
                  <select className="select">
                    <option selected disabled>
                      Por favor, seleccioná tu provincia
                    </option>
                    <option>Buenos Aires</option>
                    <option>Capital Federal</option>
                    <option>Catamarca</option>
                    <option>Chaco</option>
                    <option>Chubut</option>
                    <option>Córdoba</option>
                    <option>Corrientes</option>
                    <option>Entre Ríos</option>
                    <option> Formosa</option>
                    <option>Jujuy</option>
                    <option>La Pampa</option>
                    <option>La Rioja</option>
                    <option>Mendoza</option>
                    <option>Misiones</option>
                    <option>Neuquén</option>
                    <option>Río Negro</option>
                    <option>Salta</option>
                    <option>San Juan</option>
                    <option>San Luis</option>
                    <option>Santa Cruz</option>
                    <option>Santa Fe</option>
                    <option>Santiago del Estero</option>
                    <option>Tierra del Fuego</option>
                    <option>Tucumán</option>
                  </select>
                </div>
                <div className="field">
                  <label for="Ciudad">Ciudad</label>
                  <input type="text" id="Ciudad" placeholder="Ciudad" />
                </div>

                <div className="field">
                  <div className="fieldNa">
                    <div className="field1">
                      <label for="Name">Nombre*</label>
                      <br />
                      <input
                        className="inp"
                        type="text"
                        name="name"
                        placeholder="Nombre"
                        onChange={e => {
                          this._onChangeForm("name", e.target.value);
                        }}
                        value={this.state.formFields.name}
                      />
                      {nameFieldError && (
                        <label className="error">{nameFieldError}</label>
                      )}
                    </div>
                    <div className="field1">
                      <label for="Name">Apellido*</label>
                      <br />
                      <input
                        className="inp1"
                        type="text"
                        name="lastName"
                        placeholder="Apellido"
                        onChange={e => {
                          this._onChangeForm("lastName", e.target.value);
                        }}
                        value={this.state.formFields.lastName}
                      />
                      {lastNameFieldError && (
                        <label className="error">{lastNameFieldError}</label>
                      )}
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label for="Email">Email*</label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Correo"
                    onChange={e => {
                      this._onChangeForm("email", e.target.value);
                    }}
                    value={this.state.formFields.email}
                  />
                  {emailFieldError && (
                    <label className="error">{emailFieldError}</label>
                  )}
                </div>

                <div className="textBox">
                  <label>Mensaje</label>
                  <textarea
                    name="comments"
                    placeholder="Incluí cualquier información adicional para que nuestro equipo se ponga en contacto."
                  ></textarea>
                </div>
                <div>
                  <p className="fieldRequired">
                    Los campos con * son obligatorios.
                  </p>
                </div>
                <div className="send">
                  <button className="bt" type="submit">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Sidebar />
        </div>
      </section>
    );
  }
}

export default Contact;
