import React, { useState, useEffect } from "react";

import UserCategory from "./userCategory";

export const Container = () => {
  const [user, setUser] = useState("");
  const [categoria, setCategoria] = useState("");
  const [comunicar, setComunicar] = useState("");
  const [siguiente, setSiguiente] = useState(false);
  const [activeA, setActiveA] = useState(false);
  const [activeB, setActiveB] = useState(false);
  const [activeC, setActiveC] = useState(false);

  useEffect(() => {
    const loggedUser = window.localStorage.getItem("loggedUser");
    //const user = window.localStorage.getItem('user')
    setUser(loggedUser);
  }, []);

  const handleCategoria = e => {
    if (e.target.id == "activeA") {
      setActiveA(true);
      setActiveB(false);
      setActiveC(false);
    }
    if (e.target.id == "activeB") {
      setActiveB(true);
      setActiveA(false);
      setActiveC(false);
    }
    if (e.target.id == "activeC") {
      setActiveC(true);
      setActiveA(false);
      setActiveB(false);
    }
    setCategoria(e.target.value);
    setSiguiente(true);
  };

  const handleComunicar = e => {
    setComunicar(e.target.value);
  };

  if (user)
    return (
      <UserCategory
        handleCategoria={handleCategoria}
        handleComunicar={handleComunicar}
        siguiente={siguiente}
        comunicar={comunicar}
        categoria={categoria}
        user={user}
        activeA={activeA}
        activeB={activeB}
        activeC={activeC}
      />
    );
  return (
    <div>
      <h2>No estas autorizado</h2>
    </div>
  );
};
export default Container;
