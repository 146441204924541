import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Modal from "simple-react-modal";

import Login from "./login";

export const Container = props => {
  const [password, setPassword] = useState(""),
    [passwordError, setPasswordError] = useState(null),
    [email, setEmail] = useState(""),
    [emailError, setEmailError] = useState(null),
    [redirect, setRedirect] = useState(""),
    [codigo, setCodigo] = useState(null),
    [correo, setCorreo] = useState(null),
    [mensajeValidar, setMensajeValidar] = useState(null),
    [mensajeCorreo, setMensajeCorreo] = useState(null),
    [xhr, setXhr] = useState(""),
    [showModal, setShowModal] = useState(false),
    [errorMessage, setErrorMessage] = useState(""),
    [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    /*Tarea GDM-1275 - Inicio */
    let timer;
    if (window.localStorage.getItem("timerValidacion")) {
      timer = Date.now() - window.localStorage.getItem("timerValidacion");
    } else {
      window.localStorage.setItem("timerValidacion", Date.now());
      timer = 0;
    }
    console.log("timer : " + timer);
    /*Tarea GDM-1275 - Fin */
    let info = props.location.pathname.split("/");

    //ya se envió el correo y se muestra el mensjae
    if (info.length > 1 && info[2] == "validar") {
      setMensajeCorreo("CORREO ENVIADO");
    } // validar cuenta cuando venga desde el enlace del correo
    else if (info.length > 2 && info[2] && info[3]) {
      setCodigo(info[2]);
      setCorreo(info[3]);
      let validar_correo =
        process.env.REACT_APP_API_URL + "/api/validar_correo"; //"/api/clientes_login";
      let datos = { codigo: info[2], correo: info[3] };
      axios
        .get(validar_correo, { params: datos })
        .then(response => {
          let { data } = response;
          /*Tarea GDM-1275 - Inicio */
          if (!window.localStorage.getItem("mensajeValidacion")) {
            window.localStorage.setItem("mensajeValidacion", data.Mensaje);
          }
          if (timer > 1000) {
            window.localStorage.setItem("timerValidacion", Date.now());
            window.localStorage.setItem("mensajeValidacion", data.Mensaje);
          }
          setMensajeValidar(window.localStorage.getItem("mensajeValidacion"));
          /*Tarea GDM-1275 - Fin */

          /*Tarea GDM-1139 - Inicio */
          let cliente_existe = process.env.REACT_APP_API_URL + "/api/cliente";
          let datosCliente = {
            Email: info[3],
            StorageId: null
          };
          axios
            .get(cliente_existe, { params: datosCliente })
            .then(response => {
              let { data } = response;
              let store;
              store = { store: data.cliente.TiendaNubeStoreID };
              let carrie = process.env.REACT_APP_API_URL + "/api/carrie";
              let carrieOp = process.env.REACT_APP_API_URL + "/api/carrieOp";
              let usuario = 0;
              if (data.cliente.CarrierID === null) {
                axios
                  .get(carrie, { params: store })
                  .then(response => {
                    let { data } = response;
                    if (data.status && data.store_id) {
                      usuario = { usuario: data.store_id };
                      axios
                        .get(carrieOp, { params: usuario })
                        .then(response => {
                          let { data } = response;
                        })
                        .catch(exc => {
                          setXhr(false);
                          setShowModal(true);
                          setErrorMessage(
                            "Error al obtener opciones de carrie"
                          );

                          console.log(exc);
                        });
                    }
                  })
                  .catch(ex => {
                    setXhr(false);
                    setShowModal(true);
                    setErrorMessage("Error al obtener datos de carrie");
                    console.log(ex);
                  });
              }
            })
            .catch(e => {
              setXhr(false);
              setShowModal(true);
              setErrorMessage("Error obteniendo datos del cliente");
            });
          /*Tarea GDM-1139 - Fin */
        })
        .catch(e => {
          setXhr(false);
          setShowModal(true);
          setErrorMessage("Error al intentar validar correo");
          console.log(e);
        });
    }
  }, []);

  const passwordHandler = e => {
    const inputValue = e.target.value;
    setPassword(inputValue);
  };

  const emailHandler = e => {
    const inputValue = e.target.value;
    setEmail(inputValue);
  };

  const submitForm = () => {
    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Email ya registrado");
      return false;
    }

    if (!password) {
      setPasswordError("El Campo es obligatorio");
      return false;
    }

    let clientes = process.env.REACT_APP_API_URL + "/api/clientes_login";
    const dataLogin = {
      Email: email,
      Password: password
    };
    /* Tarea GDM-965 - Inicio */
    const datos = {
      Email: email,
      StorageId: null
    };
    window.localStorage.setItem("EmailTN", email);
    let cliente_existe = process.env.REACT_APP_API_URL + "/api/cliente";
    axios
      .get(cliente_existe, { params: datos })
      .then(response => {
        let { data } = response;
        window.localStorage.setItem("URLTN", data.cliente.URL);
      })
      .catch(e => {
        setXhr(false);
        setShowModal(true);
        setErrorMessage("server-error: " + e.response.data.message);
      });
    /* Tarea GDM-965 - Fin */
    const headers = {
      //Service_Key: config.credentials.service_key,
      "Content-Type": "application/json; charset=utf8"
    };
    /*Tarea GDM-1139 - Inicio */
    let datosCliente = {
      Email: email,
      StorageId: null
    };
    axios
      .get(cliente_existe, { params: datosCliente })
      .then(response => {
        let { data } = response;
        if (data.status) {
          if (data.cliente.CorreoValidado == 1) {
            axios
              .post(clientes, dataLogin)
              .then(response => {
                let { data } = response;

                if (data.status) {
                  window.localStorage.setItem(
                    "loggedUser",
                    data.nombre + " " + data.apellido
                  );
                  window.localStorage.setItem("timeout", new Date().getTime());
                  setRedirect(true);
                } else {
                  setXhr(false);
                  setShowModal(true);
                  setErrorMessage("server-error: " + data.message);
                }
              })
              .catch(e => {
                setXhr(false);
                setShowModal(true);
                setErrorMessage("server-error: " + e.response.data.message);
                console.log(e);
              });
          } else {
            setXhr(false);
            setShowModal(true);
            setErrorMessage("Debe validar su cuenta antes de loguearse");
          }
        } else {
          setXhr(false);
          setShowModal(true);
          setErrorMessage("No existe el email en la base de datos");
        }
      })
      .catch(ex => {
        setXhr(false);
        setShowModal(true);
        setErrorMessage("server-error: " + ex.response.data.message);

        console.log(ex);
      });
    /*Tarea GDM-1139 - Fin */
  };

  if (redirect) {
    return <Redirect to="/userCategory" />;
  }
  return (
    <div>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        className={"modal"}
        containerClassName={"modal-container"}
      >
        <div className={"close-button"}>
          <i className={"material-icons"} onClick={() => setShowModal(false)}>
            close
          </i>
        </div>
        <div className={"modal-body"}>
          {modalMessage}
          {errorMessage}
          <div>
            <button
              className="quote btn btn-default"
              onClick={() => setShowModal(false)}
            >
              Cerrar
            </button>
          </div>
        </div>
      </Modal>
      <Login
        submitForm={submitForm}
        emailHandler={emailHandler}
        emailError={emailError}
        passwordHandler={passwordHandler}
        passwordError={passwordError}
        mensajeValidar={mensajeValidar}
        mensajeCorreo={mensajeCorreo}
      />
      ;
    </div>
  );
};

export default Container;
