import React, { Component, useState, render } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup
} from "react-bootstrap";

import {
  isValidEmail,
  returnOnlyNumbers1,
  isPositiveNumber
} from "../../../../../utils/index";

import "./../styles.css";

function DatosDestinatario(props) {
  const [validated, setValidated] = useState(false);
  const [tipoServicio, setTipoServicio] = useState(0);
  const [active, setActive] = useState("");
  const handleDatosDestinatario = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    if (!boton) {
      siguiente();
    }
  };
  const [NombreDestinatario, setNombreDestinatario] = useState("");
  const [DireccionDestinatario, setDireccionDestinatario] = useState("");
  const [Numero, setNumero] = useState("");
  const [CodigoPostal, setCodigoPostal] = useState("");
  const [Piso, setPiso] = useState("");
  const [DatosAdicional, setDatosAdicional] = useState("");
  const [NroDocDestinatario, setNroDocDestinatario] = useState("");
  const [TelefonoDestinatario, setTelefonoDestinatario] = useState("");
  const [EmailDestinatario, setEmailDestinatario] = useState("");
  const [correoValidar, setCorreoValidar] = useState(false);

  const [boton, setBoton] = useState(true);

  const [campos, setCampos] = useState({
    NombreDestinatario: "none",
    DireccionDestinatario: "none",
    Numero: "none",
    CodigoPostal: "none",
    Piso: "none",
    DatosAdicional: "none",
    NroDocDestinatario: "none",
    TelefonoDestinatario: "none",
    EmailDestinatario: "none"
  });

  const [values, setValues] = useState({
    NombreDestinatario: "",
    DireccionDestinatario: "",
    Numero: "",
    CodigoPostal: "",
    Piso: "",
    DatosAdicional: "",
    NroDocDestinatario: "",
    TelefonoDestinatario: "",
    EmailDestinatario: ""
  });

  let servicios = "{}";

  if (props.tiposServicios) {
    servicios = props.tiposServicios;
  }

  const asignarParametros = (Id, Codigo, Total) => {
    let valores = { Id: Id, Codigo: Codigo, Total: Total };

    props.parametroSegundoForm(valores);
  };

  const handleSubmit = event => {
    const form =
      event.currentTarget.parentNode[0] !== undefined
        ? event.currentTarget.parentNode
        : event.currentTarget.parentNode.parentNode;
    var nuevosCampos = campos;

    if (event.currentTarget.parentNode[0] !== undefined) {
      setNombreDestinatario(validarTexto(form[0].value));
      setDireccionDestinatario(validarTexto(form[1].value));
      setNumero(validarEntero(form[2].value));
      //setPiso(validarEntero(form[3].value))
      setPiso("none");
      setCodigoPostal(validarEntero(form[4].value));
      setDatosAdicional(validarTexto(form[5].value));
      setNroDocDestinatario(validarEntero(form[6].value));
      setTelefonoDestinatario(validarEntero(form[7].value));
      setEmailDestinatario(validarEntero(form[8].value));
      //setpago(validarEntero(form[0].value))

      var nuevosCampos = {
        NombreDestinatario: validarTexto(form[0].value),
        DireccionDestinatario: validarTexto(form[1].value),
        Numero: validarEntero(form[2].value),
        Piso: "none",
        CodigoPostal: validarEntero(form[4].value),
        DatosAdicional: validarTexto(form[5].value),
        NroDocDestinatario: validarEntero(form[6].value),
        TelefonoDestinatario: validarEntero(form[7].value),
        EmailDestinatario: validarEntero(form[8].value)
      };
      setCampos(nuevosCampos);

      if (form.checkValidity() === false) {
        setBoton(true);
        event.preventDefault();
        event.stopPropagation();
      } else {
        setBoton(false);

        setValues({
          NombreDestinatario: form[0].value,
          DireccionDestinatario: form[1].value,
          Numero: form[2].value,
          Piso: form[3].value,
          CodigoPostal: form[4].value,
          DatosAdicional: form[5].value,
          NroDocDestinatario: form[6].value,
          TelefonoDestinatario: form[7].value,
          EmailDestinatario: form[8].value
        });
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      const form =
        event.currentTarget.parentNode.parentNode.parentNode[0] !== undefined
          ? event.currentTarget.parentNode.parentNode.parentNode
          : event.currentTarget.parentNode.parentNode.parentNode.parentNode;

      let numerosEnteros = [
        "NroDocDestinatario",
        "TelefonoDestinatario",
        "CodigoPostal",
        "Numero"
      ];
      let numerosDecimal = [];
      let palabras = ["NombreDestinatario", "DireccionDestinatario", "Piso"];
      let correo = ["EmailDestinatario"];

      let validar = "";
      let valido = false;

      if (numerosEnteros.includes(event.currentTarget.name)) {
        validar = returnOnlyNumbers1(event.currentTarget.value);
        if (validar.length) {
          validar = validar.replace(/,/g, "");
          valido = true;
        }
        event.currentTarget.value = validar;
        setCampos({
          ...campos,
          [event.currentTarget.name]: validarEntero(validar)
        });
      } else {
        if (palabras.includes(event.currentTarget.name)) {
          validar = validarTexto(event.currentTarget.value);
          if (validar != "none") {
            valido = true;
          }
          setCampos({
            ...campos,
            [event.currentTarget.name]: validar
          });
        } else {
          if (correo.includes(event.currentTarget.name)) {
            validar = isValidEmail(event.currentTarget.value);
            if (validar == true) {
              valido = true;
            }
            setCorreoValidar(validar);
            setCampos({
              ...campos,
              [event.currentTarget.name]: validar ? "none" : "block"
            });
          }
        }
      }

      //if (form.checkValidity() === false || !correoValidar || !validar) {
      if (form.checkValidity() === false || valido != true) {
        setBoton(true);
        event.preventDefault();
        event.stopPropagation();
      } else {
        setBoton(false);

        setValues({
          NombreDestinatario: form[0].value,
          DireccionDestinatario: form[1].value,
          Numero: form[2].value,
          Piso: form[3].value,
          CodigoPostal: form[4].value,
          DatosAdicional: form[5].value,
          NroDocDestinatario: form[6].value,
          TelefonoDestinatario: form[7].value,
          EmailDestinatario: form[8].value
        });

        let valores = {
          NombreDestinatario: form[0].value,
          DireccionDestinatario: form[1].value,
          Numero: form[2].value,
          Piso: form[3].value,
          CodigoPostal: form[4].value,
          DatosAdicional: form[5].value,
          NroDocDestinatario: form[6].value,
          TelefonoDestinatario: form[7].value,
          EmailDestinatario: form[8].value
        };

        props.parametroDestinatario(valores);

        event.preventDefault();
        event.stopPropagation();
      }
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const siguiente = () => {
    props.quintoForm();
  };

  const atrasForm3 = () => {
    props.atrasForm3();
  };

  const validarEntero = valor => {
    //intento convertir a entero.
    //si era un entero no le afecta, si no lo era lo intenta convertir
    valor = parseInt(valor);

    //Compruebo si es un valor numérico
    if (isNaN(valor) || valor < 0) {
      //entonces (no es numero) devuelvo el valor cadena vacia

      return "block";
    } else {
      //En caso contrario (Si era un número) devuelvo el valor
      return "none";
    }
  };

  const validarTexto = valor => {
    if (valor.length === 0) {
      return "block";
    } else {
      return "none";
    }
  };

  return (
    <div>
      <Form noValidate validated={validated} autoComplete="nope">
        {/* <h1 className="product-title-primary">
          Completá los datos del destinatario
        </h1> */}
        <p>Los campos marcados con * son obligatorios</p>
        <Form.Row>
          <Col xs={12}>
            <Form.Group as={Col} controlId="NombreDestinatario">
              <Form.Label>Nombre completo del destinatario*</Form.Label>
              <Form.Control
                type="text"
                name="NombreDestinatario"
                placeholder="Como figura en el documento"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NombreDestinatario }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={4}>
            <Form.Group as={Col} controlId="DireccionDestinatario">
              <Form.Label>Dirección*</Form.Label>
              <Form.Control
                type="text"
                name="DireccionDestinatario"
                placeholder="Ejemplo: Avenida de los Incas"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.DireccionDestinatario }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="Numero">
              <Form.Label>Número*</Form.Label>
              <Form.Control
                type="text"
                name="Numero"
                placeholder="Ejemplo: 1652"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.Numero }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group as={Col} controlId="Piso">
              <Form.Label>Piso/Depto</Form.Label>
              <Form.Control
                type="text"
                name="Piso"
                placeholder="Ejemplo: 6A"
                onChange={handleSubmit}
              />
              {/* <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.Piso }}
              >
                Campo obligatorio.
              </Form.Control.Feedback> */}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="CodigoPostal">
              <Form.Label>Código postal*</Form.Label>
              <Form.Control
                type="text"
                name="CodigoPostal"
                placeholder="Ejemplo: 1900, 1920, 1930, etc"
                readOnly
                value={props.CodigoPostalDestinatario}
                onChange={handleSubmit}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={4}>
            <Form.Group as={Col} controlId="DatosAdicional">
              <Form.Label>Observaciones</Form.Label>
              <Form.Control
                type="text"
                name="DatosAdicional"
                placeholder=""
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.DatosAdicional }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group as={Col} controlId="NroDocDestinatario">
              <Form.Label>DNI/CUIL/CUIT del destinatario*</Form.Label>
              <Form.Control
                type="text"
                name="NroDocDestinatario"
                placeholder="Ejemplo: 21851985"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NroDocDestinatario }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="TelefonoDestinatario">
              <Form.Label>Teléfono del destinatario*</Form.Label>
              <Form.Control
                type="text"
                name="TelefonoDestinatario"
                placeholder="Ejemplo: 0112345678"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.TelefonoDestinatario }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="EmailDestinatario">
              <Form.Label>Email del destinatario*</Form.Label>
              <Form.Control
                type="text"
                name="EmailDestinatario"
                placeholder=""
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.EmailDestinatario }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Button variant="secondary" onClick={atrasForm3}>
          Anterior
        </Button>{" "}
        <br />
        {boton && (
          <Button variant="primary" onClick={handleDatosDestinatario}>
            Siguiente
          </Button>
        )}
        {!boton && (
          <Button variant="primary" onClick={siguiente}>
            Siguiente
          </Button>
        )}
      </Form>
      <div></div>
    </div>
  );
}
export default DatosDestinatario;
