import React, { Component, useState, render, useEffect } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup,
  Table
} from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import {
  isValidEmail,
  returnOnlyNumbers1,
  isPositiveNumber
} from "../../../../../utils/index";

import "./../styles.css";

function DatosRemitente(props) {
  const [validated, setValidated] = useState(false);
  const [tipoServicio, setTipoServicio] = useState(0);
  const [active, setActive] = useState("");
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const handleDatosRemitente = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    if (!boton) {
      siguiente();
    }
  };
  const [NombreRemitente, setNombreRemitente] = useState("");
  const [DireccionRemitente, setDireccionRemitente] = useState("");
  const [NumeroRemitente, setNumeroRemitente] = useState("");
  const [CodigoPostal, setCodigoPostal] = useState("");
  const [PisoRemitente, setPiso] = useState("");
  const [DeptoRemitente, setDepto] = useState("");
  const [NroDocRemitente, setNroDocRemitente] = useState("");
  const [CodPaisRemitente, setCodPaisRemitente] = useState(54);
  const [codPaisRem, setCodPaisRem] = useState(54);
  const [CodAreaRemitente, setCodAreaRemitente] = useState("");
  const [NroCelularRemitente, setTelefonoRemitente] = useState("");
  const [EmailRemitente, setEmailRemitente] = useState("");
  const [DatosAdicional, setDatosAdicional] = useState("");
  const [correoValidar, setCorreoValidar] = useState("");

  const [boton, setBoton] = useState(true);

  const [campos, setCampos] = useState({
    NombreRemitente: "none",
    DireccionRemitente: "none",
    NumeroRemitente: "none",
    CodigoPostal: "none",
    PisoRemitente: "none",
    DeptoRemitente: "none",
    NroDocRemitente: "none",
    CodPaisRemitente: "none",
    CodAreaRemitente: "none",
    NroCelularRemitente: "none",
    EmailRemitente: "none",
    DatosAdicional: "none"
  });

  const [values, setValues] = useState({
    NombreRemitente: "",
    DireccionRemitente: "",
    NumeroRemitente: "",
    CodigoPostal: "",
    PisoRemitente: "",
    DeptoRemitente: "",
    NroDocRemitente: "",
    CodPaisRemitente: "",
    CodAreaRemitente: "",
    NroCelularRemitente: "",
    EmailRemitente: "",
    DatosAdicional: ""
  });

  const optionsCodPais = [
    { value: "54", label: "Arg +54" },
    { value: "55", label: "Bra +55" },
    { value: "56", label: "Chi +56" },
    { value: "591", label: "Bol +591" },
    { value: "595", label: "Par +595" },
    { value: "57", label: "Col +57" },
    { value: "51", label: "Per +51" },
    { value: "598", label: "Uru +598" },
    { value: "58", label: "Ven +58" }
  ];

  // useEffect(() => {
  //   setToken(sessionStorage.getItem("token"));
  // }, []);

  let servicios = "{}";

  if (props.tiposServicios) {
    servicios = props.tiposServicios;
    // this.codPaisRem = 54;
    // this.CodPaisRemitente = 54;
  }

  const asignarParametros = (Id, Codigo, Total) => {
    let valores = { Id: Id, Codigo: Codigo, Total: Total };

    props.parametroSegundoForm(valores);
  };

  const handleSubmit = event => {
    // const form =
    //   event.currentTarget.parentNode[0] !== undefined
    //     ? event.currentTarget.parentNode
    //     : event.currentTarget.parentNode.parentNode;
    var nuevosCampos = campos;

    var form = document.getElementById("form-remitente");
    var valueNombreRemitente = document.getElementById("form-remitente")
      .elements["NombreRemitente"].value;
    var valueDireccionRemitente = "";
    var valueNumeroRemitente = "";
    var valuePisoRemitente = "";
    var valueDeptoRemitente = "";
    var valueCodigoPostal = "";
    var valueNroDocRemitente = document.getElementById("form-remitente")
      .elements["NroDocRemitente"].value;
    var valueCodAreaRemitente = document.getElementById("form-remitente")
      .elements["CodAreaRemitente"].value;
    var valueNroCelularRemitente = document.getElementById("form-remitente")
      .elements["NroCelularRemitente"].value;
    var valueEmailRemitente = document.getElementById("form-remitente")
      .elements["EmailRemitente"].value;
    var valueDatosAdicional = document.getElementById("form-remitente")
      .elements["DatosAdicional"].value;

    if (props.codigoProducto != "pm") {
      var valueDireccionRemitente = document.getElementById("form-remitente")
        .elements["DireccionRemitente"].value;
      var valueNumeroRemitente = document.getElementById("form-remitente")
        .elements["NumeroRemitente"].value;
      var valuePisoRemitente = document.getElementById("form-remitente")
        .elements["PisoRemitente"].value;
      var valueDeptoRemitente = document.getElementById("form-remitente")
        .elements["DeptoRemitente"].value;
      var valueCodigoPostal = document.getElementById("form-remitente")
        .elements["CodigoPostal"].value;
    }

    //if (event.currentTarget.parentNode[0] !== undefined) {
    if (form !== undefined) {
      if (props.codigoProducto != "pm") {
        // setNombreRemitente(validarTexto(form[0].value));
        // setDireccionRemitente(validarTexto(form[1].value));
        // setNumeroRemitente(validarEntero(form[2].value));
        // setPiso(validarEntero(form[3].value));
        // setDepto(validarTexto(form[4].value));
        // setCodigoPostal(validarEntero(form[5].value));
        // setNroDocRemitente(validarEntero(form[6].value));
        // // setCodPaisRemitente(validarTexto(form[7].value));
        // setCodPaisRemitente(codPaisRem);
        // setCodAreaRemitente(validarTexto(form[9].value));
        // setTelefonoRemitente(validarEntero(form[10].value));
        // setEmailRemitente(isValidEmail(form[11].value));
        // setDatosAdicional(validarTexto(form[12].value));
        // //setpago(validarEntero(form[0].value))

        setNombreRemitente(validarTexto(valueNombreRemitente));
        setDireccionRemitente(validarTexto(valueDireccionRemitente));
        setNumeroRemitente(validarEntero(valueNumeroRemitente));
        setPiso(validarEntero(valuePisoRemitente));
        setDepto(validarTexto(valueDeptoRemitente));
        setCodigoPostal(validarEntero(valueCodigoPostal));
        setNroDocRemitente(validarEntero(valueNroDocRemitente));
        setCodPaisRemitente(validarTexto(codPaisRem));
        setCodAreaRemitente(validarCodigoArea(valueCodAreaRemitente));
        setTelefonoRemitente(validarNumeroCelular(valueNroCelularRemitente));
        setEmailRemitente(isValidEmail(valueEmailRemitente));
        setDatosAdicional(valueDatosAdicional);

        var nuevosCampos = {
          NombreRemitente: valueNombreRemitente,
          DireccionRemitente: valueDireccionRemitente,
          NumeroRemitente: validarEntero(valueNumeroRemitente),
          PisoRemitente: valuePisoRemitente,
          DeptoRemitente: valueDeptoRemitente,
          CodigoPostal: validarEntero(valueCodigoPostal),
          NroDocRemitente: validarEntero(valueNroDocRemitente),
          CodPaisRemitente: validarTexto(codPaisRem),
          CodAreaRemitente: validarCodigoArea(valueCodAreaRemitente),
          NroCelularRemitente: validarNumeroCelular(valueNroCelularRemitente),
          EmailRemitente: isValidEmail(valueEmailRemitente),
          DatosAdicional: valueDatosAdicional
        };
        setCampos(nuevosCampos);

        if (form.checkValidity() === false) {
          setBoton(true);
        } else {
          setBoton(false);

          setValues({
            NombreRemitente: valueNombreRemitente,
            DireccionRemitente: valueDireccionRemitente,
            NumeroRemitente: validarEntero(valueNumeroRemitente),
            PisoRemitente: valuePisoRemitente,
            DeptoRemitente: valueDeptoRemitente,
            CodigoPostal: validarEntero(valueCodigoPostal),
            NroDocRemitente: validarEntero(valueNroDocRemitente),
            CodPaisRemitente: validarTexto(codPaisRem),
            CodAreaRemitente: validarCodigoArea(valueCodAreaRemitente),
            NroCelularRemitente: validarNumeroCelular(valueNroCelularRemitente),
            EmailRemitente: isValidEmail(valueEmailRemitente),
            DatosAdicional: valueDatosAdicional
          });

          let valores = {
            NombreRemitente: valueNombreRemitente,
            DireccionRemitente: valueDireccionRemitente,
            NumeroRemitente: valueNumeroRemitente,
            PisoRemitente: valuePisoRemitente,
            DeptoRemitente: valueDeptoRemitente,
            CodigoPostal: valueCodigoPostal,
            NroDocRemitente: valueNroDocRemitente,
            CodPaisRemitente: codPaisRem,
            CodAreaRemitente: valueCodAreaRemitente,
            NroCelularRemitente: valueNroCelularRemitente,
            EmailRemitente: valueEmailRemitente,
            DatosAdicional: valueDatosAdicional
          };
          props.parametroRemitente(valores);
        }
      } else {
        setNombreRemitente(valueNombreRemitente);
        setNroDocRemitente(valueNroDocRemitente);
        setCodPaisRemitente(codPaisRem);
        setCodAreaRemitente(valueCodAreaRemitente);
        setTelefonoRemitente(valueNroCelularRemitente);
        setEmailRemitente(valueEmailRemitente);
        setDatosAdicional(valueDatosAdicional);
        valueDireccionRemitente =
          props.pickupMovil.Descripcion + " - " + props.pickupMovil.Direccion;
        valueCodigoPostal = props.pickupMovil.CodigoPostal;

        var nuevosCampos = {
          NombreRemitente: validarTexto(valueNombreRemitente),
          DireccionRemitente: valueDireccionRemitente,
          NumeroRemitente: "",
          PisoRemitente: "",
          DeptoRemitente: "",
          CodigoPostal: validarEntero(valueCodigoPostal),
          NroDocRemitente: validarEntero(valueNroDocRemitente),
          CodPaisRemitente: validarTexto(codPaisRem),
          CodAreaRemitente: validarCodigoArea(valueCodAreaRemitente),
          NroCelularRemitente: validarNumeroCelular(valueNroCelularRemitente),
          EmailRemitente: isValidEmail(valueEmailRemitente),
          DatosAdicional: valueDatosAdicional
        };
        setCampos(nuevosCampos);

        //console.log("form.checkValidity(): " + form.checkValidity());
        if (form.checkValidity() === false) {
          setBoton(true);
        } else {
          setBoton(false);

          setValues({
            NombreRemitente: valueNombreRemitente,
            DireccionRemitente: valueDireccionRemitente,
            NumeroRemitente: "",
            PisoRemitente: "",
            DeptoRemitente: "",
            CodigoPostal: validarEntero(valueCodigoPostal),
            NroDocRemitente: validarEntero(valueNroDocRemitente),
            CodPaisRemitente: validarTexto(codPaisRem),
            CodAreaRemitente: validarCodigoArea(valueCodAreaRemitente),
            NroCelularRemitente: validarNumeroCelular(valueNroCelularRemitente),
            EmailRemitente: isValidEmail(valueEmailRemitente),
            DatosAdicional: valueDatosAdicional
          });

          let valores = {
            NombreRemitente: valueNombreRemitente,
            DireccionRemitente: valueDireccionRemitente,
            NumeroRemitente: "",
            PisoRemitente: "",
            DeptoRemitente: "",
            CodigoPostal: valueCodigoPostal,
            NroDocRemitente: valueNroDocRemitente,
            CodPaisRemitente: codPaisRem,
            CodAreaRemitente: valueCodAreaRemitente,
            NroCelularRemitente: valueNroCelularRemitente,
            EmailRemitente: valueEmailRemitente,
            DatosAdicional: valueDatosAdicional
          };

          props.parametroRemitente(valores);
        }
      }
      event.preventDefault();
      event.stopPropagation();
    } else {
      const form =
        event.currentTarget.parentNode.parentNode.parentNode[0] !== undefined
          ? event.currentTarget.parentNode.parentNode.parentNode
          : event.currentTarget.parentNode.parentNode.parentNode.parentNode;

      let numerosEnteros = [
        "NroDocRemitente",
        "NroCelularRemitente",
        "CodigoPostal",
        "NumeroRemitente"
      ];
      let numerosDecimal = [];
      let palabras = [
        "NombreRemitente",
        "DireccionRemitente",
        "PisoRemitente",
        "DeptoRemitente",
        "CodPaisRemitente",
        "CodAreaRemitente"
      ];
      let correo = ["EmailRemitente"];

      let validar = "";
      let valido = false;

      if (numerosEnteros.includes(event.currentTarget.name)) {
        validar = returnOnlyNumbers1(event.currentTarget.value);
        if (validar.length) {
          validar = validar.replace(/,/g, "");
          valido = true;
        }
        event.currentTarget.value = validar;
        setCampos({
          ...campos,
          [event.currentTarget.name]: validarEntero(validar)
        });
      } else {
        if (palabras.includes(event.currentTarget.name)) {
          validar = validarTexto(event.currentTarget.value);
          if (validar != "none") {
            valido = true;
          }
          setCampos({
            ...campos,
            [event.currentTarget.name]: validar
          });
        } else {
          if (correo.includes(event.currentTarget.name)) {
            validar = isValidEmail(event.currentTarget.value);
            if (validar == true) {
              valido = true;
            }
            setCorreoValidar(validar);
            setCampos({
              ...campos,
              [event.currentTarget.name]: validar ? "none" : "block"
            });
          }
        }
      }

      //console.log("form.checkValidity(): " + form.checkValidity() + "valido: " + valido);
      if (form.checkValidity() === false || valido != true) {
        setBoton(true);
      } else {
        setBoton(false);
        if (props.codigoProducto != "pm") {
          setValues({
            NombreRemitente: valueNombreRemitente,
            DireccionRemitente: valueDireccionRemitente,
            NumeroRemitente: validarEntero(valueNumeroRemitente),
            PisoRemitente: valuePisoRemitente,
            DeptoRemitente: valueDeptoRemitente,
            CodigoPostal: validarEntero(valueCodigoPostal),
            NroDocRemitente: validarEntero(valueNroDocRemitente),
            CodPaisRemitente: validarTexto(codPaisRem),
            CodAreaRemitente: validarCodigoArea(valueCodAreaRemitente),
            NroCelularRemitente: validarNumeroCelular(valueNroCelularRemitente),
            EmailRemitente: isValidEmail(valueEmailRemitente),
            DatosAdicional: valueDatosAdicional
          });

          let valores = {
            NombreRemitente: valueNombreRemitente,
            DireccionRemitente: valueDireccionRemitente,
            NumeroRemitente: valueNumeroRemitente,
            PisoRemitente: valuePisoRemitente,
            DeptoRemitente: valueDeptoRemitente,
            CodigoPostal: valueCodigoPostal,
            NroDocRemitente: valueNroDocRemitente,
            CodPaisRemitente: codPaisRem,
            CodAreaRemitente: valueCodAreaRemitente,
            NroCelularRemitente: valueNroCelularRemitente,
            EmailRemitente: valueEmailRemitente,
            DatosAdicional: valueDatosAdicional
          };
          props.parametroRemitente(valores);
        } else {
          setValues({
            NombreRemitente: valueNombreRemitente,
            NroDocRemitente: validarEntero(valueNroDocRemitente),
            CodPaisRemitente: validarTexto(codPaisRem),
            CodAreaRemitente: validarCodigoArea(valueCodAreaRemitente),
            NroCelularRemitente: validarNumeroCelular(valueNroCelularRemitente),
            EmailRemitente: isValidEmail(valueEmailRemitente),
            DatosAdicional: valueDatosAdicional
          });

          let valores = {
            NombreRemitente: valueNombreRemitente,
            NroDocRemitente: valueNroDocRemitente,
            CodPaisRemitente: codPaisRem,
            CodAreaRemitente: valueCodAreaRemitente,
            NroCelularRemitente: valueNroCelularRemitente,
            EmailRemitente: valueEmailRemitente,
            DatosAdicional: valueDatosAdicional
          };
          props.parametroRemitente(valores);
        }
      }
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleChangeCodPais = selectedOption => {
    if (selectedOption.value != null) {
      setCodPaisRem(selectedOption.value);
      setCodPaisRemitente(validarTexto(selectedOption.value));
    }
  };

  const siguiente = () => {
    props.cuartoForm();
  };

  const atrasForm2 = () => {
    props.atrasForm2();
  };

  const validarEntero = valor => {
    //intento convertir a entero.
    //si era un entero no le afecta, si no lo era lo intenta convertir
    valor = parseInt(valor);

    //Compruebo si es un valor numérico
    if (isNaN(valor) || valor < 0) {
      //entonces (no es numero) devuelvo el valor cadena vacia

      return "block";
    } else {
      //En caso contrario (Si era un número) devuelvo el valor
      return "none";
    }
  };

  const validarNumeroCelular = numero => {
    const regex = /^[0-9]{7,8}$/; // Expresión regular para validar un número de celular de entre 7 u 8 dígitos

    if (regex.test(numero)) {
      return "none";
    } else {
      return "block";
    }
    //return regex.test(numero);
  };

  const validarCodigoArea = numero => {
    const regex = /^[0-9]{1,4}$/; // Expresión regular para validar un número de area de 1 hasta 4 dígitos

    if (regex.test(numero)) {
      return "none";
    } else {
      return "block";
    }
    //return regex.test(numero);
  };

  const validarTexto = valor => {
    if (valor.length === 0) {
      return "block";
    } else {
      return "none";
    }
  };

  return (
    <div>
      <Form
        novalidate
        validated={validated}
        autoComplete="on"
        id="form-remitente"
      >
        {/* <h1 className="product-title-primary">
          Completá los datos del remitente
        </h1> */}
        <p>Los campos marcados con * son obligatorios</p>
        <Form.Row>
          {props.codigoProducto == "pm" && (
            <Col xs={12}>
              <Form.Group as={Col} controlId="NombreRemitente">
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Parada</th>
                      <th>Dirección</th>
                      <th>Horario Desde</th>
                      <th>Horario Hasta</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{props.pickupMovil.Fecha}</td>
                      <td>{props.pickupMovil.Descripcion}</td>
                      <td>{props.pickupMovil.Direccion}</td>
                      <td>{props.pickupMovil.HorarioDesde}</td>
                      <td>{props.pickupMovil.HorarioHasta}</td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
            </Col>
          )}
          <Col xs={12}>
            <Form.Group as={Col} controlId="NombreRemitente">
              <Form.Label>Nombre completo del remitente*</Form.Label>
              <Form.Control
                type="text"
                name="NombreRemitente"
                placeholder="Como figura en el documento"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NombreRemitente }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        {props.codigoProducto != "pm" && (
          <>
            <Form.Row>
              <Col xs={12}>
                <Form.Group as={Col} controlId="DireccionRemitente">
                  <Form.Label>Dirección*</Form.Label>
                  <Form.Control
                    type="text"
                    name="DireccionRemitente"
                    placeholder="Ejemplo: Avenida de los Incas"
                    required
                    onChange={handleSubmit}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: campos.DireccionRemitente }}
                  >
                    Campo obligatorio.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              {/* <Col md={4}> */}
              <Col>
                <Form.Group as={Col} controlId="NumeroRemitente">
                  <Form.Label>Número*</Form.Label>
                  <Form.Control
                    type="text"
                    name="NumeroRemitente"
                    placeholder="Ejemplo: 1652"
                    required
                    onChange={handleSubmit}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: campos.NumeroRemitente }}
                  >
                    {" "}
                    Campo obligatorio.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} controlId="PisoRemitente">
                  <Form.Label>Piso</Form.Label>
                  <Form.Control
                    type="text"
                    name="PisoRemitente"
                    placeholder="Ejemplo: 6"
                    onChange={handleSubmit}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} controlId="DeptoRemitente">
                  <Form.Label>Depto</Form.Label>
                  <Form.Control
                    type="text"
                    name="DeptoRemitente"
                    placeholder="Ejemplo: A"
                    onChange={handleSubmit}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} controlId="CodigoPostal">
                  <Form.Label>Código postal*</Form.Label>
                  <Form.Control
                    type="text"
                    name="CodigoPostal"
                    placeholder="Ejemplo: 1900, 1920, 1930, etc"
                    readOnly
                    value={props.CodigoPostalRemitente}
                    onChange={handleSubmit}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </>
        )}

        <Form.Row>
          <Col md={6}>
            <Form.Group as={Col} controlId="NroDocRemitente">
              <Form.Label>DNI/CUIL/CUIT del remitente*</Form.Label>
              <Form.Control
                type="text"
                name="NroDocRemitente"
                placeholder="Ejemplo: 21851985"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NroDocRemitente }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group as={Col} controlId="CodPaisRemitente">
              <Form.Label>Código pais*</Form.Label>
              <Select
                options={optionsCodPais}
                defaultValue={{ value: "54", label: "Arg +54" }}
                isClearable
                placeholder="Seleccione"
                name="CodPaisRemitente"
                onChange={handleChangeCodPais}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.CodPaisRemitente }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group as={Col} controlId="CodAreaRemitente">
              <Form.Label>Código de área*</Form.Label>
              <Form.Control
                type="text"
                name="CodAreaRemitente"
                placeholder="Ejemplo: 011"
                pattern="^[0-9]{1,4}$"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.CodAreaRemitente }}
              >
                ingrese un código de área válido.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group as={Col} controlId="NroCelularRemitente">
              <Form.Label>Celular*</Form.Label>
              <Form.Control
                type="text"
                name="NroCelularRemitente"
                placeholder="Ejemplo: 2345678"
                pattern="^[0-9]{7,8}$"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.NroCelularRemitente }}
              >
                Ingrese un numero de celular válido.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <Form.Group as={Col} controlId="EmailRemitente">
              <Form.Label>Email del remitente*</Form.Label>
              <Form.Control
                type="email"
                name="EmailRemitente"
                placeholder=""
                pattern="^[^@]+@[^@]+\.[a-zA-Z]{1,}$"
                required
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.EmailRemitente }}
              >
                Ingrese un email válido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="DatosAdicional">
              <Form.Label>Observaciones</Form.Label>
              <Form.Control
                type="text"
                name="DatosAdicional"
                placeholder=""
                onChange={handleSubmit}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: campos.DatosAdicional }}
              >
                Campo obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={{ span: 3, offset: 10 }}>
            <Button variant="secondary" onClick={atrasForm2}>
              Anterior
            </Button>{" "}
            {boton && (
              <Button variant="primary" onClick={handleDatosRemitente}>
                Siguiente
              </Button>
            )}
            {!boton && (
              <Button variant="primary" onClick={siguiente}>
                Siguiente
              </Button>
            )}
          </Col>
        </Form.Row>
        {/* <br /> */}
      </Form>
    </div>
  );
}
export default DatosRemitente;
