import React from "react";
import { any, node, string } from "prop-types";
import "./styles.css";

const Product = ({ title, subtitle, children, hasExtraInfo, image }) => (
  <div className="content-products">
    <div className="content-products-info">
      <div class="row">
        <div class="col-md-10">
          <div className="product-title">
            <h1 className="product-title-primary">
              {title} <span className={"product-slash"}>/</span>
            </h1>
            <h3 className="product-title-secondary">{subtitle}</h3>
          </div>
          <div className="product-detail">{children}</div>
          {hasExtraInfo}
        </div>
        <div class="col-md-2">
          <div className="image-contents">
            {image.map((img, index) => (
              <img
                key={index}
                className={`icon-img ${index > 0 ? "second-img" : ""}`}
                src={img}
                alt="Producto"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

Product.propTypes = {
  title: string,
  subtitle: string,
  children: node,
  hasExtraInfo: node,
  image: any
};

export default Product;
