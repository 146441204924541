import React from "react";
import { string } from "prop-types";
import "./styles.css";

const Item = ({ children, title }) => (
  <div className="item">
    <h2 className="item-title">{title}</h2>
    <p className="item-details">{children}</p>
    <a href="/services" className="link">
      Saber más
    </a>
  </div>
);

Item.propTypes = {
  children: string,
  title: string
};

export default Item;
