import React from "react";

import "./styles.css";

export const userCategory = ({
  handleCategoria,
  handleComunicar,
  comunicar,
  siguiente,
  categoria,
  activeA,
  activeB,
  activeC
}) => {
  let url = "";
  if (comunicar == "on") {
    url = "/userCategoryConfirm/" + categoria + "/" + comunicar;
  } else {
    url = "/userCategoryConfirm2/" + categoria;
  }
  return (
    <section className="screen-contents contact-screen">
      <div className="details-container">
        <div className="content">
          <div className="container category-container">
            <div className="category-content">
              <div className="title-category-container">
                <h4>¡Hola {window.localStorage.getItem("loggedUser")}</h4>
              </div>
              <div className="sub-title-category-container">
                <h5>Bienvenido a Mi Vía Cargo. Tu cuenta se creó con éxito.</h5>
              </div>
              <div className="text-category-container">
                <p>
                  Ahora podés realizar tus envíos a cualquier parte del país,
                  seguirlos y pagarlos online.
                </p>
              </div>
              <div className="text-category-container2">
                <p>Para comenzar, seleccioná tu categoría:</p>
              </div>
              <div className="input-category-container">
                <input
                  type="button"
                  value="Emprendedor"
                  className={
                    activeA ? "category-input active" : "category-input"
                  }
                  required
                  onClick={handleCategoria}
                  id="activeA"
                />
                <input
                  type="button"
                  value="Pyme"
                  className={
                    activeB ? "category-input active" : "category-input"
                  }
                  required
                  onClick={handleCategoria}
                  id="activeB"
                />
                <input
                  type="button"
                  value="Empresa"
                  className={
                    activeC ? "category-input active" : "category-input"
                  }
                  required
                  onClick={handleCategoria}
                  id="activeC"
                />
              </div>
              <div className="input-category-checkbox-container">
                <input
                  type="checkbox"
                  className="category-input-checkbox"
                  required
                  onClick={handleComunicar}
                />
                <p>
                  Quiero que se comuniquen conmigo para abrir una cuenta
                  corriente.
                </p>
              </div>
              <div className="button-category-container">
                {siguiente ? (
                  <a href={url}>
                    <input
                      type="button"
                      value="Siguiente"
                      name="buttonRecover"
                    />
                  </a>
                ) : (
                  <input type="button" value="Siguiente" name="buttonRecover" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default userCategory;
