import React, { Component } from "react";

import "./styles.css";

class CustomerSupport extends Component {
  constructor() {
    super();
    this.state = {
      width: 850,
      height: 800
    };
  }

  updateDimensions() {
    if (window.innerWidth < 900) {
      let update_width = window.innerWidth - 65;
      let update_height = window.innerHeight;
      this.setState({ width: update_width, height: update_height });
    } else if (window.innerWidth < 1500) {
      let update_width = window.innerWidth - 460;
      let update_height = window.innerHeight;
      this.setState({ width: update_width, height: update_height });
    } else {
      let update_width = window.innerWidth - 490;
      let update_height = window.innerHeight;
      this.setState({ width: update_width, height: update_height });
    }
  }

  componentWillMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <div style={{ marginTop: 30 }}>
        <iframe
          src="https://viacargo.freshdesk.com/widgets/feedback_widget/new?captcha=yes&formTitle=Atencion+al+cliente&screenshot=No&searchArea=no&submitThanks=Enviamos+un+correo+electr%C3%B3nico+para+que+usted+pueda+realizar+un+seguimiento+a+su+consulta.+Gracias+por+sus+comentarios.+&submitTitle=Enviar+comentario&widgetType=embedded"
          height={this.state.height}
          width={this.state.width}
          className="iframe"
          title="Atención al cliente"
        />
      </div>
    );
  }
}

export default CustomerSupport;
