import React, { useEffect } from "react";
import "./styles.css";
import maintenanceIcon from "./maintenance.png";

const Maintenance = () => {
  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          const chatElement = document.getElementById("wcx-chat");
          if (chatElement) {
            chatElement.parentNode.removeChild(chatElement);
          }
        }
      }
    });

    // Start observing the document body for added nodes
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="maintenance">
      <div>
        <img
          src={maintenanceIcon}
          className="maintenance-icon"
          alt="Maintenance Icon"
        />
        Sistema en mantenimiento.
      </div>
    </div>
  );
};

export default Maintenance;
