import React from "react";
import paso1 from "assets/pasos/paso-1.png";
import paso2 from "assets/pasos/paso-2.png";
import paso3 from "assets/pasos/paso-3.png";
import paso4 from "assets/pasos/paso-4.png";
import Sidebar from "shared/components/Sidebar/Sidebar";
import background from "assets/backgrounds/05.jpg";
import "./styles.css";

const Tips = () => (
  <section className="screen-contents tips-screen">
    <div className="background">
      <img src={background} alt="Fondo" />
    </div>
    <div className="details-container">
      <div className="content">
        <div className="content-title">
          <h1>Tips / Cómo realizar sus envíos</h1>
        </div>

        <div className="steps-for-tips">
          <div className="step-item">
            <h1 className="step-number">1</h1>
            <div className="step-icon">
              <img src={paso1} className="step-img" alt="Cotizar" />
            </div>
            <div className="step-description">
              Cotizá tu envío online / método de envío
            </div>
          </div>

          <div className="step-item">
            <h1 className="step-number">2</h1>
            <div className="step-icon">
              <img src={paso2} className="step-img" alt="Preparar" />
            </div>
            <div className="step-description">
              Preparalo de manera adecuada y segura.
            </div>
          </div>

          <div className="step-item">
            <h1 className="step-number">3</h1>
            <div className="step-icon">
              <img src={paso3} className="step-img" alt="Localizar" />
            </div>
            <div className="step-description">
              Localizá la agencia más cercana.
            </div>
          </div>

          <div className="step-item">
            <h1 className="step-number">4</h1>
            <div className="step-icon">
              <img src={paso4} className="step-img" alt="Entregar" />
            </div>
            <div className="step-description">
              Entregá tu envío y guardá el número de rastreo.
            </div>
          </div>
        </div>

        <div className="tips-suggestion">
          <h6>
            Para garantizar que los envíos lleguen a tiempo y en óptimas
            condiciones de entrega, es fundamental que te tomes el tiempo
            necesario para embalarlos y etiquetarlos correctamente.
            <br/><br/>
            En todos los casos el acondicionamiento del embalaje de las 
            piezas es responsabilidad del remitente
          </h6>
        </div>

        <div className="separator-suggestion" />

        <div className="pack-suggestion">
          <h1 className="pack-title">Empaque</h1>
          <p className="pack-description">
            El empaque de un producto es el contenedor que protege el envío.
            Sirve principalmente para preservar las condiciones propias del
            producto, su imagen y la descripción de uso o consumo, además de
            tener un diseño que evita el daño durante su manipulación.
          </p>
          {/*<select>*/}
          {/*<option>Ver packs</option>*/}
          {/*</select>*/}
        </div>

        <div className="pack-suggestion">
          <h1 className="pack-title">Embalaje</h1>
          <p className="pack-description">
            El embalaje es la envoltura contenedora que permite transportar,
            manipular y almacenar el producto, individual o grupalmente. Su
            función principal es la protección y manipulación. Prepará el
            embalaje de manera segura y adecuada teniendo en cuenta.
          </p>

          <div className="embalaje-suggestion">
            <h1 className="embalaje-suggestion-title">A. Amortiguación</h1>
            <p className="embalaje-suggestion-description">
              Elegí la amortiguación con la densidad y el grosor adecuado y
              utilizá lo suficiente para rellenar todos los espacios vacíos en
              el contenedor de envío.
            </p>
          </div>

          <div className="embalaje-suggestion">
            <h1 className="embalaje-suggestion-title">B. Envolturas</h1>
            <p className="embalaje-suggestion-description">
              Envolver los productos u objetos con papeles, cartón corrugado o
              almohadillas, ayuda a proteger de manera más eficiente el
              contenido del envío. Esta recomendación es mayor si es frágil y
              delicado.
            </p>
          </div>

          <div className="embalaje-suggestion">
            <h1 className="embalaje-suggestion-title">C. Sellado</h1>
            <p className="embalaje-suggestion-description">
              Aplicá al menos tres bandas de cinta para embalar (no cinta
              americana o cinta adhesiva de pintor) en la parte superior e
              inferior del contenedor utilizando el método H de sellado.
            </p>
          </div>
        </div>

        <div className="pack-suggestion">
          <h1 className="pack-title">Identificación</h1>
          <p className="pack-description">
            Una vez que el producto se encuentra debidamente empacado y
            embalado, se debe colocar en un sitio visible de la encomienda la
            etiqueta que indique cómo tiene que ser manipulado y conservado. Es
            necesario para aclarar si requiere de algún manejo especial.
          </p>
        </div>

        <div className="pack-suggestion">
          <h1 className="pack-title">Protección</h1>
          <p className="pack-description">
            Es importante que el producto esté protegido de los impactos, golpes
            y caídas, al igual que de los agentes biológicos y climáticos que
            podrían presentarse durante el transporte. Recomendamos usar algunos
            de los siguientes materiales: metal, madera, cartón corrugado,
            plásticos, entre otros.
          </p>
        </div>

        <div className="pack-suggestion">
          <h1 className="pack-title">Sujeción y agarre</h1>
          <p className="pack-description">
            Para su mayor seguridad, es preferible que se utilicen películas
            plásticas adheribles (Strech), zunchos, cintas o ganchos para sellar
            los empaques y embalajes, y facilitar así su transporte,
            manipulación y almacenamiento.
          </p>
        </div>
      </div>
      <Sidebar />
    </div>
  </section>
);

export default Tips;
