import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const FooterMobile = () => (
  <footer className="footer">
    <section className="content-link">
      <section className="links-info">
        <div className="links">
          <Link to="/terms" className="footer-link">
            <span>Términos y condiciones de Servicio</span>
          </Link>
          <Link to="/rezagos" className="footer-link">
            <span>Rezagos</span>
          </Link>
          <Link to="/terms" className="footer-link">
            <span>Términos de uso del sitio web</span>
          </Link>
          <Link to="/terms" className="footer-link">
            <span>Política de privacidad</span>
          </Link>

          <div className={"afip-qr-container"}>
            <div className={"afip-qr"}>
              <a
                href="http://qr.afip.gob.ar/?qr=RjSR1DjFeyhV-kw2OpZQiA,,"
                target="_F960AFIPInfo"
              >
                <img
                  src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                  border="0"
                  alt={"AFIP"}
                />
              </a>
            </div>
            <div className={"afip-qr"}>
              <a
                href="http://qr.afip.gob.ar/?qr=_w3RjSKvd8x5wJmo2ZJIJQ,,"
                target="_F960AFIPInfo"
              >
                <img
                  src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                  border="0"
                  alt={"AFIP"}
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </section>

    <section className="terms">
      <p>©VíaCargo</p>
      {/* <div className={"terms-services-mobile"}>
       
        <Link to="/terms">
          <p className="terms-title">Términos y condiciones de Servicio</p>
        </Link>
        <Link to="/rezagos">
          <p className="terms-title">Rezagos</p>
        </Link>
        <Link to="/terms">
          <p className="terms-title">Términos de uso del sitio web</p>
        </Link>
        <Link to="/terms">
          <p className="terms-title">Política de privacidad</p>
        </Link>
      </div> */}
    </section>
  </footer>
);

export default FooterMobile;
