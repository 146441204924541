import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import axios from "axios";
import Modal from "simple-react-modal";
import { isValidEmail, returnOnlyNumbers1 } from "../../../../utils/index";

import UserRegisterScreen from "./userRegister";

export const Container = props => {
  const [name, setName] = useState(""),
    [nameError, setNameError] = useState(null),
    [lastName, setLastNameHandler] = useState(""),
    [lastNameError, setLastNameError] = useState(null),
    [email, setEmail] = useState(""),
    [emailError, setEmailError] = useState(null),
    [numberDni, setNumberDni] = useState(""),
    [numberDniError, setNumberDniError] = useState(null),
    [address, setAddress] = useState(""),
    [addressError, setAddressError] = useState(null),
    [numberAddress, setNumberAddress] = useState(""),
    [numberAddressError, setNumberAddressError] = useState(null),
    [codeAddress, setCodeAddress] = useState(""),
    [codeAddressError, setCodeAddressError] = useState(null),
    [codeArea, setCodeArea] = useState(""),
    [codeAreaError, setCodeAreaError] = useState(null),
    [phone, setPhone] = useState(""),
    [phoneError, setPhoneError] = useState(null),
    [password, setPassword] = useState(""),
    [passwordError, setPasswordError] = useState(null),
    [confirmPassword, setConfirmPassword] = useState(""),
    [confirmPasswordError, setConfirmPasswordError] = useState(null),
    [redirect, setRedirect] = useState(""),
    [storeId, setStoreId] = useState(
      window.localStorage.getItem("StoreId") || ""
    ),
    [activar, setActivar] = useState(true),
    [activarMensaje, setActivarMensaje] = useState("CARGANDO FORMULARIO ..."),
    [xhr, setXhr] = useState(""),
    [showModal, setShowModal] = useState(""),
    [errorMessage, setErrorMessage] = useState(""),
    [errorMessageTitle, setErrorMessageTitle] = useState(""),
    [waitMessage, setWaitMessage] = useState(""), //Tarea GDM-1274
    [enableModal, setEnableModal] = useState(false), //Tarea GDM-1274
    [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    // para obtener el code enviado en la url por tiendanube
    let info = props.location.search.split("=");
    let consultarApi = true;
    setActivar(false); //Arranca el form desactivado
    if (info.length > 1) {
      let token = process.env.REACT_APP_API_URL + "/api/tokentn";
      let datos = { info: info[1] };

      //let datos = {info:"3305ada2ea160fd5cb22f20e625d2aee4dbf7dd3"};

      //let datos = {info:"3305ada2ea160fd5cb22f20e625d2aee4dbf7dd3"};
      consultarApi = validarToken(datos.info);

      if (consultarApi) {
        consultarApi = false;
        setActivar(false);
        setTimeout(function() {
          axios
            .get(token, { params: datos })
            .then(response => {
              let { data } = response;
              if (data.status && data.user_id) {
                datos = {
                  Email: null,
                  StoreId: data.user_id
                };
                setStoreId(data.user_id);
                localStorage.setItem("estadoTiendaNubeToken", true);
                localStorage.setItem("StoreId", data.user_id);
                /* Tarea GDM-965 - Inicio */
                let cliente_existe =
                  process.env.REACT_APP_API_URL + "/api/cliente";
                axios
                  .get(cliente_existe, { params: datos })
                  .then(response => {
                    console.log("vc: response", JSON.stringify(response));
                    let { data } = response;
                    if (data.status) {
                      /*Tarea GDM-1139 - Inicio */
                      if (data.cliente.CorreoValidado) {
                        /*Si el cliente existe y esta validado se fuerza la ejecucion de carrie y carrieOp*/
                        setShowModal(true); //Tarea GDM-1274
                        setWaitMessage(
                          "Estamos procesando sus datos.Espere por favor......"
                        ); //Tarea GDM-1274
                        let store;
                        store = { store: data.cliente.TiendaNubeStoreID };
                        let carrie =
                          process.env.REACT_APP_API_URL + "/api/carrie";
                        let carrieOp =
                          process.env.REACT_APP_API_URL + "/api/carrieOp";
                        let usuario = 0;
                        //if(data.cliente.CarrierID === null){
                        axios
                          .get(carrie, { params: store })
                          .then(response => {
                            let { data } = response;
                            if (data.status && data.store_id) {
                              usuario = { usuario: data.store_id };
                              axios
                                .get(carrieOp, { params: usuario })
                                .then(response => {
                                  let { data } = response;
                                  //console.log(data);
                                  /* Tarea GDM-1274 - Inicio */
                                  setEnableModal(true);
                                  setRedirect("/login");
                                  setWaitMessage("");
                                  setErrorMessage(
                                    "El cliente ya existe en nuestra base de datos." +
                                      "Será redirigido a la pagina de ingreso de usuario al cerrar este mensaje"
                                  );
                                  /* Tarea GDM-1274 - Fin */
                                })
                                .catch(exc => {
                                  setXhr(false);
                                  setShowModal(true);
                                  setErrorMessage(
                                    "Error al obtener opciones de carrie"
                                  );

                                  console.log(exc);
                                });
                            }
                          })
                          .catch(ex => {
                            setXhr(false);
                            setShowModal(true);
                            setErrorMessage("Error al obtener datos de carrie");
                            console.log(ex);
                          });
                        setXhr(false);
                        //setShowModal(true);
                        //setRedirect("/login")
                        //setErrorMessage("El cliente ya existe en nuestra base de datos.Puede cerrar este mensaje");
                        // }
                      } else {
                        /*Si el cliente existe pero no esta validado se le vuelve a enviar el correo de activacion */
                        let enviar_correo =
                          process.env.REACT_APP_API_URL + "/api/enviar_correo";
                        const dataCorreo = {
                          correo: data.cliente.Email,
                          name: data.cliente.Nombre,
                          lastname: data.cliente.Apellido,
                          nrodoc: data.cliente.NroDoc,
                          telefono: data.cliente.Telefono,
                          tiendanubestore: data.cliente.TiendaNubeStoreID
                        };
                        axios
                          .post(enviar_correo, dataCorreo)
                          .then(response => {
                            let { data } = response;
                            if (data.status) {
                              setTimeout(() => {
                                window.location.href = "/login/validar";
                              }, 2000);
                              //enviar correo y redirect a login
                            } else {
                              setActivarMensaje(
                                "hubo un problema. " + data.Mensaje
                              );
                              setXhr(false);
                              setShowModal(true);
                              setErrorMessage("server-error: " + data.Mensaje);
                            }
                          })
                          .catch(e => {
                            setActivarMensaje(
                              "Hubo un error al intentar enviar el correo de activacion"
                            );
                            setXhr(false);
                            setShowModal(true);
                            setErrorMessage(
                              "server-error: " + e.response.data.message
                            );
                          });
                      }
                      /*Tarea GDM-1139 - Fin */
                    } else {
                      setActivar(true);
                    }
                  })
                  .catch(e => {
                    setActivarMensaje(
                      "hubo un problema al guardar los datos. " + data.message
                    );
                    setXhr(false);
                    setShowModal(true);
                    setErrorMessage("server-error: " + e.response.data.message);
                  });
                /* Tarea GDM-965 - Fin */
              }
            })
            .catch(e => {
              setActivarMensaje(
                "hubo un problema al carga el formulario. " +
                  e.response.data.message
              );
              setXhr(false);
              setShowModal(true);
              setErrorMessage("server-error: " + e.response.data.message);
              const errorTitle =
                e.response.data.message ===
                "invalid_grant - Authorization code doesn't " +
                  "exist or is invalid for the client (TiendaNube)"
                  ? "A ocurrido un error al configurar la app de Via Cargo." +
                    "Por favor desinstálela y vuelva a instalarla."
                  : "";

              const error =
                e.response.data.message ===
                "invalid_grant - Authorization code doesn't " +
                  "exist or is invalid for the client (TiendaNube)"
                  ? "En caso de que el error persista enviar un mail a integraciones.vc@viacorreo.com.ar"
                  : "server-error:    " + e.response.data.message;
              setErrorMessageTitle(errorTitle);
              setErrorMessage(error);

              localStorage.setItem("estadoTiendaNubeToken", false);
              console.log(e);
            });
        }, 2000);
      }
    }
  }, []);

  const validarToken = token => {
    //Valida el token de Tiendanube, si falló al chequear con la API
    //devolverá false, sino devolvera true hasta que el token cambie
    if (!localStorage.getItem("tokenTiendaNube")) {
      localStorage.setItem("tokenTiendaNube", token);
    }
    if (localStorage.getItem("tokenTiendaNube") !== token) {
      localStorage.setItem("tokenTiendaNube", token);
      setActivar(false);
      localStorage.setItem("estadoTiendaNubeToken", false);
      return true;
    } else {
      if (localStorage.getItem("estadoTiendaNubeToken") === "true") {
        setActivar(true);
        return false;
      } else {
        setActivar(false);
        return true;
      }
    }
  };

  const nameHandler = e => {
    const inputValue = e.target.value;
    setName(inputValue);
  };

  const lastNameHandler = e => {
    const inputValue = e.target.value;
    setLastNameHandler(inputValue);
  };

  const emailHandler = e => {
    const inputValue = e.target.value;
    setEmail(inputValue);
  };

  const numberDniHandler = e => {
    const inputValue = e.target.value;
    setNumberDni(inputValue);
  };

  const addressHandler = e => {
    const inputValue = e.target.value;
    setAddress(inputValue);
  };

  const numberAddressHandler = e => {
    const inputValue = e.target.value;
    setNumberAddress(inputValue);
  };

  const codeAddressHandler = e => {
    const inputValue = e.target.value;
    setCodeAddress(inputValue);
  };

  const codeAreaHandler = e => {
    const inputValue = e.target.value;
    setCodeArea(inputValue);
  };

  const phoneHandler = e => {
    const inputValue = e.target.value;
    setPhone(inputValue);
  };

  const passwordHandler = e => {
    const inputValue = e.target.value;
    setPassword(inputValue);
  };

  const confirmPasswordHandler = e => {
    const inputValue = e.target.value;
    setConfirmPassword(inputValue);
    /*if(password != confirmPassword){
      setConfirmPasswordError("La confirmación de clave no es la misma");
      return false;
    }*/
  };

  const submitForm = () => {
    setNameError("");
    setLastNameError("");
    setEmailError("");
    setNumberDniError("");
    setAddressError("");
    setNumberAddressError("");
    setCodeAddressError("");
    setCodeAreaError("");
    setPhoneError("");
    setPasswordError("");
    setConfirmPasswordError("");

    if (!name) {
      setNameError("Nombre no puede estar vacio");
      return false;
    } else if (name.length < 4) {
      setNameError("Nombre debe contener almenos 4 caracteres");
      return false;
    }

    if (!lastName) {
      setLastNameError("Apellido no puede estar vacio");
      return false;
    } else if (lastName.length < 4) {
      setLastNameError("apellido debe contener almenos 4 caracteres");
      return false;
    }

    if (!email) {
      setEmailError("Correo inválido");
      return false;
    } else if (!isValidEmail(email)) {
      setEmailError("Correo inválido");
      return false;
    }

    if (!numberDni) {
      setNumberDniError("Numero de documento es obligatorio");
      return false;
    } else if (!returnOnlyNumbers1(numberDni)) {
      setNumberDniError("Numero de documento es obligatorio");
      return false;
    }

    if (!address) {
      setAddressError("El campo es obligatorio");
      return false;
    }

    if (!numberAddress) {
      setNumberAddressError("El Campo es obligatorio");
      return false;
    }
    /*else if(numberAddress.length < 4) {
      setNumberAddressError("numero de dirección debe contener almenos 3 caracteres");
        return false;
      }*/

    if (!codeAddress) {
      setCodeAddressError("EL Campo es obligatorio");
      return false;
    } else if (codeAddress.length < 4) {
      setCodeAddressError("código debe contener almenos 4 caracteres");
      return false;
    }

    if (!codeArea) {
      setCodeAreaError("El Campo es obigatorio");
      return false;
    } else if (codeArea.length < 2) {
      setCodeAreaError("código de area debe contener almenos 3 caracteres");
      return false;
    }

    if (!phone) {
      setPhoneError("El Campo es obligatorio");
      return false;
    }

    if (!password) {
      setPasswordError("El Campo es obligatorio");
      return false;
    } else if (password.length < 8) {
      setPasswordError("clave debe contener almenos 8 caracteres");
      return false;
    } else {
      let valido = false;
      let valido1 = false;
      for (var i = 0; i < password.length; i++) {
        if (password[i].match(/[A-Z]/)) {
          valido = true;
        }
      }

      var regex = /(\d+)/g;

      if (password.match(regex)) {
        valido1 = true;
      }

      if (!valido1 || !valido) {
        setPasswordError(
          "la clave debe contener al menus una letra mayúscula y al menos un número"
        );
        return false;
      }
    }

    if (!confirmPassword) {
      setConfirmPasswordError("El Campo es obligatorio");
      return false;
    }

    if (password != confirmPassword) {
      setConfirmPasswordError("La confirmación de clave no es la misma");
      return false;
    }

    let clientes = process.env.REACT_APP_API_URL + "/api/clientes";
    let enviar_correo = process.env.REACT_APP_API_URL + "/api/enviar_correo";
    const dataCliente = {
      Email: email,
      Password: password,
      Nombre: name,
      Apellido: lastName,
      Direccion: address + " " + numberAddress,
      CodigoPostal: codeAddress,
      CodArea: codeArea,
      TipoDoc: "DNI",
      NroDoc: numberDni,
      IdCliente: "00000423",
      IdCentro: "02",
      Telefono: phone,
      TiendaNubeStoreID: storeId
    };

    const headers = {
      //Service_Key: config.credentials.service_key,
      "Content-Type": "application/json; charset=utf8"
    };

    const dataCorreo = {
      correo: email,
      name: name,
      lastname: lastName,
      nrodoc: numberDni,
      telefono: phone,
      tiendanubestore: storeId
    };
    /*Tarea GDM-965 - Inicio */
    let cliente_existe = process.env.REACT_APP_API_URL + "/api/cliente";
    let datos = {
      Email: null,
      StorageId: localStorage.getItem("StoreId")
    };
    axios
      .get(cliente_existe, { params: datos })
      .then(response => {
        let { data } = response;
        console.log(data.status);
        if (!data.status) {
          axios
            .post(clientes, dataCliente)
            .then(response => {
              let { data } = response;

              if (data.status) {
                axios
                  .post(enviar_correo, dataCorreo)
                  .then(response => {
                    let { data } = response;
                    /*Tarea GDM-967 - Inicio;
                  let store;
                  store = { store: storeId };
                  let carrie = "/api/carrie";
                  let carrieOp = "/api/carrieOp";
                  let usuario=0;
                  axios
                    .get(carrie, { params: store })
                    .then(response => {
                    let { data } = response;
                    if (data.status && data.store_id) {
                      usuario = { usuario: data.store_id };
    
                      axios
                        .get(carrieOp, { params: usuario })
                        .then(response => {
                          let { data } = response;
    
                          if (data.status) {
                            setActivar(true);
                          }
                        })
                        .catch(exc => {
                          setActivarMensaje(
                            "hubo un problema al carga el formulario. " +
                              exc.response.data.message
                          );
                          setXhr(false);
                          setShowModal(true);
                          setErrorMessage(
                            "server-error: " + exc.response.data.message
                          );
    
                          console.log(exc);
                        });
                    }
                  })
                  .catch(ex => {
                    setActivarMensaje(
                      "hubo un problema al carga el formulario. " +
                        ex.response.data.message
                    );
                    setXhr(false);
                    setShowModal(true);
                    setErrorMessage("server-error: " + ex.response.data.message);
    
                    console.log(ex);
                  });
                  Tarea GDM-967 - Fin */
                    if (data.status) {
                      setTimeout(() => {
                        window.location.href = "/login/validar";
                      }, 2000);
                      //enviar correo y redirect a login
                    } else {
                      setActivarMensaje("hubo un problema. " + data.Mensaje);
                      setXhr(false);
                      setShowModal(true);
                      setErrorMessage("server-error: " + data.Mensaje);
                    }
                  })
                  .catch(e => {
                    setActivarMensaje(
                      "hubo un problema al guardar los datos. " + data.message
                    );
                    setXhr(false);
                    setShowModal(true);
                    setErrorMessage("server-error: " + e.response.data.message);
                  });

                //enviar correo y redirect a login
                //window.location.href = "/login/validar"
                //setRedirect(true)
              } else {
                setActivarMensaje("hubo un problema. " + data.message);
                setXhr(false);
                setShowModal(true);
                setErrorMessage("server-error: " + data.message);
              }
            })
            .catch(e => {
              setActivarMensaje(
                "hubo un problema al guardar los datos. " +
                  e.response.data.message
              );
              setXhr(false);
              setShowModal(true);
              setErrorMessage("server-error: " + e.response.data.message);

              console.log(e);
            });
        }
      })
      .catch(e => {
        setActivarMensaje(
          "hubo un problema al guardar los datos. " + e.response.data.message
        );
        setXhr(false);
        setShowModal(true);
        setErrorMessage("server-error: " + e.response.data.message);
      });
    /*Tarea GDM-965 - Fin */
  };
  /*Tarea GDM-965 - Inicio */
  const cerrar = e => {
    setShowModal(false);

    console.log("URL de redireccion : ", redirect);
    if (redirect) {
      window.location.href = redirect;
    }
  };
  /*Tarea GDM-965 - Fin */

  return (
    <div>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        className={"modal"}
        containerClassName={"modal-container"}
        closeOnOuterClick={false}
      >
        {/* Tarea GDM-1274 - Inicio*/}
        {enableModal && (
          <div className={"close-button"}>
            <i className={"material-icons"} onClick={() => cerrar()}>
              close
            </i>
          </div>
        )}
        {/* Tarea GDM-1274 - Fin*/}
        <div className={"modal-body"}>
          {modalMessage}
          <h5>{errorMessageTitle}</h5>
          {errorMessage}
          <h4>{waitMessage}</h4>
          <div>
            {/* Tarea GDM-1274 - Inicio*/}
            {enableModal && (
              <button
                className="quote btn btn-default"
                onClick={() => cerrar()} //Tarea GDM-965 se cambio "setShowModal" por "cerrar"
              >
                Cerrar
              </button>
            )}
            {/* Tarea GDM-1274 - Fin*/}
          </div>
        </div>
      </Modal>
      {activar && (
        <UserRegisterScreen
          submitForm={submitForm}
          nameHandler={nameHandler}
          nameError={nameError}
          lastNameHandler={lastNameHandler}
          lastNameError={lastNameError}
          emailHandler={emailHandler}
          emailError={emailError}
          numberDniHandler={numberDniHandler}
          numberDniError={numberDniError}
          addressHandler={addressHandler}
          addressError={addressError}
          numberAddressHandler={numberAddressHandler}
          numberAddressError={numberAddressError}
          codeAddressHandler={codeAddressHandler}
          codeAddressError={codeAddressError}
          codeAreaHandler={codeAreaHandler}
          codeAreaError={codeAreaError}
          phoneHandler={phoneHandler}
          phoneError={phoneError}
          passwordHandler={passwordHandler}
          passwordError={passwordError}
          confirmPasswordHandler={confirmPasswordHandler}
          confirmPasswordError={confirmPasswordError}
          activar={activar}
          activarMensaje={activarMensaje}
        />
      )}
    </div>
  );
};

export default Container;
