import React from "react";
import Sidebar from "shared/components/Sidebar/Sidebar";
import background from "assets/backgrounds/02.jpg";
import "./styles.css";

const Packs = () => (
  <section className="screen-contents">
    <div className="background">
      <img src={background} alt="Fondo" />
    </div>
    <div className="details-container">
      <div className="content">
        <div className="content-title">
          <h1>Packs</h1>
        </div>

        <div className="packs-content">
          <h1>Próximamente.</h1>
          <p>
            Estamos renovando nuestros empaques para brindarte un mejor
            servicio.
          </p>
        </div>
      </div>
      <Sidebar />
    </div>
  </section>
);

export default Packs;
