import React from "react";
import Sidebar from "shared/components/Sidebar/Sidebar";
import background from "assets/backgrounds/01.jpg";
import "./styles.css";

const Terms = () => (
  <section className="screen-contents terms-screen">
    <div className="background">
      <img src={background} alt="Fondo" />
    </div>
    <div className="details-container">
      <div className="content">
        <div className="content-title">
          <h1>Términos y Condiciones del servicio</h1>
        </div>
        <div className="terms-content">
          <p>
            'VIA CARGO' es la denominación comercial para los servicios de carga
            y postales habilitados para VIA BARILOCHE S.A. y VIA CARGO S.A.
            (RNPSP N° 532 y 870). Los servicios postales "VIA CARGO" se rigen
            por el Decreto N° 1187/93 (t.o. Decreto 115/97), la Ley N° 20126 de
            Correos (Arts. 6,11,17,20,26,30,31,32,34,43,44), su Decreto
            Reglamentario N° 151/74 (Arts. 11,17,20,26,30) y demás normas
            vigentes. En consecuencia, los envíos postales son inviolables, lo
            que implica la obligación de no abrirlos, apoderarse de ellos,
            suprimirlos, dañarlos o desviarlos intencionalmente de su curso, ni
            tratar de conocer su contenido. Es obligatorio declarar el valor del
            contenido en los envíos que se incluya papel moneda, alhajas,
            monedas metálicas, objetos preciosos o valores al portador u otros
            objetos de valor cuyo valor real supere los límites mínimos y
            máximos de responsabilidad que asume VÍA CARGO, y que se encuentran
            disponibles en www.viacargo.com.ar y en los puntos de atención de la
            empresa al momento de despachar envío. En estos casos, será
            obligatorio que incluyan una prima de reembolso, que deberá
            ajustarse al valor real del contenido. El costo de la prima
            ascenderá al 5 % (más I.V.A), sobre el valor declarado. El valor
            mínimo de la prima se encuentra disponible en www.viacargo.com.ar y
            en los puntos de atención de la empresa al momento de despachar
            envío. Se entenderá por valor declarado, el valor declarado en la
            guía al momento de despachar el envío, marcando el límite máximo de
            responsabilidad que VÍA CARGO asume como consecuencia del mismo. El
            cargo de la misma va por cuenta del remitente o destinatario
            dependiendo la modalidad del envío elegido. La empresa no se
            responsabiliza por el estado del contenido, el cual recibe en bulto
            cerrado y no verifica. Tampoco se responsabiliza por roturas y
            extravíos causados por embalajes deficientes. La mercadería en
            descomposición será destruida en el momento de su detección. El
            envío postal pertenecerá al remitente hasta tanto no haya sido
            entregado al destinatario o persona autorizada para recibirlo. Los
            envíos que no puedan ser entregados al destinatario serán sometidos
            al procedimiento establecido por la normativa vigente. Serán
            destruidos los envíos que se detecte contengan productos perecederos
            o de fácil descomposición, así como también los que presenten
            filtraciones o se encuentren en condiciones inconvenientes para su
            almacenamiento. No podrán incluirse en los envíos postales los
            siguiente objetos: objetos inflamables; corrosivos; armas de fuego,
            explosivos; elementos líquidos y/o frágiles; sustancias de carácter
            infeccioso y/o peligroso; material magnético; materiales
            radioactivos; estupefacientes o cualquier otra sustancia y/o
            material cuyo uso o tenencia esté penado legalmente o que se hubiera
            obtenido en violación a las normas vigentes en la República
            Argentina. Siendo que VÍA CARGO desconoce el contenido del envío, el
            mismo resultará de exclusiva responsabilidad de los clientes. VIA
            CARGO asume la responsabilidad por la eventual pérdida, extravío,
            deterioro, total o parcial y demora excesiva e injustificada en la
            entrega de los envíos que admita. Conforme lo previsto en la ley,
            cuando se comprueben estas situaciones, VIA CARGO indemnizará al
            cliente con un monto que alcanzará de máxima el valor declarado por
            el remitente en la guía. La indemnización se abonará al remitente
            cuando: a) El envío no haya sido entregado al destinatario; b) El
            destinatario que hubiere recibido el envío expoliado o averiado,
            efectúe cesión de derechos a favor del remitente. Se abonarán al
            destinatario cuando: a) Al momento de recibir el envío expoliado o
            averiado formule por escrito la consiguiente reclamación y b) El
            remitente ceda sus derechos. No habrá lugar a indemnización en los
            siguientes casos: a) El interesado no haya formulado reclamación,
            dentro del plazo estipulado en la normativa vigente, de un plazo de
            cuatro (4) meses de contratado el servicio; b) Se trate de envíos
            que se hallen en violación a las disposiciones vigentes; c) El hecho
            que origine el perjuicio sea imputable al expedidor o al
            destinatario, o por vicio propio de la cosa o inherente a su
            naturaleza; d) Los envíos se admitan a riesgo del remitente; e) En
            los casos fortuitos o de fuerza mayor; f) El destinatario haya
            otorgado recibo sin formular objeción. Información sobre precios,
            condiciones particulares de los servicios, seguimiento y
            reclamaciones disponible en www.viacargo.com.ar y en los puntos de
            atención de la empresa. La entrega de los envíos de los puntos de
            atención se realizará al destinatario con exhibición de su D.N.I o a
            personas que acredite fehacientemente autorización o representación
            suficiente para hacerlo, sin excepción alguna. Organismo Regulador:
            ENTE NACIONAL DE COMUNICACIONES www.enacom.gov.ar.
            <br />
            <a
              href="https://www.enacom.gob.ar/problemas-con-el-servicio/reclamo_postales_p712"
              target="_blank"
            >
              https://www.enacom.gob.ar/problemas-con-el-servicio/reclamo_postales_p712
            </a>
            <br />
            <a
              href="https://www.argentina.gob.ar/produccion/defensadelconsumidor"
              target="_blank"
            >
              https://www.argentina.gob.ar/produccion/defensadelconsumidor
            </a>{" "}
            <br />
            DDJJ en ENACOM Formularios RNPSP 006 (Ocasionales) disponibles{" "}
            <a href="http://share.viacorreo.com.ar/owncloud/index.php/s/wDWF4C7ofl9lW94">
              AQUÍ
            </a>
            .
          </p>
        </div>
      </div>
      <Sidebar />
    </div>
  </section>
);

export default Terms;
