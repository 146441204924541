import React, { Component } from "react";

import Sidebar from "shared/components/Sidebar/Sidebar";
import Tabbar from "../../../../shared/components/Tabbar/Tabbar";
import Company from "./components/Company";
import News from "./components/News";

import "./styles.css";

class KnowUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [
        {
          value: "us",
          label: "Via Cargo",
          action: () => this._toggleTab("us"),
          active: window.location.pathname.indexOf("us") > -1
        },
        {
          value: "news",
          label: "Novedades",
          action: () => this._toggleTab("news"),
          active: window.location.pathname.indexOf("news") > -1
        }
      ]
    };
  }

  _toggleTab = tab => {
    // Already active
    if (this.state.options.filter(option => option.value === tab)[0]["active"])
      return false;

    let options = [...this.state.options];

    options = options.map(option => {
      option.active = option.value === tab;
      return option;
    });

    this.setState({ options });
  };

  render() {
    let activeTab = this.state.options.find(option => option.active);

    return (
      <section className="screen-contents">
        <div className="background" />
        <div className="details-container">
          <div className="content">
            <div className="content-title">
              <h1>Conocenos</h1>
            </div>
            <Tabbar options={this.state.options} />
            {activeTab["value"] === "us" && <Company />}
            {activeTab["value"] === "news" && <News />}
          </div>
          <Sidebar />
        </div>
      </section>
    );
  }
}

export default KnowUs;
