import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../../assets/logovc.png";

import "./styles.css";

export const Login = ({
  submitForm,
  emailHandler,
  emailError,
  passwordHandler,
  passwordError,
  mensajeValidar,
  mensajeCorreo,
  mensajeError
}) => {
  return (
    <section className="screen-contents contact-screen">
      <div className="details-container bg-login">
        <div className="content">
          <div className="container mt-5">
            <div className="row d-flex justify-content-center">
              <div className="col-md-6">
                {mensajeError && (
                  <div className="bg-green bg-red">Error, {mensajeError}</div>
                )}
                {mensajeValidar && (
                  <div className="bg-white p-3 validar">{mensajeValidar}</div>
                )}
                {mensajeCorreo && (
                  <div className="bg-white p-3 correo">
                    Te enviamos un correo electrónico para que puedas activar tu
                    cuenta.
                    <br />
                    Recordá revisar la carpeta de spam o correo no deseado.
                  </div>
                )}
                <div className="cardd px-5 py-5">
                  <div className="form-data">
                    <div className="logo">
                      <img src={logo} alt="Logo" />
                    </div>
                    <div className="forms-inputs mb-4">
                      <div className="input-group w-100">
                        <span className="input-group-text" id="basic-addon1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-person-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          aria-label="Input group example"
                          aria-describedby="basic-addon1"
                          onChange={emailHandler}
                        />
                        <div className="validacion-error">
                          <p>{emailError}</p>
                        </div>
                      </div>
                    </div>

                    <div className="forms-inputs mb-4">
                      <div className="input-group w-100">
                        <span className="input-group-text" id="basic-addon1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-file-lock2-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M7 6a1 1 0 0 1 2 0v1H7V6z" />
                            <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 6v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0z" />
                          </svg>
                        </span>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Contraseña"
                          aria-label="Input group example"
                          aria-describedby="basic-addon1"
                          onChange={passwordHandler}
                        />
                        <div className="validacion-error">
                          <p>{passwordError}</p>
                        </div>
                      </div>
                    </div>
                    <div className="password">
                      <Link to="/password">¿Ha olvidado su contraseña?</Link>
                    </div>
                    <div className="mb-3">
                      <input
                        type="submit"
                        value="Conectar"
                        className="button"
                        onClick={submitForm}
                      />

                      <Link to="/userRegister">
                        <input
                          type="button"
                          value="¿Nuevo usuario? Registrate"
                          className="button"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
