import React, { Component, useState } from "react";
import Modal from "simple-react-modal";
import axios from "axios";
import loading from "../../../../../assets/loading.gif";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
// import pin from "../../../../../assets/icons/pin_small.png";
// import pinStop from "../../../../../assets/icons/pin_small_stop.png";
import pin from "../../../../../assets/icons/pin_pickup.png";
import pinStop from "../../../../../assets/icons/pin_bandera.png";
import icon from "../../../../../assets/my_location_marker.png";
import Button1 from "../../../../../shared/components/Button1";
import icoPickupMovil from "assets/icons/pickup_movil1.png";
import newicoPickupMovil from "assets/icons/new-pickup-movil.png";

class MapSeguimiento extends Component {
  constructor(props) {
    super(props);

    console.log("props.centro1: " + JSON.stringify(props.centro));
    if (props.centro.lat == "") {
      props.centro.lat = "-34.49886000";
    }

    if (props.centro.lng == "") {
      props.centro.lng = "-58.71260000";
    }
    console.log("props.centro2: " + JSON.stringify(props.centro));

    this.state = {
      currentMarker: null,
      //center: { lat: -34.6124019, lng: -58.4993903 },

      center: props.centro,

      user: null,
      zoom: 18,
      token: sessionStorage.getItem("token"),
      positionMap: [
        {
          IdDiaOperativoMovil: 3,
          Descripcion: "",
          HoraAperturaManana: "",
          HoraCierreManana: "",
          HoraAperturaTarde: "",
          HoraCierreTarde: "",
          JornadaContinua: 1,
          Dominio: "",
          Posicionamiento: {
            LATITUD: "",
            LONGITUD: ""
          }
        }
      ],
      paradas: props.stops,
      showModal: false,
      modalMessage: "",
      serviceActive: true,
      launchService: true,
      dataPosicionamiento: {},
      casacentral: {
        lat: "-34.49987725",
        lng: "-58.71254989"
      }
    };

    if (this.state.launchService) {
      this._getPosicionamiento(this.state.token);
      this.setState({
        launchService: false
      });
    }
  }

  // useEffect() {
  //   // console.log("Props Stops: " + JSON.stringify(props.stops));
  //   setInterval(() => {
  //     this._getPosicionamiento(this.state.token);
  //   }, 20000);

  // }

  componentDidMount() {
    setInterval(() => {
      this._getPosicionamiento(this.state.token);
    }, 20000);
    //this._getPosicionamiento(this.state.token);
  }

  _getPosicionamiento = token => {
    let posicionamiento =
      process.env.REACT_APP_API_URL + `/api/posicionamiento`;

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: posicionamiento,

      headers: {
        "X-API-KEY": token
      }
    };

    let msg = (
      <div className="textoModal">
        <p>
          El servicio de pickup móvil no se encuentra en su horario de
          recolección.
        </p>
        <p>Verifica nuestros horarios en la página principal.</p>
      </div>
    );

    axios(config)
      .then(response => {
        // console.log("getPosicionamiento: " + JSON.stringify(response.data));

        if (
          this.state.center.lat !== response.data.Posicionamiento.LATITUD ||
          this.state.center.lng !== response.data.Posicionamiento.LONGITUD
        ) {
          let positionlonlatserv = {
            lat: response.data.Posicionamiento.LATITUD,
            lng: response.data.Posicionamiento.LONGITUD
          };

          this.setState({
            center: positionlonlatserv,
            dataPosicionamiento: response.data,
            serviceActive: response.data.JornadaContinua == 1,
            showModal: response.data.JornadaContinua != 1,
            modalMessage: msg
          });
        }

        if (this.state.positionMap !== response.data) {
          this.setState({
            positionMap: [response.data]
          });
        }
      })
      .catch(error => {
        this.setState({
          serviceActive: false,
          showModal: true,
          modalMessage: msg
        });
        console.log(error);
      });
  };

  // _get_horario_disponible(
  //   aperturaManana,
  //   cierreManana,
  //   aperturaTarde,
  //   cierreTarde,
  //   jornada
  // ) {
  //   const fechaGenerica = "1/1/1999";
  //   const horaActual = new Intl.DateTimeFormat(undefined, {
  //     timeStyle: "short",
  //   }).format(new Date());
  //   const horaCompara = new Date(fechaGenerica + horaActual);
  //   const horaComparaMananaA = new Date(fechaGenerica + aperturaManana);
  //   const horaComparaMananaC = new Date(fechaGenerica + cierreManana);
  //   const horaComparaTardeA = new Date(fechaGenerica + aperturaTarde);
  //   const horaComparaTardeC = new Date(fechaGenerica + cierreTarde);
  //   let disponibilidad = false;
  //   //vemos si es horario corrido
  //   if (jornada) {
  //     if (horaComparaMananaA < horaCompara && horaCompara < horaComparaTardeC) {
  //       disponibilidad = true;
  //     } else if (
  //       (horaComparaMananaA < horaCompara &&
  //         horaCompara < horaComparaMananaC) ||
  //       (horaComparaTardeA < horaCompara && horaCompara < horaComparaTardeC)
  //     ) {
  //       disponibilidad = true;
  //     }
  //   }
  //   return disponibilidad;
  // }

  render() {
    console.log("Render...");
    console.log("center render " + JSON.stringify(this.state.center));
    // this._getPosicionamiento();
    // Paradas
    let markerStop = Leaflet.icon({
      iconUrl: pinStop,
      popupAnchor: [0, -18],
      iconSize: [36, 36],
      iconAnchor: [18, 18]
    });
    // Camioneta
    let marker = Leaflet.icon({
      iconUrl: pin,
      popupAnchor: [0, -18],
      iconSize: [36, 36],
      iconAnchor: [18, 18]
    });

    //this._getPosicionamiento(this.state.token);
    const posicionamientoRender = this.state.positionMap;
    let servicioActivo = this.state.serviceActive;
    const paradasvec = this.state.paradas;
    // console.log('Paradas');
    // console.log(paradasvec);

    let descripcion = "";
    let latitud = "";
    let longitud = "";
    let horaAperturaManana = "";
    let horaCierreManana = "";
    let horaAperturaTarde = "";
    let horaCierreTarde = "";
    let jornadaContinua = 0;
    let positionlonlat = {
      lat: "",
      lng: ""
    };
    let leyenda = "";

    // let disponible = this._get_horario_disponible(
    //   horaAperturaManana,
    //   horaCierreManana,
    //   horaAperturaTarde,
    //   horaCierreTarde,
    //   jornadaContinua
    // );

    if (servicioActivo) {
      posicionamientoRender !== null &&
        posicionamientoRender.map(positions => {
          //let posicionamiento = positions.Posicionamiento;
          if (positions.Posicionamiento != null) {
            descripcion = positions.Dominio;
            latitud = positions.Posicionamiento.LATITUD;
            longitud = positions.Posicionamiento.LONGITUD;
            horaAperturaManana = positions.HoraAperturaManana;
            horaCierreManana = positions.HoraCierreManana;
            horaAperturaTarde = positions.HoraAperturaTarde;
            horaCierreTarde = positions.HoraCierreTarde;
            jornadaContinua = positions.JornadaContinua;
            if (jornadaContinua) {
              leyenda = "De " + horaAperturaManana + " a " + horaCierreTarde;
            } else {
              leyenda =
                "De " +
                horaAperturaManana +
                " a " +
                horaCierreManana +
                " y de " +
                horaAperturaTarde +
                " a " +
                horaCierreTarde;
            }

            positionlonlat = {
              lat: latitud,
              lng: longitud
            };
            console.log(
              "positionlatlon posicionamiento render map " +
                JSON.stringify(positionlonlat)
            );
          }
        });
      return (
        <div>
          <Map
            center={
              this.state.center.lat != ""
                ? this.state.center
                : this.state.casacentral
            }
            style={{ height: 400, zIndex: 1 }}
            zoom={this.state.zoom}
          >
            <TileLayer
              url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
              maxZoom={16}
              minZoom={5}
            />
            <MarkerClusterGroup className="markercluster-map">
              {/* Camioneta */}
              {}
              <Marker position={positionlonlat} icon={marker}>
                <Popup>
                  <div className="info-box">
                    <h5>Servicio de Pickup</h5>
                    {/* <h5>Horarios</h5>
                    <p>{leyenda}</p> */}
                  </div>
                </Popup>
              </Marker>
              {/* Paradas */}
              {paradasvec !== null &&
                paradasvec.map(paradas => {
                  let paradaDescripcion = paradas.Descripcion;
                  let paradaDireccion = paradas.Direccion;
                  let paradaLatitud = paradas.Latitud;
                  let paradaLongitud = paradas.Longitud;
                  let paradaPosition = {
                    lat: paradaLatitud,
                    lng: paradaLongitud
                  };
                  return (
                    <Marker position={paradaPosition} icon={markerStop}>
                      <Popup>
                        <div className="info-box">
                          <h3>{paradaDescripcion}</h3>
                          <p>{paradaDireccion}</p>
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}
            </MarkerClusterGroup>
          </Map>
        </div>
      );
    } else {
      // this.setState({
      //   showModal: true,
      //   modalMessage: msg,
      // });

      //lanzar modal no hay moviles disponibles
      return (
        <Modal
          show={this.state.showModal}
          // onClose={() => this.setState({ showModal: false })}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          {/* <div className={"close-button"}>
            <i
              className={"material-icons"}
              onClick={() => {
                // this.setState({ showModal: false });
                window.close();
              }}
            >
              close
            </i>
          </div> */}
          <div className={"modal-body"}>
            <div>
              <img
                src={newicoPickupMovil}
                alt="Pickup Movil"
                className="logomodal"
              />
            </div>
            {this.state.modalMessage}
            <div>
              <Button1
                className="quote"
                //onClick={() => this.setState({ showModal: false })}
                onClick={() => window.close()}
              >
                Cerrar
              </Button1>
            </div>
          </div>
        </Modal>
      );
    }
  }
}

export default MapSeguimiento;
