import React, { Component, useState, render, useRef } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup,
  Modal
} from "react-bootstrap";
import Reaptcha from "reaptcha";

import "./../styles.css";

function DatosDestinatario(props) {
  const [validated, setValidated] = useState(false);
  const [tipoServicio, setTipoServicio] = useState(0);
  const [active, setActive] = useState("");
  const [accept, setAccept] = useState(false);
  const [accept_ilegal_obj, setAcceptIlegalObj] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(false);
  const [boton, setBoton] = useState(false);

  const captchaRef = useRef(null);
  let servicios = {};
  if (props.tiposServicios) {
    servicios = props.tiposServicios;
  }

  const siguiente = () => {
    props.sextoForm();
    setBoton(!boton); //Tarea GDM-1031
    //props.retiros("")
  };

  // Modal Exito
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.href = "/";
  };
  const handleShow = () => setShow(true);

  const atrasForm4 = () => {
    props.atrasForm4();
  };

  const aceptar = valor => {
    //setAccept(valor)
    //setBoton(!boton)
    //props.atrasForm4(valor)
  };

  return (
    <div>
      <div className="row">
        {/* <div className="container">
          <div className="text-left">
            <p>Por favor, revisá cuidadosamente los datos ingresados</p>
          </div>        
        </div> */}

        {/* <div className="col-md-3 col-lg-5">
          <img
            src="/images/box.png"
            alt="Via Cargo Mi retiro en domicilio Online"
            width="100"
            className="img-fluid"
          />
        </div> */}
        <div style={{ height: 15 }} />
        <div className="col-md-3 col-lg-12">
          <div className="text-left">
            <p>Por favor, revisá cuidadosamente los datos ingresados</p>
            <p>
              <strong>Descripción del envío</strong>
            </p>
          </div>
          <div className="text-left">
            <p>{props.DescripcionServicio}</p>
            <p>Cantidad de bultos: {props.NumeroBultos}</p>
            <p>
              {props.Alto} cm x {props.Ancho} cm x {props.Largo} cm
            </p>
            {/* <h4>
              <b>{props.TipoEntrega}</b>
            </h4> */}
          </div>
          <div className="text-left">
            <p>
              <strong>Tipo de servicio</strong>
            </p>
            <p>{props.TipoEntrega}</p>
          </div>
        </div>
        <div className="col-md-3 col-lg-6">
          <hr className="my-4" />
          <div className="text-left">
            <p>
              <strong>Remitente</strong>
            </p>
          </div>
          {props.codigoProducto != "pm" && (
            <div className="text-left">
              <h5>{props.datosRemitente.NombreRemitente}</h5>
              <p>
                <b>Direccion</b>: {props.datosRemitente.DireccionRemitente}{" "}
                <b>Departamento</b>: {props.datosRemitente.Piso}
              </p>
              <p>
                <b>Codigo postal:</b> {props.datosRemitente.CodigoPostal}
              </p>
              <p>
                <b>DNI:</b> {props.datosRemitente.NroDocRemitente}/{" "}
                <b>Email:</b> {props.datosRemitente.EmailRemitente}/ <b>Cel:</b>{" "}
                {props.datosRemitente.TelefonoRemitente}
              </p>
            </div>
          )}
          {props.codigoProducto == "pm" && (
            <div className="text-left">
              <h5>{props.datosRemitente.NombreRemitente}</h5>
            </div>
          )}
          <hr className="my-4" />
        </div>
        <div className="col-md-3 col-lg-6">
          <hr className="my-4" />
          <div className="text-left">
            <p>
              <strong>Destinatario</strong>
            </p>
          </div>
          <div className="text-left">
            <h5>{props.datosDestinatario.NombreDestinatario}</h5>
            <p>
              <b>Direccion</b>: {props.datosDestinatario.DireccionDestinatario}{" "}
              <b>Departamento</b>: {props.datosDestinatario.Piso}
            </p>
            <p>
              <b>Codigo postal:</b> {props.datosDestinatario.CodigoPostal}
            </p>
            <p>
              <b>DNI:</b> {props.datosDestinatario.NroDocDestinatario}/{" "}
              <b>Email:</b> {props.datosDestinatario.EmailDestinatario}/{" "}
              <b>Cel:</b> {props.datosDestinatario.TelefonoDestinatario}
            </p>
          </div>
          <hr className="my-4" />
        </div>
        <div className="col-md-3 col-lg-12">
          <div className="text-left">
            <h5>Total</h5>
            <h3>${props.TotalServicio}</h3>
            <p>
              Los valores de cotización son informativos y están sujetos a la
              veracidad de la información cargada en este formulario. La
              cotización final se realizará al momento del retiro.
            </p>
            <p>
              En todos los casos el acondicionamiento del{" "}
              <a href="/tips">embalaje de las piezas</a> es responsabilidad del
              remitente
            </p>
          </div>
        </div>

        <div className="form-group form-group col-md-3 col-lg-12">
          <input
            className="form-check-inline"
            name="accept"
            type="checkbox"
            id="accept"
            defaultValue={accept}
            onClick={() => {
              setAccept(!accept);
              setBoton(!boton && accept_ilegal_obj && captchaToken);
            }}
          />
          <label htmlFor="accept" className="form-check-label">
            El contenido del envío que realizo está bajo mi responsabilidad.*
          </label>

          <br />
          <br />

          <div style={{ marginLeft: "15px" }}>
            <Reaptcha
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={captchaRef}
              onVerify={() => {
                setCaptchaToken(!captchaToken);
                setBoton(!boton && accept && accept_ilegal_obj);
              }}
              onExpire={() => {
                setCaptchaToken(!captchaToken);
                setBoton(false);
              }}
            />
          </div>
          <br />
          <br />

          <input
            className="form-check-inline"
            name="accept_ilegal_obj"
            type="checkbox"
            id="accept_ilegal_obj"
            defaultValue={accept_ilegal_obj}
            onClick={() => {
              setAcceptIlegalObj(!accept_ilegal_obj);
              setBoton(!boton && accept && captchaToken);
            }}
            e
          />
          <label htmlFor="accept_ilegal_obj" className="form-check-label">
            Acepto no enviar Elementos prohibidos y embalar de forma adecuada
            los productos, siendo responsable de cualquier daño y/o rotura.
          </label>

          <br />
          <br />
          <Button
            variant="secondary"
            onClick={atrasForm4}
            style={{ maxHeight: 38 }}
          >
            Anterior
          </Button>
          <br />
          {!boton && (
            <Button
              variant="primary"
              onClick={siguiente}
              disabled
              style={{ maxHeight: 38 }}
            >
              Siguiente
            </Button>
          )}
          {boton && (
            <Button
              variant="primary"
              onClick={handleShow}
              style={{ maxHeight: 38 }}
            >
              Siguiente
            </Button>
          )}

          {/* MODAL DEL EXITO */}
          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: "20px" }}>
              Tu pedido fue registrado con éxito, te enviaremos un e-mail con
              los datos y la etiqueta que deberás pegar en los bultos.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}
export default DatosDestinatario;

{
  /* <div className="col-md-3 col-lg-5">
                    <img src="/images/box.png" alt="Via Cargo Mi retiro en domicilio Online" width="100" className="img-fluid"/>
                </div>
                <div style={{height:15}}/>
                <div className="col-md-3 col-lg-12">
                    <div className="text-left">
                        <p><strong>Descripción del envío</strong></p>
                    </div>
                    <div className="text-left">
                        <h5>{props.DescripcionServicio}</h5>
                        <p>{props.Alto} cm x {props.Ancho} cm x {props.Largo} cm</p>
                        <h4><b>{props.TipoEntrega}</b></h4>
                    </div>
                </div>
                <div className="col-md-3 col-lg-6">
                    <hr className="my-4"/>
                    <div className="text-left">
                        <p><strong>Remitente</strong></p>
                    </div>
                    
                    <div className="text-left">
                        <h5>{props.datosRemitente.NombreRemitente}</h5>
                        <p><b>Direccion</b>: {props.datosRemitente.DireccionRemitente} <b>Departamento</b>: {props.datosRemitente.Piso}</p>
                        <p><b>Codigo postal:</b> {props.datosRemitente.CodigoPostal}</p>
                        <p><b>DNI:</b> {props.datosRemitente.NroDocRemitente}
                        / <b>Email:</b> {props.datosRemitente.EmailRemitente}
                        / <b>Cel:</b> {props.datosRemitente.TelefonoRemitente}
                        </p>
                    </div>
                </div>
                <div className="col-md-3 col-lg-6">
                    <hr className="my-4"/>
                    <div className="text-left">
                        <p><strong>Destinatario</strong></p>
                    </div>
                    <div className="text-left">
                        <h5>{props.datosDestinatario.NombreDestinatario}</h5>
                        <p><b>Direccion</b>: {props.datosDestinatario.DireccionDestinatario} <b>Departamento</b>: {props.datosDestinatario.Piso}</p>
                        <p><b>Codigo postal:</b> {props.datosDestinatario.CodigoPostal}</p>
                        <p><b>DNI:</b> {props.datosDestinatario.NroDocDestinatario}
                        / <b>Email:</b> {props.datosDestinatario.EmailDestinatario} 
                        / <b>Cel:</b> {props.datosDestinatario.TelefonoDestinatario}
                        </p>
                    </div>
                    <hr className="my-4"/>
                </div>
                <div className="col-md-3 col-lg-12">
                    <div className="text-left">
                        <!-- <h5>Total</h5>
                        <h3>${props.TotalServicio}</h3>

                        <p>
                        *Este cargo es el costo del retiro. El valor del envío se abonará con el
                        retiro o en destino.
                        </p>
                        <p>* Toda operación tiene un costo mínimo y fijo de $ <b><i>{servicios.CostoFijo}</i></b>, en concepto de flete por el retiro a domicilio de encomienda y gastos de servicio.
                        </p> --!>}
                    </div>
                </div> */
}
