import React from "react";
import { Link } from "react-router-dom";

import Button from "shared/components/Button";

import "./styles.css";

const Access = () => (
  <section className="screen-contents-access">
    <div className="access-contents">
      <h1 className="access-title">Bienvenidos</h1>
      <div className="access-body">
        <div className="access-item">
          <h2 className="access-body-title">Agencias</h2>
          <Button
            secondary
            onClick={() =>
              (window.location = "https://stsviacargo.alertran.net/tpv")
            }
          >
            Ingresar
          </Button>
        </div>

        <div className="access-item">
          <h2 className="access-body-title">Centro de distribución</h2>
          <Button
            secondary
            onClick={() =>
              (window.location = "https://viacargo.alertran.net/ViaCargo")
            }
          >
            Ingresar
          </Button>
        </div>

        <div className="access-item">
          <h2 className="access-body-title">Clientes frecuentes</h2>
          <Button
            secondary
            onClick={() =>
              (window.location = " https://gtsviacargo.alertran.net/gts")
            }
          >
            Ingresar
          </Button>
          </div>

        {/* <div className="access-item">
          <h2 className="access-body-title">Mi Via Cargo</h2>
          <Button secondary onClick={() => (window.location = "/Login")}>
            Ingresar
          </Button>
        </div> */}
      </div>
    </div>
  </section>
);

export default Access;
