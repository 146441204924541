import React, { Component, useState, render, useEffect } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup
} from "react-bootstrap";
import {
  isValidEmail,
  returnOnlyNumbers1,
  returnOnlyNumbers,
  isPositiveNumber
} from "../../../../../utils/index";
import axios from "axios";
import Select from "react-select";

import alto_ico from "../../../../../../src/assets/product/formats/alto.png";
import ancho_ico from "../../../../../../src/assets/product/formats/ancho.png";
import bulto_ico from "../../../../../../src/assets/product/formats/bulto.png";
import peso_ico from "../../../../../../src/assets/product/formats/peso.png";
import profundidad_ico from "../../../../../../src/assets/product/formats/profundidad.png";
import valor_declarado_ico from "../../../../../../src/assets/product/formats/valor-declarado.png";

function Formulario(props) {
  const [validated, setValidated] = useState(false);
  const [CodigoPostalRemitente, setCodigoPostalRemitente] = useState("none");
  const [CodigoPostalDestinatario, setCodigoPostalDestinatario] = useState(
    "none"
  );
  const [NumeroBultos, setNumeroBultos] = useState("none");
  const [Kilos, setKilos] = useState("none");
  const [Alto, setAlto] = useState("none");
  const [Ancho, setAncho] = useState("none");
  const [Largo, setLargo] = useState("none");
  const [ImporteValorDeclarado, setImporteValorDeclarado] = useState("none");
  const [pago, setpago] = useState("");
  const [pagoError, setpagoError] = useState("none");
  const [importe, setImporte] = useState("");
  const [importesRango, setImportesRango] = useState("");
  const [codRem, setCodRem] = useState("");
  const [codDest, setCodDest] = useState("");
  const [codPostal, setCodPostal] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  /*Tarea GDM-1031 - Inicio */
  const [selectedOp1, setSelectedOp1] = useState("");
  const [selectedOp2, setSelectedOp2] = useState("");
  useEffect(() => {
    limpiar();
  }, [props.limpio]);
  /*Tarea GDM-1031 - Fin */
  useEffect(() => {
    setToken(sessionStorage.getItem("token"));
  }, []);

  useEffect(() => {
    //const token = sessionStorage.getItem("token");
    var getUrl = window.location;
    var baseUrl = getUrl.host;
    var protocol = getUrl.protocol;
    var pathArray = baseUrl.split(":");

    let importe_declarado =
      process.env.REACT_APP_API_URL + `/api/importe_declarado`;
    //Descomentar cuando se quiera subir a preminens/Test
    //let haveHost = pathArray[1] != "" ? ":" + pathArray[1] : ""; //Solo local porque sino no funciona el acordeon
    //let importe_declarado = protocol + "//" + pathArray[0] + haveHost + `/api/importe_declarado`; //Solo local porque sino no funciona el acordeon

    if (token) {
      axios
        .get(importe_declarado, { params: { token: token } })
        .then(response => {
          let { data } = response;

          setImportesRango(data);
        })
        .catch(e => {
          /*this.setState({
              //infoPago: e.response.data.message,
              xhr: false,
              ////showModal: true,
              errorMessage: "server-error: "+e.response.data.message
            });    */

          console.log(e);
        });

      let url_cod_postal = process.env.REACT_APP_API_URL + "/api/codigo_postal";
      axios
        .get(url_cod_postal, "")
        .then(response => {
          let { data } = response;

          let datos = [];
          if (data.length > 0) {
            data.map(cod =>
              datos.push({
                value: cod.CodigoPostal,
                label: cod.Descripcion + " - " + cod.Provincia
              })
            );
            datos.sort((a, b) => a.Descripcion - b.Descripcion);
            setCodPostal(datos);
          }
        })
        .catch(e => {
          console.log(e);
          /*this.setState({
          //infoPago: e.response.data.message,
          xhr: false,
          showModal: true,
          errorMessage: "server-error: " + e.response.data.message
        });*/
        });
    } else {
      setToken(props.checkToken());
    }
  }, [token]);

  const options = codPostal;
  const optionsServicios = [
    {
      value: "ea",
      label: "Entrega en Agencia"
    },
    {
      value: "pm",
      label: "Pickup Móvil"
    }
  ];

  const [campos, setCampos] = useState({
    CodigoPostalRemitente: "none",
    CodigoPostalDestinatario: "none",
    NumeroBultos: "none",
    Kilos: "none",
    Alto: "none",
    Ancho: "none",
    Largo: "none",
    ImporteValorDeclarado: "none",
    pago: "none",
    IdClienteRemitente: "99999999",
    IdCentroRemitente: "99",
    PaisRemitente: "054",
    CodigoPostalRemitente: "1611",
    PaisDestinatario: "054",
    CodigoPostalDestinatario: "4400",
    TipoMoneda: "ARS"
  });

  const [values, setValues] = useState({
    CodigoPostalRemitente: 0,
    CodigoPostalDestinatario: 0,
    NumeroBultos: 0,
    Kilos: 0,
    Alto: 0,
    Ancho: 0,
    Largo: 0,
    ImporteValorDeclarado: 0,
    pago: 0,
    IdClienteRemitente: "99999999",
    IdCentroRemitente: "99",
    PaisRemitente: "054",
    CodigoPostalRemitente: "1611",
    PaisDestinatario: "054",
    CodigoPostalDestinatario: "4400",
    TipoMoneda: "ARS"
  });
  const [boton, setBoton] = useState(true);
  const nombreCampos = [
    "CodigoPostalRemitente",
    "CodigoPostalDestinatario",
    "NumeroBultos",
    "Kilos",
    "Alto",
    "Ancho",
    "Largo",
    "ImporteValorDeclarado",
    "pago"
  ];

  var variables = [];
  let siguiente = false;
  let cRem = "";
  let cDest = "";

  const handleSubmit = event => {
    const form =
      event.currentTarget.parentNode[0] !== undefined
        ? event.currentTarget.parentNode
        : event.currentTarget.parentNode.parentNode;
    var nuevosCampos = campos;

    if (event.currentTarget.parentNode[0] !== undefined) {
      //setCodigoPostalRemitente(validarEntero(form[0].value))
      //setCodigoPostalDestinatario(validarEntero(form[1].value))
      setNumeroBultos(validarEntero(form[4].value));
      //console.log(validarKg(form[5].value));
      setKilos(validarKg(form[5].value));
      setAlto(validarEntero(form[6].value));
      setAncho(validarEntero(form[7].value));
      setLargo(validarEntero(form[8].value));
      setImporteValorDeclarado(validarEntero(form[9].value));

      var nuevosCampos = {
        CodigoPostalRemitente: codRem, //validarEntero(form[0].value),
        CodigoPostalDestinatario: codDest, //validarEntero(form[1].value),
        NumeroBultos: validarEntero(form[4].value),
        Kilos: validarKg(form[5].value),
        Alto: validarEntero(form[6].value),
        Ancho: validarEntero(form[7].value),
        Largo: validarEntero(form[8].value),
        ImporteValorDeclarado: validarDecimal(form[9].value),
        Pago: pago,
        IdClienteRemitente: "99999999",
        IdCentroRemitente: "99",
        PaisRemitente: "054",
        CodigoPostalRemitente: "1611",
        PaisDestinatario: "054",
        CodigoPostalDestinatario: "4400",
        TipoMoneda: "ARS"
      };
      setCampos(nuevosCampos);
      //console.log(form.checkValidity());
      if (
        form.checkValidity() === false ||
        form[5].value < 0 ||
        form[5].value > 50 ||
        form[6].value < 5 ||
        form[7].value < 5 ||
        form[8].value < 5 ||
        parseInt(form[9]) > importesRango.Maximo ||
        parseInt(form[9]) < importesRango.Minimo
      ) {
        setBoton(true);
        event.preventDefault();
        event.stopPropagation();
      } else {
        setBoton(false);

        setValues({
          CodigoPostalRemitente: codRem,
          CodigoPostalDestinatario: codDest,
          NumeroBultos: form[4].value,
          Kilos: form[5].value,
          Alto: form[6].value,
          Ancho: form[7].value,
          Largo: form[8].value,
          ImporteValorDeclarado: form[9].value,
          Pago: pago,
          IdClienteRemitente: "99999999",
          IdCentroRemitente: "99",
          PaisRemitente: "054",
          //CodigoPostalRemitente: '1611',
          PaisDestinatario: "054",
          //CodigoPostalDestinatario: '4400',
          TipoMoneda: "ARS"
        });
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      let errorEncontrado = false;
      const form =
        event.currentTarget.parentNode.parentNode[0] !== undefined
          ? event.currentTarget.parentNode.parentNode
          : event.currentTarget.parentNode.parentNode.parentNode;

      let validar = returnOnlyNumbers1(event.currentTarget.value);
      validar = validar.replace(/,/g, "");
      event.currentTarget.value = validar;

      if (event.currentTarget.name == "ImporteValorDeclarado") {
        siguiente = true;
        if (
          validarEntero(event.currentTarget.value) == "none" &&
          (parseInt(event.currentTarget.value) > importesRango.Maximo ||
            parseInt(event.currentTarget.value) < importesRango.Minimo)
        ) {
          setImporte(
            "Valor debe estar entre " +
              importesRango.Minimo +
              " y " +
              importesRango.Maximo +
              " pesos"
          );
          setBoton(true);
          siguiente = false;
          errorEncontrado = true;
        }
      } else {
        if (
          event.currentTarget.name == "Alto" ||
          event.currentTarget.name == "Ancho" ||
          event.currentTarget.name == "Largo"
        ) {
          //alert(event.currentTarget.value)

          siguiente = true;
          if (event.currentTarget.value < 5) {
            setBoton(true);

            siguiente = false;
            setCampos({
              ...campos,
              [event.currentTarget.name]: validarEntero("incorrecto")
            });
          }
        }

        if (event.currentTarget.name == "Kilos") {
          //alert(event.currentTarget.value)

          siguiente = true;
          if (event.currentTarget.value < 0 || event.currentTarget.value > 50) {
            setBoton(true);

            siguiente = false;
            setCampos({
              ...campos,
              [event.currentTarget.name]: validarEntero("incorrecto")
            });
          }
        }
      }

      if (siguiente == true) {
        if (validarEntero(event.currentTarget.value) != "block") {
          setImporte("");
          setBoton(false);

          setCampos({
            ...campos,
            [event.currentTarget.name]: validarEntero(event.currentTarget.value)
          });
        }
      }

      if (
        form.checkValidity() === false ||
        form[5].value < 0 ||
        form[5].value > 50 ||
        form[6].value < 5 ||
        form[7].value < 5 ||
        form[8].value < 5 ||
        parseInt(form[9].value) > importesRango.Maximo ||
        parseInt(form[9].value) < importesRango.Minimo
      ) {
        setBoton(true);
        siguiente = false;
        event.preventDefault();
        event.stopPropagation();
      } else {
        // por si ingresan los valores de forma desordenada
        setBoton(false);
        siguiente = true;

        if (event.currentTarget.name == "ImporteValorDeclarado") {
          if (
            validarEntero(event.currentTarget.value) == "none" &&
            (parseInt(event.currentTarget.value) > importesRango.Maximo ||
              parseInt(event.currentTarget.value) < importesRango.Minimo)
          ) {
            setImporte(
              "Valor debe estar entre " +
                importesRango.Minimo +
                " y " +
                importesRango.Maximo +
                " pesos"
            );
            setBoton(true);
            siguiente = false;
          } else {
            setBoton(false);
          }
        }

        if (pago == "") {
          setBoton(true);
        } else {
          setBoton(false);
        }

        setValues({
          CodigoPostalRemitente: codRem,
          CodigoPostalDestinatario: codDest,
          NumeroBultos: form[4].value,
          Kilos: form[5].value,
          Alto: form[6].value,
          Ancho: form[7].value,
          Largo: form[8].value,
          ImporteValorDeclarado: form[9].value,
          Pago: pago,
          IdClienteRemitente: "99999999",
          IdCentroRemitente: "99",
          PaisRemitente: "054",
          //CodigoPostalRemitente: '1611',
          PaisDestinatario: "054",
          //CodigoPostalDestinatario: '4400',
          TipoMoneda: "ARS"
        });

        event.preventDefault();
        event.stopPropagation();
      }

      event.preventDefault();
      event.stopPropagation();
    }
  };

  const validar = event => {
    const form = event.currentTarget.parentNode;

    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setBoton(false);
    }
  };

  const validarCampoEntero = e => {
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      setBoton(true);
      e.preventDefault();
      e.stopPropagation();
    } else {
      setBoton(false);
    }

    setCampos({
      ...campos,
      [e.target.name]: validarEntero(e.target.value)
    });
  };

  const validarCampoDecimal = e => {
    const form = e.currentTarget.parentNode;

    if (!form.checkValidity()) {
      setBoton(true);
      e.preventDefault();
      e.stopPropagation();
    } else {
      setBoton(false);
    }

    setCampos({
      ...campos,
      [e.target.name]: validarDecimal(e.target.value)
    });
  };

  const validarEntero = valor => {
    //intento convertir a entero.
    //si era un entero no le afecta, si no lo era lo intenta convertir
    valor = parseInt(valor);

    //Compruebo si es un valor numérico
    if (isNaN(valor) || valor < 0) {
      //entonces (no es numero) devuelvo el valor cadena vacia

      return "block";
    } else {
      //En caso contrario (Si era un número) devuelvo el valor
      return "none";
    }
  };
  const handleBlockedButtom = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const handleSubmitRadio = event => {
    const form =
      event.currentTarget.parentNode[0] !== undefined
        ? event.currentTarget.parentNode
        : event.currentTarget.parentNode.parentNode;

    setpago(event.currentTarget.value);
    if (
      form[5].value < 0 ||
      form[5].value > 50 ||
      form[6].value < 5 ||
      form[7].value < 5 ||
      form[8].value < 5 ||
      parseInt(form[9]) > importesRango.Maximo ||
      parseInt(form[9].value) < importesRango.Minimo ||
      codDest == null ||
      codRem == null
    ) {
      setBoton(true);
      siguiente = false;
    } else {
      if (form.checkValidity() === true) {
        setBoton(false);
        siguiente = true;
        values.Pago = event.currentTarget.value;
      }
    }
  };

  const handleChangeRem = selectedOption => {
    setSelectedOp1(selectedOption.value); //Tarea GDM1031
    if (selectedOption.value != null) {
      setCodRem(selectedOption.value);
      setValues({
        CodigoPostalRemitente: selectedOption.value,
        CodigoPostalDestinatario: values.CodigoPostalDestinatario,
        NumeroBultos: values.NumeroBultos,
        Kilos: values.Kilos,
        Alto: values.Alto,
        Ancho: values.Ancho,
        Largo: values.Largo,
        ImporteValorDeclarado: values.ImporteValorDeclarado,
        Pago: pago,
        IdClienteRemitente: "99999999",
        IdCentroRemitente: "99",
        PaisRemitente: "054",
        PaisDestinatario: "054",
        TipoMoneda: "ARS"
      });

      setCodigoPostalRemitente(validarEntero(selectedOption.value));
    }
  };

  const handleChangeDest = selectedOption => {
    setSelectedOp2(selectedOption.value); //Tarea GDM1031
    if (selectedOption.value != null) {
      setCodDest(selectedOption.value);
      setValues({
        CodigoPostalRemitente: values.CodigoPostalRemitente,
        CodigoPostalDestinatario: selectedOption.value,
        NumeroBultos: values.NumeroBultos,
        Kilos: values.Kilos,
        Alto: values.Alto,
        Ancho: values.Ancho,
        Largo: values.Largo,
        ImporteValorDeclarado: values.ImporteValorDeclarado,
        Pago: pago,
        IdClienteRemitente: "99999999",
        IdCentroRemitente: "99",
        PaisRemitente: "054",
        PaisDestinatario: "054",
        TipoMoneda: "ARS"
      });
      setCodigoPostalDestinatario(validarEntero(selectedOption.value));
    }
  };

  const validarDecimal = valor => {
    valor = parseFloat(valor);

    if (isNaN(valor) || valor < 0) {
      return "block";
    } else {
      return "none";
    }
  };

  const validarKg = valor => {
    valor = parseFloat(valor);

    if (isNaN(valor) || valor < 0 || valor > 50) {
      return "block";
    } else {
      return "none";
    }
  };
  const nextStepHandler = () => {
    //const selectedService = services.find(s => s.selected);

    if (pago == "") {
      setpagoError("block");
      setBoton(true);
      return false;
    } else {
      setpagoError("none");
      props.tiposServicios(values);
    }
  };
  /* Tarea GDM1031 - Inicio*/
  const limpiar = () => {
    console.log("limpiar");
    setSelectedOp1(0);
    setSelectedOp2(0);
  };
  /* Tarea GDM1031 - Fin*/
  if (importesRango) {
    let title = "";
    switch (props.codigoProducto) {
      case "rd":
        title = "Retiro en domicilio";
        break;
      case "ea":
        title = "Pickup en agencia";
        break;

      case "pm":
        title = "Pickup móvil";
        break;
      default:
        title = "";
        break;
    }
    return (
      <Form noValidate validated={validated} autoComplete="nope">
        {/* <h1 className="product-title-primary">
          Completá los datos de tu envío ({title})
        </h1> */}
        <p>Los campos marcados con * son obligatorios</p>

        <Form.Row>
          <Form.Group as={Col} controlId="CodigoPostalRemitente">
            <Form.Label>Código postal para el retiro*</Form.Label>
            <Select
              options={options}
              isClearable={true}
              /*Tarea GDM-1031 - Inicio */
              value={
                options !== null
                  ? options.filter(function(option) {
                      return option.value === selectedOp1;
                    })
                  : null
              }
              /*Tarea GDM-1031 - Fin */
              placeholder="Buscar por Provincia, localidad o CP"
              name="CodigoPostalRemitente"
              onChange={handleChangeRem}
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="CodigoPostalDestinatario">
            <Form.Label>Código postal para el envío*</Form.Label>
            <Select
              options={options}
              isClearable
              /*Tarea GDM-1031 - Inicio */
              value={
                options !== null
                  ? options.filter(function(option) {
                      return option.value === selectedOp2;
                    })
                  : null
              }
              /*Tarea GDM-1031 - Fin */
              placeholder="Buscar por Provincia, localidad o CP"
              name="CodigoPostalDestinatario"
              onChange={handleChangeDest}
              required
            />
          </Form.Group>
        </Form.Row>

        {/* <h4>Describí tu envío</h4> */}
        <Form.Label>Describí tu envío</Form.Label>

        <Form.Row>
          <Form.Group as={Col} controlId="NumeroBultos">
            <Form.Label>Bultos*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ej: 1, 2, 3"
              name="NumeroBultos"
              required
              onChange={handleSubmit}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: campos.NumeroBultos }}
            >
              Campo obligatorio.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="Kilos">
            <Form.Label>Peso* (max. 50kg)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ej: 1, 2, 3"
              name="Kilos"
              required
              onChange={handleSubmit}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: campos.Kilos }}
            >
              Campo obligatorio, no superar el máximo.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="Alto">
            <Form.Label>Alto*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ej: 5, 6, 7"
              name="Alto"
              required
              onChange={handleSubmit}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: campos.Alto }}
            >
              Campo obligatorio, mínimo 5cm.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="Ancho">
            <Form.Label>Ancho*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ej: 5, 6, 7"
              name="Ancho"
              required
              onChange={handleSubmit}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: campos.Ancho }}
            >
              Campo obligatorio, mínimo 5cm.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="Largo">
            <Form.Label>Profundidad*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ej: 5, 6, 7"
              name="Largo"
              required
              onChange={handleSubmit}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: campos.Largo }}
            >
              Campo obligatorio, mínimo 5cm.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="ImporteValorDeclarado">
            <Form.Label>Valor declarado*</Form.Label>
            <Form.Control
              type="text"
              placeholder={
                "Min $" +
                importesRango.Minimo +
                "- Max $" +
                importesRango.Maximo
              }
              name="ImporteValorDeclarado"
              required
              onChange={handleSubmit}
            />
            <Form.Control.Feedback
              type="invalid"
              style={{ display: campos.ImporteValorDeclarado }}
            >
              Campo obligatorio.
            </Form.Control.Feedback>

            <span style={{ color: "#dc3545", fontSize: 13 }}>{importe}</span>
          </Form.Group>
        </Form.Row>

        {/* <h4>Seleccione forma de pago*</h4> */}
        <p>Seleccione forma de pago*</p>
        <Form.Check
          inline
          label="Pago en origen"
          name="pago"
          type="radio"
          id="origen"
          value="P"
          onChange={handleSubmitRadio}
        ></Form.Check>
        <Form.Check
          inline
          label="Pago en destino"
          name="pago"
          type="radio"
          id="destino"
          value="D"
          onChange={handleSubmitRadio}
        ></Form.Check>
        <Form.Control.Feedback type="invalid" style={{ display: pagoError }}>
          Campo obligatorio.
        </Form.Control.Feedback>
        <br />
        {boton && (
          <Button
            onClick={handleBlockedButtom}
            variant="primary"
            className="btnSiguiente"
          >
            Siguiente
          </Button>
        )}
        {!boton && (
          <div
            onClick={() => nextStepHandler()}
            className={"btn btn-success"}
            style={{ minWidth: 160 }}
          >
            Siguiente
          </div>
        )}
      </Form>
    );
  } else {
    return <div></div>;
  }
}
export default Formulario;
//render(<Formulario />);
