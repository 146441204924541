import React from "react";
import { any, node } from "prop-types";

const ProductSummary = ({ img, children }) => (
  <div className="product-detail">
    <a href="/products">
      <img src={img} alt="Producto" />
      <div className="product-detail-info">{children}</div>
    </a>
  </div>
);

ProductSummary.propTypes = {
  children: node,
  img: any
};

export default ProductSummary;
