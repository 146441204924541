import React from "react";
import "./styles.css";
import warningIcon from "./warning.png";
import { defaultFormat } from "moment";

const Warning = () => {
  return (
    <div className="banner-precaucion">
      <img src={warningIcon} className="warning-icon" />
      ¡Cuidado con las estafas! Usa nuestros canales oficiales y validá tu guía
      de seguimiento.
    </div>
  );
};

export default Warning;
