import React, { Component } from "react";
import { Carousel as CarouselReact } from "react-responsive-carousel";
import slider01 from "assets/img/slider2023-01.png";
import slider02 from "assets/img/slider2023-02.jpg";
import slider03 from "assets/img/slider2023-03.jpg";
import "./styles.css";

const Slider = () => (
  <section className="slider-content">
    <CarouselReact
      autoPlay={true}
      infiniteLoop={true}
      showArrows={true}
      showStatus={false}
      showThumbs={false}
      interval={6000}
    >
      <div className={"slide-div-container"}>
        <img src={slider01} alt="Via Cargo" />
      </div>

      <div className={"slide-div-container"}>
        <img src={slider02} alt="Via Cargo" />
      </div>

      <div className={"slide-div-container"}>
        <img src={slider03} alt="Via Cargo" />
      </div>
    </CarouselReact>
  </section>
);

export default Slider;
