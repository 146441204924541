import React from "react";

import "./styles.css";

export const passwordChange = () => {
  return (
    <section className="screen-contents contact-screen">
      <div className="details-container">
        <div className="content">
          <div className="container change-container">
            <div className="change-content">
              <div className="title-recover-container">
                <h4>¿Olvidaste tu contraseña?</h4>
              </div>
              <div className="text-change-container">
                <p>
                  Por favor ingresá tu correo electrónico y te enviaremos un
                  email para que puedas crear una contraseña nueva
                </p>
              </div>
              <div className="input-change-container">
                <label>Correo electrónico</label>
                <input
                  type="email"
                  className="change-input"
                  name="emailChange"
                  required
                />
              </div>
              <div className="input-change-container">
                <label>Nueva contraseña</label>
                <input
                  type="password"
                  className="change-input"
                  name="emailChange"
                  required
                />
                <p>
                  La contraseña deberá contener como mínimo 8 caracteres, una
                  letra mayúscula y al menos 1 número.
                </p>
              </div>
              <div className="input-change-container">
                <label>Confirmar contraseña</label>
                <input
                  type="password"
                  className="change-input"
                  name="emailChange"
                  required
                />
              </div>
              <div className="button-change-container">
                <input
                  type="button"
                  value="Crear nueva contraseña"
                  name="buttonChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default passwordChange;
