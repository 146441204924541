import React, { Component, useState, useEffect, useRef } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup
} from "react-bootstrap";

import axios from "axios";
import Modal from "simple-react-modal";

import "./../styles.css";

function Tarjetas(props) {
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    let posnetUrl = process.env.REACT_APP_API_URL + `/api/postnet`;
    let tarjetaUrl = process.env.REACT_APP_API_URL + `/api/tarjetas`;

    axios
      .get(posnetUrl, { params: { token: token } })
      .then(response => {
        let { data } = response;

        setPosnet(data);

        let encontrado = 0;
        let idposnet = 0;
        data.forEach(function(elemento) {
          if (elemento.idtipo === 5) {
            encontrado = 1;
            idposnet = elemento.idposnet;
          }
        });

        if (encontrado) {
          setIdPosnet(idposnet);
          const datos = { idposnet: idposnet, token: token };

          axios
            .get(tarjetaUrl, { params: datos })
            .then(response => {
              let { data } = response;

              setTarjetas(data);
            })
            .catch(e => {
              setXhr(false);
              setShowModal(true);
              setErrorMessage("server-error: " + e.response.data.message);
            });
        } else {
          setXhr(false);
          setShowModal(true);
          setErrorMessage(
            "server-error: No se encontró tarjetas con idtipo = 5"
          );
        }
      })
      .catch(e => {
        setXhr(false);
        setShowModal(true);
        setErrorMessage("server-error: " + e.response.data.message);
      });
  }, []);

  const [tarjetas, setTarjetas] = useState("");
  const [posnet, setPosnet] = useState("");
  const [cuotas, setCuotas] = useState("");
  const [xhr, setXhr] = useState("");
  const [showModal, setShowModal] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [idTarjeta, setIdTarjeta] = useState(0);
  const [idPosnet, setIdPosnet] = useState(0);
  const [cuota, setCuota] = useState(0);
  const [coeficiente, setCoeficiente] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  let i = 1;
  let idPostnetSelect = 0;

  const atrasForm6 = () => {
    props.atrasForm6();
  };

  const obtenerTarjetas = idposnet => {
    let tarjetaUrl = process.env.REACT_APP_API_URL + `/api/tarjetas`;
    const datos = { idposnet: 1702, token: token };

    axios
      .get(tarjetaUrl, { params: datos })
      .then(response => {
        let { data } = response;
        setTarjetas(data);
      })
      .catch(e => {
        setXhr(false);
        setShowModal(true);
        setErrorMessage("server-error: " + e.response.data.message);
      });
  };

  const obtenerCuotas = (idposnet, idtarjeta) => {
    let cuotasUrl = process.env.REACT_APP_API_URL + `/api/cuotas`;
    const datos = { idposnet: idposnet, idtarjeta: idtarjeta, token: token };

    axios
      .get(cuotasUrl, { params: datos })
      .then(response => {
        let { data } = response;
        setCuotas(data);
      })
      .catch(e => {
        setXhr(false);
        setShowModal(true);
        setErrorMessage("server-error: " + e.response.data.message);
        console.log(e);
      });
  };

  const selectPosnet = event => {
    setIdPosnet(event.target.value);
    setButtonDisabled(false);
    obtenerTarjetas(event.target.value);
    setIdTarjeta("");
    setCuotas("");
  };

  const selectTarjeta = event => {
    setIdTarjeta(event.target.value);
    setButtonDisabled(false);
    setCuotas("");
    obtenerCuotas(idPosnet, event.target.value);
  };

  const selectCuota = event => {
    let valor = event.target.value;
    if (event.target.value != "0") {
      setButtonDisabled(true);
      valor = valor.toString().split("-");

      setCoeficiente(valor[1]);
      setCuota(valor[0]);
    } else {
      setButtonDisabled(false);
    }
  };

  const siguiente = () => {
    let valor = {
      IdTarjeta: idTarjeta,
      IdPosnet: idPosnet,
      Cuotas: cuota,
      Coeficiente: coeficiente
    };

    props.parametroTarjeta(valor);
    props.septimoForm();
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        className={"modal"}
        containerClassName={"modal-container"}
      >
        <div className={"close-button"}>
          <i className={"material-icons"} onClick={() => setShowModal(false)}>
            close
          </i>
        </div>
        <div className={"modal-body"}>
          {modalMessage}
          {errorMessage}
          <div>
            <button
              className="quote btn btn-default"
              onClick={() => setShowModal(false)}
            >
              Cerrar
            </button>
          </div>
        </div>
      </Modal>

      {setPosnet && (
        <div style={{ textAlign: "center" }}>
          {
            <img
              src="/images/logo-via-cargo-verde.png"
              alt="Placeholder image"
              width="150"
              className="img-fluid"
            />
          }
          <hr />
          <Form>
            <Form.Row className="align-items-center">
              <Col lg={2}>
                {/*<Form.Group as={Col} >
                            <Form.Label className="mr-sm-2" htmlFor="inlineFormCustomSelect" >
                                Postnet
                            </Form.Label>
                            <Form.Control as="select"
                                
                                id="inlineFormCustomSelect"
                                custom
                                style={{minWidth:"auto"}}
                                onChange={selectPosnet}>
                                    <option key="0">Seleccionar</option>
                                    {Object.entries(posnet).map(([key,value])=>{
                                        i++
                                        return (
                                            <option key={i} value={value.idposnet}>{value.idposnet}</option>    
                                        );
                                    })}
                            </Form.Control>
                        </Form.Group>
                        */}
              </Col>
              <Col lg={4}>
                <Form.Group as={Col}>
                  <Form.Label
                    className="mr-sm-2"
                    htmlFor="inlineFormCustomSelect"
                  >
                    Tarjeta
                  </Form.Label>
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelect"
                    custom
                    style={{ minWidth: "auto" }}
                    onChange={selectTarjeta}
                  >
                    {tarjetas && (
                      <option key="0" value="0">
                        Seleccionar
                      </option>
                    )}
                    {Object.entries(tarjetas).map(([key, value]) => {
                      i++;
                      if (value.EsDebito) {
                        return (
                          <option key={i} value={value.idtarjeta}>
                            {value.descripcion_tarjeta} (Debito)
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={value.idtarjeta}>
                            {value.descripcion_tarjeta} (Crédito)
                          </option>
                        );
                      }
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group as={Col}>
                  <Form.Label
                    className="mr-sm-2"
                    htmlFor="inlineFormCustomSelect"
                  >
                    Cuotas
                  </Form.Label>
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelect"
                    custom
                    style={{ minWidth: "auto" }}
                    onChange={selectCuota}
                  >
                    {cuotas && <option key="0">Seleccionar</option>}
                    {Object.entries(cuotas).map(([key, value]) => {
                      i++;
                      let info = value.cuota + "-" + value.coeficiente;
                      return (
                        <option key={i} title={value.couta} value={info}>
                          {value.cuota}{" "}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
          <div>
            <Button variant="secondary" onClick={props.atrasForm6}>
              Volver atrás
            </Button>
            <Button
              variant="primary"
              onClick={siguiente}
              style={{ maxHeight: 38 }}
              disabled={!buttonDisabled}
            >
              Siguiente
            </Button>
          </div>
        </div>
      )}
      {!setPosnet && <div>Cargando información de las tarjetas </div>}
    </div>
  );
}
export default Tarjetas;
