import React, { useState, useEffect } from "react";

import UserCategoryConfirm2 from "./userCategoryConfirm2";

export const Container = () => {
  const [user, setUser] = useState("");

  useEffect(() => {
    const loggedUser = window.localStorage.getItem("loggedUser");
    setUser(loggedUser);
  }, []);

  if (user) return <UserCategoryConfirm2 />;
  return (
    <div>
      <h2>No estas autorizado</h2>
    </div>
  );
};

export default Container;
