import React from "react";
import { Link, Route } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import "./styles.css";

const Footer = () => (
  <footer className="footer footer-desktop">
    <section className="content-link">
      {/* Accesos Rápidos */}
      <section className="links-info">
        <div className="title">Accesos Rápidos</div>
        <div className="links">
          <Link to="/tracking" className="footer-link">
            <span>Seguimiento de tu envio</span>
          </Link>
          <Link to="/agencies" className="footer-link">
            <span>Agencias</span>
          </Link>
          <Link to="/quote" className="footer-link">
            <span>Cotizar Envíos</span>
          </Link>
          <Link to="/services" className="footer-link">
            <span>Servicios</span>
          </Link>
          <Link
            to={{ pathname: "https://www.viacompras.com.ar/" }}
            target="_blank"
            className="footer-link"
          >
            <span>Vía Compras</span>
          </Link>
        </div>
      </section>

      {/* Cómo */}
      <section className="links-info">
        <div className="title">Cómo</div>
        <div className="links">
          {/* <Link to="/tips" className="footer-link">
            <span>Peso y tamaño</span>
          </Link> */}
          <Link to="/tips" className="footer-link">
            <span>Tips / Cómo embalar</span>
          </Link>
        </div>
      </section>

      {/* Productos */}
      <section className="links-info">
        <div className="title">Productos</div>
        <div className="links">
          <Link to="/products" className="footer-link">
            <span>VC Personal</span>
          </Link>
          <Link to="/products" className="footer-link">
            <span>VC Standard</span>
          </Link>
          <Link to="/products" className="footer-link">
            <span>VC Plus</span>
          </Link>
          <Link to="/product/rd" className="footer-link">
            <span>Retiro en domicilio</span>
          </Link>
          <Link to="/product/ea" className="footer-link">
            <span>Entrega en Agencia</span>
          </Link>
          {/* <Link to="/product/pm" className="footer-link"> */}
          <span>Pickup Móvil</span>
          {/* </Link> */}
        </div>
      </section>

      {/* Soporte */}
      <section className="links-info">
        <div className="title">Soporte</div>
        <div className="links">
          <Link to="/support" className="footer-link">
            <span>Atención al cliente</span>
          </Link>
        </div>
      </section>

      {/* Acerca de Vía Cargo */}
      <section className="links-info">
        <div className="title">Acerca de Vía Cargo</div>
        <div className="links">
          <Link to="/know-us" className="footer-link">
            <span>Conocenos</span>
          </Link>
          <Link to="/contact?join" className="footer-link">
            <span>Sumate a la Red</span>
          </Link>
          {/* <Link to="/join-us" className="footer-link"> */}
          <Link
            to={{ pathname: "https://www.linkedin.com/company/54990355/" }}
            target="_blank"
            className="footer-link"
          >
            <span>RR.HH.</span>
          </Link>
          {/* <Link to="/contact" className="footer-link">
            <span>Acercanos tu CV</span>
          </Link> */}
        </div>
      </section>

      {/* Redes Sociales */}
      <section className="links-info">
        <div className="title">Redes Sociales</div>
        <div className="links">
          <Link
            to={{ pathname: "https://www.instagram.com/viacargo.oficial/" }}
            target="_blank"
            className="footer-link mb-1"
          >
            <SocialIcon
              url="https://www.instagram.com/viacargo.oficial"
              network="instagram"
              style={{ height: 25, width: 25 }}
              bgColor="#ffffff"
            />
          </Link>
          <Link
            to={{
              pathname: "https://www.facebook.com/ViaCargoAgenciaPabloNogues"
            }}
            target="_blank"
            className="footer-link mb-1"
          >
            <SocialIcon
              url="https://www.facebook.com/ViaCargoAgenciaPabloNogues"
              network="facebook"
              style={{ height: 25, width: 25 }}
              bgColor="#ffffff"
            />
          </Link>
          <Link
            to={{ pathname: "https://www.linkedin.com/company/54990355/" }}
            target="_blank"
            className="footer-link mb-1"
          >
            <SocialIcon
              url="https://www.linkedin.com/company/54990355"
              network="linkedin"
              style={{ height: 25, width: 25 }}
              bgColor="#ffffff"
            />
          </Link>
        </div>
      </section>
    </section>

    <section className="terms">
      <div className="terms-services">
        <div className={"new-phone"}>
          {/* <a href="tel:08102227722">0810 222 7722</a> */}
        </div>
        <Link to="/terms">
          <span className="terms-title">
            Términos y condiciones de Servicio
          </span>
        </Link>
        <Link to="/rezagos">
          <span className="terms-title">Rezagos</span>
        </Link>
        <Link to="/terms">
          <span className="terms-title">Términos de uso del sitio web</span>
        </Link>
        <Link to="/terms">
          <span className="terms-title">Política de privacidad</span>
        </Link>
        <div className={"afip-qr-container"}>
          <div className={"afip-qr"}>
            <a
              href="http://qr.afip.gob.ar/?qr=RjSR1DjFeyhV-kw2OpZQiA,,"
              target="_F960AFIPInfo"
            >
              <img
                src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                border="0"
                alt={"AFIP"}
              />
            </a>
          </div>
          <div className={"afip-qr"}>
            <a
              href="http://qr.afip.gob.ar/?qr=_w3RjSKvd8x5wJmo2ZJIJQ,,"
              target="_F960AFIPInfo"
            >
              <img
                src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                border="0"
                alt={"AFIP"}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="conditions">
        <p>
          Este sitio está protegido por las leyes internacionales y de Argentina
          de derechos de autor y marca registrada. Todos los derechos
          reservados. ©Vía Cargo 2023.
        </p>
        <p>
          Los derechos de propiedad intelectual y de otro tipo relativos a la
          información contenida en este sitio pertenecen a Vía Cargo.
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        Viacargo {process.env.REACT_APP_VERSION}{" "}
      </div>
      {/* <div style={{ color: "#383e43" }}>VIA CARGO S.A. Viacargo S.A</div>
      <div style={{ color: "#383e43" }}>BUSPLUS busplus </div> */}
    </section>
  </footer>
);

export default Footer;
