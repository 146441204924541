import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "shared/components/Sidebar/Sidebar";
import background from "assets/backgrounds/01.jpg";
import "./styles.css";

const FrequentAsk = () => (
  <section className="screen-contents faqs-screen">
    <div className="background">
      <img src={background} alt="Fondo" />
    </div>
    <div className="details-container">
      <div className="content">
        <div className="content-title">
          <h1>Preguntas Frecuentes</h1>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">¿Cuánto cuesta el envío?</h1>
          <p className="ask-body">
            El costo depende de la ubicación de origen y destino además del tipo
            de servicio, volumen y peso de la encomienda. Podés acceder a
            nuestra sección de cotización para completar la información
            requerida para conocer el valor de tu envío.
          </p>
          <Link to="/quote" className="ask-link">
            Ver Más
          </Link>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">
            ¿Cómo puedo saber dónde está mi encomienda con el número de
            seguimiento?
          </h1>
          <p className="ask-body">
            Con el número de seguimiento impreso en tu comprobante debés
            ingresar a la sección de "nombre" e ingresando este valor obtendrás
            el detalle de los movimientos de la encomienda.
          </p>
          <Link to="/tracking" className="ask-link">
            Ver Más
          </Link>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">
            ¿Dónde puedo ver los diferentes servicios que ofrecen?
          </h1>
          <p className="ask-body">
            Encontrará el servicio que más se adapte a tus necesidades visitando
            el siguiente link.
          </p>
          <Link to="/services" className="ask-link">
            Ver Más
          </Link>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">¿Cuentan con embalaje en las agencias?</h1>
          <p className="ask-body">
            Debés traer tu encomienda ya embalada. Adicionalmente, en nuestros
            puntos de venta habilitados, poseemos bolsas de seguridad y también
            film stretch que, en caso necesario, serán utilizados para reforzar
            el embalaje de su encomienda.
          </p>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">
            ¿Qué punto de venta tengo cerca de mi domicilio?
          </h1>
          <p className="ask-body">
            Para conocer un punto de venta cercano a tu domicilio o bien conocer
            el punto de venta más próximo al destino deseado podrás acceder a
            nuestra sección de puntos de venta mediante el siguiente link.
          </p>
          <a href="/agencies" className="ask-link">
            Ver Más
          </a>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">
            ¿Puedo obtener una agencia de VIA CARGO?
          </h1>
          <p className="ask-body">
            En la siguiente sección podrás ingresar tus datos para que te
            contacte un representante comercial.
          </p>
          <a href="/contact?join" className="ask-link">
            Ver Más
          </a>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">¿Se puede abonar con tarjeta?</h1>
          <p className="ask-body">
            Sí, nuestros puntos de venta están habilitados para recibir pagos en
            efectivo, tarjeta de crédito y débito.
          </p>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">¿Mi encomienda aún no llega?</h1>
          <p className="ask-body">
            Si pasaron más de 72hs hábiles y no recibiste tu envío y/o la
            encomienda no tuvo movimientos en la sección de seguimiento. Amplia
            tu consulta en el siguiente formulario.
          </p>
          <a href="/support" className="ask-link">
            Ver Más
          </a>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">¿Puede retirar un tercero?</h1>
          <p className="ask-body">
            En caso que se presente una persona distinta del destinatario,
            deberá presentar una nota de autorización expresa con firma
            certificada por Escribano, Juez de Paz o Entidad Bancaria y copia de
            DNI.
          </p>
          {/* <a href="/services" className="ask-link">
            Ver Más
          </a> */}
        </div>

        <div className="ask-box">
          <h1 className="ask-title">
            ¿Cómo embalo mi encomienda correctamente?
          </h1>
          <p className="ask-body">
            El embalaje es muy importante realizarlo correctamente. Por esta
            razón te acercamos unas recomendaciones para asegurar el buen
            acondicionamiento de tu carga y así evitar cualquier inconveniente.
          </p>
          <Link to="/tips" className="ask-link">
            Ver Más
          </Link>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">
            ¿Qué documentación es requerida a la hora de realizar un envío?
          </h1>
          <p className="ask-body">
            Los envíos dentro de nuestra provincia (o país) sólo requieren del
            número de DNI o cédula del remitente y destinatario, excepto para la
            provincia de MISIONES, en la cual se deberá informar el número de
            CUIL/CUIT del remitente y destinatario.
          </p>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">
            Para los envíos a Tierra del Fuego ... ¿Se requiere alguna
            documentación extra?
          </h1>
          <p className="ask-body">
            Para llegar a la Isla de Tierra del Fuego debemos cruzar Chile y
            como consecuencia, la carga debe pasar inevitablemente por aduana.
            <ul>
              <li>Factura de Compra de la Mercadería Original.</li>
              <li>
                Con número de CAI y el vencimiento de la misma, con fecha
                posterior a la fecha de emisión.
              </li>
              <li>Declaración Jurada con copia de DNI del remitente.</li>
              <li>
                Para los envíos particulares solo se deberá completar una
                planilla que servirá de declaración Jurada sobre los artículos
                que la componen.
              </li>
            </ul>
          </p>
        </div>

        <div className="ask-box">
          <h1 className="ask-title">¿Cómo obtengo una cuenta corriente?</h1>
          <p className="ask-body">
            Ingresando tus datos en la siguiente sección te contactará un
            representante comercial.
          </p>
          <a href="/contact" className="ask-link">
            Ver Más
          </a>
        </div>
      </div>
      <Sidebar />
    </div>
  </section>
);

export default FrequentAsk;
