import React, { Component } from "react";

import axios from "axios";
import Steps, { Step } from "rc-steps";
import Modal from "simple-react-modal";

import Button from "shared/components/Button";
import Collapse from "shared/components/Collapse";
import Sidebar from "shared/components/Sidebar";

import Table from "./components/Table";
import loading from "../../../../assets/loading.gif";
import packs from "../../../../assets/icons/packs.png";
import agencies from "../../../../assets/icons/agencias2.png";
import guide from "../../../../assets/guia.jpg";
import delivered from "../../../../assets/entregado.png";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import background from "assets/backgrounds/02.jpg";
import "./styles.css";

class Tracking extends Component {
  constructor(props) {
    super(props);

    let code = "";
    if (props.match.params.code) {
      code = props.match.params.code;
    }

    this.state = {
      code,
      xhr: false, // Request in process
      data: null,
      showModal: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    if (this.state.code !== "") {
      this._searchCode();
    }
  }

  _inputCode = ev => {
    let code = ev.target.value;
    if (code.length > 12) {
      code = code.substr(0, 12);
    }
    this.setState({ code });
  };

  _searchCode = () => {
    let { code, xhr } = this.state;

    if (xhr || !code || code.length !== 12) return false;
    this.setState({ xhr: !xhr, data: null });
    //console.log("code: " + code);
    // Llamar al método reCAPTCHA para obtener el token
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_SITE_KEY_V3, { action: "submit" })
        .then(token => {
          //console.log("Token de reCAPTCHA:", token);
          // Hago la petición con el token obtenido
          let TRACKING_SERVICE =
            process.env.REACT_APP_API_URL +
            `/api/alerce/tracking/${code}?tokenRecaptcha=${token}`;

          axios
            .get(TRACKING_SERVICE)
            .then(response => {
              //alert("Valor de su score: " + response.data.score);
              this.setState({
                xhr: false,
                data: response.data
              });
            })
            .catch(e => {
              let errorMessage = "";
              if (e.response && e.response.status === 400) {
                //errorMessage = "not-found";
                errorMessage = e.response.data;
              }

              this.setState({
                xhr: false,
                showModal: true,
                errorMessage
              });
            });
        });
    });
  };

  _handleKeyDown = ev => {
    if (ev.key === "Enter") {
      // Get input value
      let code = ev.target.value;
      if (code.length > 12) {
        code = code.substr(0, 12);
      }
      this.setState({ code });
      this._searchCode();
    }
  };

  render() {
    let { data, errorMessage } = this.state;

    let modalMessage = "";
    if (errorMessage === "not-found") {
      modalMessage = (
        <div>
          <p>No se ha podido encontrar el código de seguimiento ingresado.</p>
          <p>Por favor, volvé a intentarlo</p>
        </div>
      );
    } else {
      modalMessage = (
        <div>
          <p>{errorMessage}</p>
        </div>
      );
    }

    return (
      <section className="screen-contents tracking-screen">
        <div className="background">
          <img src={background} alt="Fondo" />
        </div>
        <div className="details-container">
          <div className="content">
            <div className="content-title">
              <h1>Seguimiento de envío</h1>
            </div>

            <div className="content-search-bar">
              <h3 className="search-bar-title">Ingresá el número de envío</h3>
              <input
                type="number"
                value={this.state.code}
                placeholder="Número de envío"
                onChange={this._inputCode}
                onKeyDown={this._handleKeyDown}
              />
              <div className={"button-container"}>
                {this.state.xhr && (
                  // <img src={loading} alt={"Cargando"} className={"spinner"} />
                  <Button disabled={true}>Buscando...</Button>
                )}
                {!this.state.xhr && (
                  <Button onClick={this._searchCode}>Buscar</Button>
                )}
              </div>
            </div>

            <div className="divisor" />

            {!data && (
              <div className={"help-info"}>
                <div>
                  Podés encontrar el número de envío en tu correo electrónico de
                  confirmación, en la nota de envío o en el remito de entrega.{" "}
                  <div className="link">Ver número de guía</div>
                </div>
                <div className={"img-container"}>
                  <div
                    style={{
                      width: "100%",
                      padding: "50px 0",
                      border: "1px solid green"
                    }}
                  >
                    <img src={guide} alt={"Guia"} style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
            )}

            {data && data["ok"] && data["ok"].length > 0 && (
              <div className={"detail-info"}>
                <div className={"title"}>
                  Encontramos {data["ok"].length} coincidencia
                  {data["ok"].length > 1 ? "s" : ""}
                </div>
                {data["ok"].map((deliver, index) => {
                  let data = deliver["objeto"];
                  //console.log(data['score']);
                  return (
                    <div key={index}>
                      <div className={"deliver"} key={index}>
                        <div className={"deliver-summary"}>
                          <Steps direction="vertical" current={1}>
                            <Step
                              title={
                                <div>
                                  <b>{data["direccionRemitente"]}</b>,{" "}
                                  {data["poblacionRemitente"]}
                                </div>
                              }
                              icon={<img src={packs} alt={"Remitente"} />}
                              description={data["fechaHoraAdmision"]}
                            />
                            <Step
                              title={
                                <div>
                                  <b>{data["direccionDestinatario"]}</b>,{" "}
                                  {data["poblacionDestinatario"]}
                                </div>
                              }
                              icon={<img src={agencies} alt={"Destino"} />}
                              description={
                                data["fechaHoraEntrega"]
                                  ? data["fechaHoraEntrega"]
                                  : " - "
                              }
                            />
                          </Steps>
                          {data["fechaHoraEntrega"] && (
                            <div className={"delivered"}>
                              <img src={delivered} alt={"Entregado"} />
                              Entregado
                            </div>
                          )}
                        </div>
                        <div className={"deliver-metadata"}>
                          <div className={"header-metadata"}>
                            Número de envío
                          </div>
                          <div>{data["wsTracking"]["expedicion"]}</div>
                          <div className={"header-metadata"}>
                            Información del envío
                          </div>
                          <div>
                            {data["numeroTotalPiezas"]} pieza
                            {data["numeroTotalPiezas"] > 1 ? "s" : ""}
                          </div>
                          <div className={"header-metadata"}>Firmado por</div>
                          <div>
                            {data["nifQuienRecibe"]
                              ? data["nifQuienRecibe"]
                              : " - "}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <Collapse title="Ver Detalle">
                        <Table data={data["listaEventos"]} type={"events"} />
                        {data["listaIncidencias"] &&
                          data["listaIncidencias"].length > 0 && (
                            <div>
                              <div className={"incident-title"}>
                                Incidencias
                              </div>
                              <Table
                                data={data["listaIncidencias"]}
                                type={"incidents"}
                              />
                            </div>
                          )}
                      </Collapse>
                      <Collapse title="INFORMACIÓN ADICIONAL">
                        <div>
                          Peso declarado: {data["kilos"] ? data["kilos"] : ""}
                        </div>
                        <div>
                          Cantidad de piezas: {data["numeroTotalPiezas"]}
                        </div>
                        <div>Servicio: {data["descripcionServicio"]}</div>
                      </Collapse>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <Sidebar />
        </div>
        <Modal
          show={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          <div className={"close-button"}>
            <i
              className={"material-icons"}
              onClick={() => this.setState({ showModal: false })}
            >
              close
            </i>
          </div>
          <div className={"modal-body"}>
            {modalMessage}
            <div>
              <Button
                className="quote"
                onClick={() => this.setState({ showModal: false })}
              >
                Entendido
              </Button>
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}

export default Tracking;
