import React from "react";
import Product from "./components/Product";
import Sidebar from "shared/components/Sidebar/Sidebar";
import vcpPersonal from "assets/productos/2023/1.png";
import vcAgencias from "assets/productos/2023/2.png";
import vcDomicilio from "assets/productos/final/vc-plus.png";
import vcPlus from "assets/productos/2023/3-4.png";
import vcPickupMovil from "assets/productos/2023/5-6.png";

import viaAutomatica from "assets/productos/final/via-automatica.png";
import packSize from "assets/productos/final/pack-size.png";

import background from "assets/backgrounds/back_product.jpg";
import "./styles.css";

const extraInfo = (
  <div className="product-extra-info">
    <p>
      <span className="primary">Si la entrega en destino es a domicilio</span>
      <span className="secondary">(Agencia > Domicilio)</span>
    </p>
    <p className="details">
      Debe ser coordinado con la agencia emisora del envío.
    </p>
    <p>
      <span className="primary">Si el retiro del envío es a domicilio</span>
      <span className="secondary">(Domicilio > Agencia)</span>
    </p>
    <p className="details"></p>
  </div>
);

const Products = () => (
  <section className="screen-contents products-screen">
    <div className="background">
      <img src={background} alt="Fondo" />
    </div>
    <div className="details-container">
      <div className="content">
        <div className="content-title">
          <h1>Productos</h1>
        </div>
        <div className="content-description">
          <p>
            Vía Cargo te ofrece una amplia gama de servicios postales de manera
            rápida y segura.
          </p>
          <p>Realizá tus envíos y hasta cualquier punto del país.</p>
        </div>

        <Product
          title="VC Personal"
          subtitle="Puerta a Puerta"
          image={[vcpPersonal]}
        >
          <p>
            Ofrecemos un servicio Puerta a Puerta porque pensamos en tu
            comodidad. Vía cargo se acercará a tu domicilio para retirar la
            encomienda y se encargará de entregársela directamente al
            destinatario en el domicilio pactado. Este servicio está disponible
            en todo el país .
          </p>
        </Product>

        <Product
          title="VC Standard"
          subtitle="Agencia a Agencia"
          image={[vcAgencias]}
        >
          <p>
            Si deseás realizar el envío desde una de nuestras agencias podés
            acercarte a la más cercana y nosotros nos encargaremos de enviarla
            hacia la Agencia que corresponda a la dirección del destino. Ágil y
            Sencillo.
          </p>
        </Product>

        <Product
          title="VC Plus"
          subtitle="Agencia-Domicilio o Domicilio-Agencia"
          // hasExtraInfo={extraInfo}
          image={[vcPlus]}
        >
          <p>
            Servico 100% personalizado. Con Vía Cargo Plus ahora podés hacer
            envíos de la manera que quieras, a donde quieras. Podés elegir que
            retiren la encomienda en tu domicilio para ser entregada en una
            agencia, o por el contrario, podés llevarla a una de nuestras
            agencias para ser entregada - al destinatario - en su domicilio o
            dirección pactada.
          </p>
        </Product>
        <br />
        <Product
          title="Pickup Móvil"
          subtitle="Pickup > Agencia o Pickup > Domicilio"
          // hasExtraInfo={extraInfo}
          image={[vcPickupMovil]}
        >
          <p>
            Servicio de despacho de puntos de la Ciudad de Buenos Aires, a todo
            el país, hasta 15kg. Servicio exclusivo de recolección en CABA.
          </p>
        </Product>

        <hr />

        {/* <Product
          title="Via Automática"
          subtitle=""
          image={[viaAutomatica, packSize]}
        >
          <p>
            En 2019 se inauguró la primera planta automatizada de encomiendas al
            servicio de la gente. Con sus 20.000 m2 de superficie y 11000 m2
            cubiertos, no sólo generamos nuevos puestos de trabajo, sino que
            además duplicamos las operaciones mensuales y los egresos e ingresos
            diarios de envíos y encomiendas hacia todo el país.
          </p>

          <p>
            Esta apuesta tecnológica elevó la vara en el segmento de envíos
            persona a persona. Cuenta con playas de volúmenes medianos y
            grandes, las cuales están equipadas con la más avanzada tecnología,
            logrando que todas las encomiendas que ingresan en esta categoría
            cuenten con un tratamiento especial y automático, que garantizará la
            seguridad y canalización 100% del envío
          </p>

          <p>
            Además, aquellos envíos que se originen en CABA y GBA y cumplan con
            los requisitos de estandarización, se les aplicará un descuento del
            20% del flete: <br />
            <strong>Medida máxima 80 x 60 x 60 cm / Peso máximo: 49kg.</strong>
          </p>
        </Product> */}

        <span className="banner-text">
          ¡En Vía Cargo seguimos apostando en el futuro!
        </span>
      </div>
      <Sidebar />
    </div>
  </section>
);

export default Products;
