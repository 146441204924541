import React, { useState } from "react";
import { string } from "prop-types";
import "./styles.css";

const SummaryItem = ({ icon, title, text }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handlePopUp = () => {
    if (isHovering && text !== "undefined") {
      return (
        <div id="{title}">
          <div className="likes__list">{text}</div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  if (text != "") {
    return (
      <div
        className="item-summary"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <div>{handlePopUp()}</div>
        <img alt="Icon" className="icon-image" src={icon} />
        <h1 className="summary-title">{title}</h1>
      </div>
    );
  } else {
    return (
      <div className="item-summary">
        <img alt="Icon" className="icon-image" src={icon} />
        <h1 className="summary-title">{title}</h1>
      </div>
    );
  }
};

SummaryItem.propTypes = {
  title: string
};

export default SummaryItem;
