import React from "react";

import "./styles.css";

export const password = ({
  submitEmail,

  emailHandler,
  emailError
}) => {
  return (
    <section className="screen-contents contact-screen">
      <div className="details-container">
        <div className="content">
          <div className="container recover-container">
            <div className="recover-content">
              <div className="title-recover-container">
                <h4>¿Olvidaste tu contraseña?</h4>
              </div>
              <div className="text-recover-container">
                <p>
                  Por favor ingresá tu correo electrónico y te enviaremos un
                  email para que puedas crear una contraseña nueva
                </p>
              </div>
              <div className="input-recover-container">
                <label>Correo electrónico</label>
                <input
                  type="text"
                  className="recover-input"
                  name="emailRecover"
                  onChange={emailHandler}
                />

                <div className="validacion-error">
                  <p>{emailError}</p>
                </div>
              </div>
              <div className="button-recover-container">
                <div>
                  <input
                    type="button"
                    value="Enviar"
                    name="buttonRecover"
                    onClick={submitEmail}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default password;
