import React from "react";
import { any, node, string } from "prop-types";
import "./styles.css";

const Product = ({
  title,
  subtitle,
  children,
  hasExtraInfo,
  image,
  showSeparator
}) => (
  <div className="content-products">
    <div class="row">
      <div class="col-md-2">
        <div className="image-contents">
          {showSeparator && (
            <div className={"product-separator"}>
              <div />
            </div>
          )}
          {image.map((img, index) => (
            <img key={index} className="icon-img" src={img} alt="Producto" />
          ))}
        </div>
      </div>
      <div class="col-md-10">
        <div className="inner-content-products">
          <div className="product-title">
            <h1 className="product-title-primary">
              {title} <span className={"product-slash"}>/</span>
            </h1>
            <h3 className="product-title-secondary">{subtitle}</h3>
          </div>
          <div className="product-detail margined">{children}</div>
          {hasExtraInfo}
        </div>
      </div>
    </div>
  </div>
);

Product.propTypes = {
  title: string,
  subtitle: string,
  children: node,
  hasExtraInfo: node,
  image: any
};

export default Product;
