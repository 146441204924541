import React, { Component } from "react";

import { Link } from "react-router-dom";

import arrow from "assets/icons/flecha.svg";
import "./styles.css";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ""
    };
  }

  render() {
    return (
      <div className="services-section">
        <div className="sidebar-title">
          <h1>Servicios de envío</h1>
        </div>
        <Link to={"/products"}>
          <div className="side-item separator">
            <span className="side-green-title">
              VC PERSONAL<br />
              <span className={"side-title"}>PUERTA A PUERTA</span>
            </span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>

        <Link to={"/products"}>
          <div className="side-item separator">
            <span className="side-green-title">
              VC STANDARD<br />
              <span className={"side-title"}>AGENCIA / AGENCIA</span>
            </span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>

        <Link to={"/products"}>
          <div className="side-item separator">
            <span className="side-green-title">
              VC PLUS<br />
              <span className={"side-title"}>
                AGENCIA / DOMICILIO / AGENCIA
              </span>
            </span>
            <img className="side-img arrow" src={arrow} alt="Next" />
          </div>
        </Link>
      </div>
    );
  }
}

export default Services;
