import React, { Component, useState, useEffect, useRef } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Form,
  CardGroup,
  Transitions,
  Collapse,
  InputGroup
} from "react-bootstrap";
import FomularioPagoProcesar from "./FomularioPagoProcesar";
import axios from "axios";
import ReactDOM from "react-dom";
import Modal from "simple-react-modal";

import PagoProcesado from "./PagoProcesado";

import "./../styles.css";

function FomularioPago(props) {
  const [formulario, setFormulario] = useState("");
  const [xhr, setXhr] = useState("");
  const [showModal, setShowModal] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [url, setUrl] = useState("");
  const [pago, setPago] = useState("");
  const [infoPago, setInfoPago] = useState("");
  const [correcto, setCorrecto] = useState(false);
  const [alto, setAlto] = useState(0);
  const [ancho, setAncho] = useState(0);
  const [idComprobante, setIdComprobante] = useState(0);
  const [pagoFallo, setPagoFallo] = useState("");
  const [intentosImprimir, setIntentosImprimir] = useState(0);
  const [email, setEmail] = useState(""); /*Tarea GDM-1031 */
  const formRef = useRef(null);
  let contador = 0;
  let timeout = 0;
  let timeoutImprimir = 0;
  let intentos = 0;
  let camposFactura = {};
  let retirosFactura = process.env.REACT_APP_API_URL + `/api/retiros_factura`;
  let infoImprimir = undefined;

  const token = sessionStorage.getItem("token");
  /*Tarea GDM-1031 -Inicio */
  const ocultarConfirmar = () => {
    props.ocultarConfirmar();
  };
  /*Tarea GDM-1031 -Fin */

  const consultarPago = () => {
    if (formulario) {
      //if (formulario && formulario.oid) {
      let camposFirstData = {
        IdOperacion: formulario.oid
      };
      //let retiros = `/api/retiros_actualizar_pago`;
      let retiros = process.env.REACT_APP_API_URL + `/api/alerce/envios`;
      let datosCotizacion = JSON.parse(
        window.localStorage.getItem("datosRemitente")
      );
      axios
        .post(
          retiros,
          //{
            //retiro: "retiroweb",
            //email: email,
            //IdCotizacion: datosCotizacion.IdCotizacion
            datosCotizacion
          //}
          ,
          { params: { token: token } }
        )
        .then(response => {
          //let { data } = response;
          /*Tarea GDM-1031 - Inicio */
          let data = {
            data: {
              message: "",
              status: ""
            },
            status: ""
          };
          data.data.message = "Pago aprobado";
          data.data.status = "aprobado";
          data.status = true;
          setPago("1");
          //setPago(data.data);
          /*Tarea GDM-1031 - Fin */
          if (data.status) {
            if (
              data.data.message == "El pago fue rechazado por firstdata." ||
              data.data.status == "rechazado"
            ) {
              setPagoFallo(data.data.message);
              const v = { data: data.data.message };
              clearInterval(timeout);
              setPago(v);
              setUrl("pagado");
              window.localStorage.removeItem("datosRemitente");
              //console.log("pago rechazado",data.data)
            } else {
              if (
                data.data.message == "Pago aprobado" ||
                data.data.status == "aprobado"
              ) {
                /*setIdComprobante(data.data.idcomprobante);
                camposFactura = {
                  IdComprobante: data.data.idcomprobante,
                  Email: props.Email
                };*/
                //props.retiros(camposFactura);
                //timeoutImprimir = setInterval(imprimir, 5000);

                clearInterval(timeout);
                setUrl("pagado");
                setCorrecto(true);
                //console.log(infoImprimir)
              }
            }
          } else {
            console.log("status rechazado", data);
            if (data.message == "El pago fue rechazado por firstdata.") {
              setPagoFallo("Pago Rechazado");
              const v = { data: "Pago Rechazado" };
              clearInterval(timeout);
              setPago(v);
              setUrl("pagado");
            }
          }
        })
        .catch(e => {
          setXhr(false);
          //setShowModal(true)
          setErrorMessage("Hubo un error al actualizar el pago");
        });

      intentos++;

      if (intentos > 12) {
        const v = { data: "Ford" };
        clearInterval(timeout);
        setPago(v);
        setUrl("pagado");
      }
    }
  };

  const imprimir = () => {
    if (contador > 3) {
      clearInterval(timeoutImprimir);
      setXhr(false);
      setShowModal(true);
      setErrorMessage("server-error: " + infoImprimir);
    } else {
      let sumar = intentosImprimir + 1;
      setIntentosImprimir(sumar);
      contador++;
      const datos = {
        ...camposFactura,
        token: token
      };
      axios
        .get(retirosFactura, { params: datos })
        .then(response => {
          let { data } = response;
          clearInterval(timeoutImprimir);
        })
        .catch(e => {
          infoImprimir = e.response.data.message;
          //console.log(e.response.data);
        });
    }
  };

  useEffect(() => {
    let adicionalD = props.data.datosDestinatario.DatosAdicional
      ? ", " + props.data.datosDestinatario.DatosAdicional
      : "";
    let adicionalR = props.data.datosRemitente.DatosAdicional
      ? ", " + props.data.datosRemitente.DatosAdicional
      : "";

    // eliminar caracteres especiales
    let dirDest =
      props.data.datosDestinatario.DireccionDestinatario +
      " " +
      props.data.datosDestinatario.NumeroDestinatario +
      " " +
      props.data.datosDestinatario.PisoDestinatario +
      adicionalD;
    dirDest = dirDest
      .replace("'", "")
      .replace("¨", "")
      .replace("*", "")
      .replace('"', "");
    let dirRem =
      props.data.datosRemitente.DireccionRemitente +
      " " +
      props.data.datosRemitente.NumeroRemitente +
      " " +
      props.data.datosRemitente.PisoRemitente +
      adicionalR;
    dirRem = dirRem
      .replace("'", "")
      .replace("¨", "")
      .replace("*", "")
      .replace('"', "");
    let volumenNuevo =
      (parseInt(props.data.Largo) *
        parseInt(props.data.Ancho) *
        parseInt(props.data.Alto)) /
      1000000;

    let CodPaisRemitente = props.data.datosRemitente.CodPaisRemitente;
    let CodAreaRemitente = props.data.datosRemitente.CodAreaRemitente;
    let NroCelularRemitente = props.data.datosRemitente.NroCelularRemitente;

    let CodPaisDestinatario = props.data.datosDestinatario.CodPaisDestinatario;
    let CodAreaDestinatario = props.data.datosDestinatario.CodAreaDestinatario;
    let NroCelularDestinatario =
      props.data.datosDestinatario.NroCelularDestinatario;

    let IdCliRemPM = "";
    let IdCliRemEA = "";
    let IdCliRemRD = "";
    let IdCeRemPM = "";
    let IdCeRemEA = "";
    let IdCeRemRD = "";
    if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {
      IdCliRemPM = process.env.REACT_APP_IDCLIREM_PM_PROD;
      IdCliRemEA = process.env.REACT_APP_IDCLIREM_EA_PROD;
      IdCliRemRD = process.env.REACT_APP_IDCLIREM_RD_PROD;

      IdCeRemPM = process.env.REACT_APP_IDCEREM_PM_PROD;
      IdCeRemEA = process.env.REACT_APP_IDCEREM_EA_PROD;
      IdCeRemRD = process.env.REACT_APP_IDCEREM_RD_PROD;
    } else {
      IdCliRemPM = process.env.REACT_APP_IDCLIREM_PM_TEST;
      IdCliRemEA = process.env.REACT_APP_IDCLIREM_EA_TEST;
      IdCliRemRD = process.env.REACT_APP_IDCLIREM_RD_TEST;

      IdCeRemPM = process.env.REACT_APP_IDCEREM_PM_TEST;
      IdCeRemEA = process.env.REACT_APP_IDCEREM_EA_TEST;
      IdCeRemRD = process.env.REACT_APP_IDCEREM_RD_TEST;
    }

    let IdClienteRemitente = IdCliRemPM;
    let IdCentroRemitente = IdCeRemPM;
    if (props.codigoProducto == "ea") {
      IdClienteRemitente = IdCliRemEA;
      IdCentroRemitente = IdCeRemEA;
    } else if (props.codigoProducto == "rd") {
      IdClienteRemitente = IdCliRemRD;
      IdCentroRemitente = IdCeRemRD;
    }

    let campos = {
      IdCotizacion: props.data.IdCotizacion,
      NumeroBultos: props.data.NumeroBultos,
      Kilos: props.data.Kilos,
      Largo: props.data.Largo,
      Ancho: props.data.Ancho,
      Alto: props.data.Alto,
      IdClienteRemitente: IdClienteRemitente,
      IdCentroRemitente: IdCentroRemitente,
      PaisRemitente: CodPaisRemitente,
      CodigoPostalRemitente: props.data.datosRemitente.CodigoPostal,
      PaisDestinatario: CodPaisDestinatario,
      CodigoPostalDestinatario: props.data.datosDestinatario.CodigoPostal,
      ImporteValorDeclarado: props.data.ImporteValorDeclarado,
      TipoMoneda: "ARS",

      NombreRemitente: props.data.datosRemitente.NombreRemitente.replace(
        /"/g,
        ""
      ),
      DireccionRemitente: dirRem.replace(/"/g, "").replace(/['"]+/g, ""),
      //ObservacionRemitente: props.data.datosRemitente.Observacion,
      TipoDocRemitente: "DNI",
      NroDocRemitente: props.data.datosRemitente.NroDocRemitente,
      //TelefonoRemitente: "(" + CodPaisRemitente + ") " + CodAreaRemitente + " " + NroCelularRemitente,
      TelefonoRemitente: NroCelularRemitente,
      EmailRemitente: props.data.datosRemitente.EmailRemitente,

      NombreDestinatario: props.data.datosDestinatario.NombreDestinatario.replace(
        /"/g,
        ""
      ),
      DireccionDestinatario: dirDest.replace(/"/g, "").replace(/['"]+/g, ""),
      //ObservacionDestinatario: props.data.datosDestinatario.Observacion,
      TipoDocDestinatario: "DNI",
      NroDocDestinatario: props.data.datosDestinatario.NroDocDestinatario,
      // TelefonoDestinatario: "(" + CodPaisDestinatario + ") " + CodAreaDestinatario + " " + NroCelularDestinatario,
      TelefonoDestinatario: NroCelularDestinatario,
      EmailDestinatario: props.data.datosDestinatario.EmailDestinatario,

      CodigoServicio: props.data.CodigoServicio,
      ImporteTotal: props.data.TotalServicio,

      Volumen: volumenNuevo < 0.01 ? 0.01 : volumenNuevo,
      ImporteReembolso: 100.0,
      TipoPortes: props.data.Pago,
      Observaciones: "",
      IdClienteDestinatario: "9999999999",
      IdCentroDestinatario: "99",

      IdMedio: "TAR",
      IdTarjeta: props.IdTarjeta,
      IdPosnet: props.IdPosnet,
      Cuotas: props.Cuotas,
      Coeficiente: props.Coeficiente,
      IdTipoDocumento: 1,
      Documento: props.NroDocRemitente,
      Nombre: props.NombreRemitente,
      Apellido: props.NombreRemitente,
      Email: props.Email,
      TipoDocRemitente: "DNI",
      retiro: "retiroweb" //Tarea GDM-1031
    };
    setEmail(campos.Email); //Tarea GDM-1031
    //let retiros = `/api/retiros_iniciar_pago`;
    let retiros = process.env.REACT_APP_API_URL + `/api/retiros`;
    let camposRetirosPost = JSON.parse(
      window.localStorage.getItem("datosRemitente")
    );
    consultarPago();
    if (url == "") {
      console.log(
        "Formulario Pago, request retiros: " + JSON.stringify(camposRetirosPost)
      );
      axios
        .post(retiros, camposRetirosPost, { params: { token: token } })
        .then(response => {
          let { data } = response;
          /*Tarea GDM-1031 - Inicio */
          // if (data.status == true) {
          //   console.log(
          //     "Formulario Pago, request retiros_iniciar_pago: " +
          //       JSON.stringify(campos)
          //   );
          //   axios
          //     .post(retiros, campos, { params: { token: token } })
          //     .then(response => {
          //       let { data } = response;
          //       if (data.data != "aprobado") {
          //         setCorrecto(false);
          //       }
          //     })
          //     .catch(e => {
          //       setXhr(false);
          //       setShowModal(true);
          //       setErrorMessage("Hubo un error al iniciar el pago");
          //       setUrl("pagado");
          //       setPago("1");
          //       setCorrecto(false);
          //     });
          // }
          setFormulario("1");
          setUrl("pagado");
        })
        /*Tarea GDM-1031 - Fin */
        .catch(e => {
          setXhr(false);
          setShowModal(true);
          setErrorMessage("Hubo un error al tratar de guardar el pedido");
          setUrl("pagado");
          setPago("1");
          setCorrecto(false);
        });
    } else {
      if (pago == "") {
        timeout = setInterval(consultarPago, 3000);
      }
    }
  }, [url, pago]);

  let variable = ""; // "/pickup-formulario/info="+JSON.stringify(formulario)

  const x = Object.entries(props.formPago).map(([key, value]) => {
    variable =
      variable +
      '<input type="text" name=' +
      { key } +
      " value=" +
      { value } +
      "></input>";
  });

  const formIframe = () => {
    return (
      <div>
        <form
          target="paymentForm"
          ref={formRef}
          id="checkoutForm"
          method="post"
          action={props.formPago.action}
        >
          {Object.entries(props.formPago).map(([key, value]) => {
            let i = 0;
            if (key !== "action") {
              return (
                <input key={i++} type="text" name={key} value={value}></input>
              );
            }
          })}
        </form>
      </div>
    );
  };

  const atrasForm7 = () => {
    props.atrasForm7();
  };

  if (url != "") {
    return (
      <div>
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          className={"modal"}
          containerClassName={"modal-container"}
        >
          <div className={"close-button"}>
            <i className={"material-icons"} onClick={() => setShowModal(false)}>
              close
            </i>
          </div>
          <div className={"modal-body"}>
            {modalMessage}
            {errorMessage}
            <div>
              <button
                className="quote btn btn-default"
                onClick={() => setShowModal(false)}
              >
                Cerrar
              </button>
            </div>
          </div>
        </Modal>

        {url != "pagado" && (
          <iframe
            src={url}
            width="100%"
            height="100%"
            frameBorder="0"
            style={{
              maxWidth: 640,
              width: "100%",
              minHeight: 800,
              overflow: "visible"
            }}
          />
        )}

        {url == "pagado" && pago && (
          <PagoProcesado
            codProd={props.codigoProducto}
            formPago={pago}
            importe={props.importe}
            senderFullName={props.NombreRemitente}
            correcto={correcto}
            pagoFallo={pagoFallo}
            atrasForm7={props.atrasForm7}
            volverForm={props.volverForm} /* Tarea GDM-1031*/
            ocultarConfirmar={ocultarConfirmar} /* Tarea GDM-1031*/
          />
        )}
        {url == "pagado" && !correcto && (
          <div style={{ textAlign: "center" }}>
            {/* <Button variant="secondary" onClick={props.atrasForm7}>
              Volver atrás
            </Button> */}
          </div>
        )}
      </div>
    );
  } else {
    return <div>Cargando datos para el pago</div>;
  }
}
export default FomularioPago;
