import React from "react";
import "./styles.css";
import { Link, withRouter } from "react-router-dom";
import Button from "../Button";

const ImageTextViacompra = ({ image, text, textbutton, textlink }) => (
  <section className={"image-text-section"}>
    <img className={"img"} alt="legend 1" src={image} />
    <div
      className="carousel-title"
      style={{ top: text === "A todo el país" ? "40%" : "10%" }}
    >
      {text}
    </div>
  </section>
);

export default ImageTextViacompra;
